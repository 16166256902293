import { proxy, useSnapshot } from "valtio";

// todo: test pdf generation and maybe omit this oveall
export interface DetailsPageState {
  isPDFExport: boolean;
}

export const detailsPageStateProxy = proxy<DetailsPageState>({
  isPDFExport: false,
});

export const useDetailsPageState = () => {
  const snap = useSnapshot(detailsPageStateProxy);
  return snap;
};

export const updateDetailsPageState = (newState: Partial<DetailsPageState>) => {
  const newStateCopy = { ...newState };

  Object.assign(detailsPageStateProxy, newStateCopy);
};

export const useIsPDFExport = () => {
  return useSnapshot(detailsPageStateProxy).isPDFExport;
};