import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, SecurinFix } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 3;
const SecurinFixesRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const values = safeParseJSONtoArray<SecurinFix>(context.getValue());

  if (!values || !Array.isArray(values)) {
    return <>-</>;
  }

  const renderSource = (fix: SecurinFix, index: number) =>
    fix?.sources?.[0]?.url ? (
      <a
        key={`securin_fix_urls_${index}`}
        className="flex-start ease-nav-brand text-slate-600 hover:text-blue-700 flex font-semibold transition-all"
        href={fix.sources[0].url}
        target="_blank"
        rel="noreferrer"
      >
        <span>
          {fix.sources[0].name || fix.sources[0].url}
          <i className="fas fa-external-link-alt inline ml-1 text-xs" />
        </span>
      </a>
    ) : (
      <span key={`securin_fix_urls_${index}`}>Unknown source</span>
    );

  const fullView = <div>{values.map(renderSource)}</div>;

  if (values.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{values.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderSource(source, index))}</div>
        <Tooltip value={fullView}>
          <div className="cursor-progress mt-2 text-xs text-right text-gray-600">
            And {values.length - LIMIT_RECORDS_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export default SecurinFixesRenderer;
