import type { NucleusQLState } from "@components/NucleusQL/NucleusQLState";
import type { FieldReferencesFieldsState } from "@features/field_references/fieldReferences.state";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import type { IntrospectionKey } from "@queries/useIntrospections";
import { LEXER_TOKENS, type LexerToken, type LexerTokenName, TOKEN_TYPE, getLexer } from "@utils/Lexer";
import { Suggestion } from "./Suggestion";

enum CONTEXT_SCOPE {
  // Field scope: When the user is entering a field name
  FIELD = "field",
  // Value scope: When the user is entering a value for a field
  VALUE = "value",
  // Logical scope: When the user is entering a logical operator
  LOGICAL = "logical",
  // Comparison scope: When the user is entering a comparison operator
  COMPARISON = "comparison",
  // Sort direction scope: When the user is entering a sort directionFIELD = "field",
  SORTDIRECTION = "sortdir",
}

class QLContext {
  boundStateUpdater: (newState: Partial<NucleusQLState>, isValidate?: boolean, isSubmit?: boolean) => void;
  currentValue: string;
  prefix = "";
  scope: CONTEXT_SCOPE | null = null;
  field: ThreatKey | null = null;
  currentFullToken: LexerToken | null = null;
  lastToken: LexerToken | null = null;
  nextToLastToken: LexerToken | null = null;
  cursorPos: number;
  nestingLevel = 0;
  queryPart = "expression";

  private isWhitespace = false;

  constructor(
    fieldReferences: Readonly<FieldReferencesState>,
    boundStateUpdater: (newState: Partial<NucleusQLState>, isValidate?: boolean, isSubmit?: boolean) => void,
    currentValue: string,
    cursorPos = 0,
  ) {
    this.boundStateUpdater = boundStateUpdater;
    this.currentValue = currentValue;
    this.cursorPos = cursorPos;

    const tokens = this.tokenizeInput();
    this.setQueryPartAndNestingLevel(tokens);
    this.setTokens(tokens);
    this.setPrefix();
    this.determineScope(fieldReferences);
  }

  // Tokenize the input up to the cursor position
  private tokenizeInput(): LexerToken[] {
    const lexer = getLexer();
    const sliced = this.currentValue.slice(0, this.cursorPos);
    return lexer.setInput(sliced).lexAll();
  }

  // Determine if we're in the expression or ordering part of the query
  // and track the nesting level of parentheses
  private setQueryPartAndNestingLevel(tokens: LexerToken[]): void {
    for (const t of tokens) {
      if (t.name === LEXER_TOKENS.ORDER_BY) {
        this.queryPart = "ordering";
      } else if (t.name === LEXER_TOKENS.PAREN_L) {
        this.nestingLevel++;
      } else if (t.name === LEXER_TOKENS.PAREN_R) {
        this.nestingLevel--;
      }
    }
  }

  // Set the current, last, and next-to-last tokens based on cursor position
  private setTokens(tokens: LexerToken[]): void {
    const allTokens = getLexer().setInput(this.currentValue).lexAll();

    if (tokens.length && tokens[tokens.length - 1].end >= this.cursorPos) {
      this.currentFullToken = allTokens[tokens.length - 1];
      tokens.pop();
    }

    if (tokens.length) {
      this.lastToken = tokens[tokens.length - 1];
      if (tokens.length > 1) {
        this.nextToLastToken = tokens[tokens.length - 2];
      }
    }
  }

  // Set the prefix (text immediately before the cursor)
  // and remove leading whitespace
  private setPrefix(): void {
    this.prefix = this.currentValue.slice(this.lastToken ? this.lastToken.end : 0, this.cursorPos);
    const whitespaceRegex = /[ \t\v\f\u00A0]+/;
    const whitespace = this.prefix.match(whitespaceRegex);
    if (whitespace) {
      this.isWhitespace = true;
      this.prefix = this.prefix.slice(whitespace[0].length);
    }
    if (this.prefix === "(") {
      // parenthesis are not parts of the suggestion
      this.prefix = "";
    }
  }

  // Main method to determine the current context scope
  private determineScope(fieldReferences: Readonly<FieldReferencesFieldsState>): void {
    const logicalTokens: LexerTokenName[] = [LEXER_TOKENS.AND, LEXER_TOKENS.OR];

    // nothing to suggest right after right paren
    if (this.prefix === ")" && !this.isWhitespace) {
      this.scope = null;
      return;
    }

    if (this.isFieldScope(logicalTokens)) {
      this.setFieldScope();
    } else if (this.isValueScope()) {
      this.setValueScope(fieldReferences);
    } else if (this.isComparisonScope()) {
      this.setComparisonScope();
    } else if (this.isLogicalScope()) {
      this.scope = CONTEXT_SCOPE.LOGICAL;
    } else if (this.isSortDirectionScope()) {
      this.scope = CONTEXT_SCOPE.SORTDIRECTION;
    } else {
      this.scope = null;
    }
  }

  // Helper methods for scope determination

  // Check if we're in a logical operator context
  private isLogicalScope(): boolean {
    return !!(
      this.queryPart === "expression" &&
      this.isWhitespace &&
      this.lastToken &&
      (["PAREN_R", "EMPTY"].includes(this.lastToken.name) ||
        this.lastToken.type === TOKEN_TYPE.VALUE ||
        this.lastToken.type === TOKEN_TYPE.FUNCTION)
    );
  }

  // Set the scope to field and adjust prefix if needed
  private setFieldScope(): void {
    this.scope = CONTEXT_SCOPE.FIELD;
    if (this.prefix === "." && this.lastToken) {
      this.prefix = this.currentValue.slice(this.lastToken.start, this.cursorPos);
    }
  }

  // Check if we're in a field name context
  private isFieldScope(logicalTokens: LexerTokenName[]): boolean {
    return !!(
      (this.queryPart === "expression" &&
        (!this.lastToken ||
          (this.lastToken && logicalTokens.includes(this.lastToken.name) && this.isWhitespace) ||
          (this.prefix === "." && this.lastToken && !this.isWhitespace) ||
          (this.lastToken.name === "PAREN_L" &&
            (!this.nextToLastToken || logicalTokens.indexOf(this.nextToLastToken.name) >= 0)))) ||
      (this.queryPart === "ordering" && this.lastToken && this.lastToken.name === LEXER_TOKENS.ORDER_BY)
    );
  }

  // Check if we're in a value input context
  private isValueScope(): boolean {
    return !!(
      this.lastToken &&
      this.nextToLastToken &&
      this.nextToLastToken.name === "NAME" &&
      this.lastToken.type === TOKEN_TYPE.OPERATOR
    );
  }

  // Set the scope to value and resolve the field name
  private setValueScope(fieldReferences: Readonly<FieldReferencesFieldsState>): void {
    const resolvedName = Suggestion.resolveFieldName(this.nextToLastToken!.value);
    if (resolvedName) {
      this.scope = CONTEXT_SCOPE.VALUE;
      this.field = resolvedName as IntrospectionKey;
      this.adjustPrefixForQuotedValues(fieldReferences);
    }
  }

  // Adjust the prefix for quoted values
  private adjustPrefixForQuotedValues(fieldReferences: Readonly<FieldReferencesFieldsState>): void {
    const fieldDef = fieldReferences[this.field!];
    if (
      this.prefix[0] === '"' &&
      (fieldDef?.type === "str" || fieldDef?.type === "datetime" || fieldDef?.possible_values?.length)
    ) {
      this.prefix = this.prefix.slice(1);
    }
  }

  // Check if we're in a comparison operator context
  private isComparisonScope(): boolean {
    return !!(this.queryPart === "expression" && this.lastToken && this.isWhitespace && this.lastToken.name === "NAME");
  }

  // Set the scope to comparison and resolve the field name
  private setComparisonScope(): void {
    const resolvedName = Suggestion.resolveFieldName(this.lastToken!.value);
    if (resolvedName) {
      this.scope = CONTEXT_SCOPE.COMPARISON;
      this.field = resolvedName;
    }
  }

  // Check if we're in a sort direction context
  private isSortDirectionScope(): boolean {
    return !!(this.queryPart === "ordering" && this.lastToken && this.lastToken.name === "NAME");
  }
}

export default QLContext;
export { CONTEXT_SCOPE };
