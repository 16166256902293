import type SerializedOrganizationConfiguration from "@interfaces/SerializedOrganizationConfiguration";
import { useCurrentUserProfile } from "@queries/useCurrentUserProfile";

function useOrganizationConfiguration() {
  return useCurrentUserProfile<SerializedOrganizationConfiguration>({
    select: (profile) => profile.organization_configuration,
  });
}

function useOrganizationTier() {
  const { data: organizationData } = useOrganizationConfiguration();

  return organizationData?.tier;
}

export { useOrganizationTier, useOrganizationConfiguration };
