import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, MandiantExploit } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_RECORDS_TO = 6;
const MandiantExploitRenderer: (context: CellContext<FrontendSerializedThreat, MandiantExploit[]>) => JSX.Element = (
  context,
) => {
  const value: MandiantExploit[] = context.getValue();

  if (!value || !Array.isArray(value)) {
    return <>-</>;
  }

  const textClass = "text-sm text-gray-500";

  const renderRecord = (record: MandiantExploit, index) => (
    <Tooltip key={`mandiant_exploit_${index}`} value={<div className={textClass}>{record.description}</div>}>
      <a
        className="inline-flex items-center font-semibold transition-all ease-nav-brand text-slate-600 hover:text-blue-700"
        href={record.exploitUrl}
        target="_blank"
        rel="noreferrer"
      >
        <i className="mr-1 text-lg text-orange-600 opacity-70 fas fa-bug" />
      </a>
    </Tooltip>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderRecord(source, index))}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export default MandiantExploitRenderer;
