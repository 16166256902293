import type { MandiantTechnology } from "./SerializedThreat";

export class MandiantTechnologyInstance implements MandiantTechnology {
  id: string;
  cpe: string;
  cpeTitle?: string;
  cpe_title?: string;
  _technologyName?: string;
  technology_name?: string;
  vendor?: string;
  vendor_name?: string;

  constructor(technology: MandiantTechnology) {
    this.id = technology.cpe + Math.random().toString(36);
    this.cpe = technology.cpe;
    this.cpeTitle = technology.cpeTitle;
    this.cpe_title = technology.cpe_title;
    this._technologyName = technology.technologyName;
    this.technology_name = technology.technology_name;
    this.vendor = technology.vendor;
    this.vendor_name = technology.vendor_name;
  }

  getTechnologyName() {
    return this._technologyName || this.technology_name || "";
  }

  getTitle() {
    return this.cpeTitle || this.cpe_title || "";
  }

  getVendorName() {
    return this.vendor || this.vendor_name || "";
  }
}
