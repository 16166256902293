import type SerializedOrganization from "@interfaces/SerializedOrganization";
import { OrganizationUIModel } from "@interfaces/SerializedOrganization";
import { type UseSuspenseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";

import useGlobalVariables from "./useGlobalVariables";

const useOrganization = (
  initialData?: SerializedOrganization,
  options?: Partial<UseSuspenseQueryOptions<SerializedOrganization>>,
) => {
  return useSuspenseQuery<SerializedOrganization>({
    queryKey: ["organization"],
    queryFn: async () => {
      const response = await fetchWithSession("/api/v1/organization/?include[]=has_risk_levels&include[]=cve_statuses");
      return response.organization;
    },
    initialData: initialData ?? undefined,
    staleTime: 1000 * 60,
    ...options,
  });
};

// @needSuspense
const useOrganizationUIModel = (
  initialData?: SerializedOrganization,
  options?: Partial<UseSuspenseQueryOptions<SerializedOrganization>>,
) => {
  const { data: organizationData } = useOrganization(initialData, options);

  const { currentUserID } = useGlobalVariables();

  const organizationUIModel = new OrganizationUIModel(organizationData, currentUserID);

  return organizationUIModel;
};

const useOrganizationMonitoringLimit = () => {
  const { data: organizationData } = useOrganization();

  return organizationData?.watched_search_result_limit;
};

export default useOrganization;
export { useOrganizationUIModel, useOrganizationMonitoringLimit };
