import { debounce } from "lodash-es";
import React, { useEffect, useState } from "react";

import type { Table } from "@tanstack/react-table";

function PageSelector({ table }: { table: Table<any> }) {
  const {
    pagination: { pageIndex },
  } = table.getState();
  const [pageNumber, setPageNumber] = useState(pageIndex + 1);
  const [debouncedSetPageNumber] = useState(() =>
    debounce((newPageNumber: number) => {
      table.setPageIndex(newPageNumber - 1);
    }, 1000),
  );

  // sync the table state with the local state
  useEffect(() => {
    if (pageNumber !== pageIndex + 1) {
      setPageNumber(pageIndex + 1);
    }
  }, [pageIndex]);

  // debounce the page number change into the table state if the page number is valid
  useEffect(() => {
    const numericPageNumber = Number(pageNumber);
    if (!Number.isNaN(numericPageNumber) && numericPageNumber > 0 && numericPageNumber <= table.getPageCount()) {
      debouncedSetPageNumber(pageNumber);
    }
  }, [pageNumber]);

  const handlePageNumberChange = (event: any) => {
    event.preventDefault();

    setPageNumber(event.target.value);
  };

  return (
    <div className="flex flex-row text-xs font-normal text-gray-500 align-baseline">
      <span className="block">Page </span>
      <input
        type="number"
        className="focus:outline-none focus:shadow-outline-blue focus:border-blue-300 block mx-2 border-b border-gray-500"
        value={pageNumber}
        onChange={handlePageNumberChange}
        max={table.getPageCount()}
        min={1}
      />

      <span className="block font-semibold text-gray-900">of {table.getPageCount()}</span>
    </div>
  );
}

export default PageSelector;
