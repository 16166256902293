import { captureException } from "@sentry/react";
import type React from "react";
import { useEffect, useState } from "react";
import TemplateSlot from "../utils/TemplateSlot";

function LoginPage() {
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const next = urlParams.get("next");
    if (next) {
      setNextUrl(next);
    }
  }, []);

  const handleLoginClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const form = document.querySelector("form#loginForm") as HTMLFormElement;
    if (!form) {
      console.error("Form element not found.");
      captureException(new Error("Login form not found."));
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData(form);

    const handleLoginResponse = (response: Response): void => {
      if (response.ok && response.redirected) {
        // Ok, we are logged in, clear session storage to flush react query cache
        window.sessionStorage.clear();
        if (nextUrl) {
          window.location.href = nextUrl;
        } else {
          // Redirect to the homepage
          window.location.href = "/";
        }

        return;
      }

      // esle we let the form submit normally to handle login errors by Django
      form.submit();
    };

    try {
      const response = await submitForm(form.action, formData);
      if (!response.ok && response.status === 403) {
        const csrfToken = await fetchCsrfToken();
        formData.append("csrfmiddlewaretoken", csrfToken);
        const retryResponse = await submitForm(form.action, formData);
        handleLoginResponse(retryResponse);
        return;
      }

      handleLoginResponse(response);
    } catch (error: unknown) {
      // let the form submit normally
      console.error("An error occurred during login:", error as Error);

      form.submit();
    }
  };

  const submitForm = async (url: string, data: FormData): Promise<Response> => {
    return await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "X-CSRFToken": data.get("csrfmiddlewaretoken")?.toString() ?? "",
      },
    });
  };

  const fetchCsrfToken = async (): Promise<string> => {
    const response = await fetch("/get-csrf-token/", {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch CSRF token.");
    }

    const data = await response.json();
    const csrfInput = document.querySelector("[name=csrfmiddlewaretoken]") as HTMLInputElement;
    if (!csrfInput) {
      throw new Error("CSRF input element not found.");
    }

    csrfInput.value = data.csrfToken;
    return data.csrfToken as string;
  };

  return (
    <>
      <TemplateSlot slotID="slot_loginButton" keepClasses>
        <button
          className="py-3.5 w-full bg-nucleus text-white rounded hover:bg-nucleus/90 active:bg-nucleus/80 focus:ring-2 focus:ring-offset-2 focus:ring-nucleus/80 focus:ring-offset-nucleus/80 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitting}
          type="submit"
          onClick={handleLoginClick}
        >
          Login
        </button>
      </TemplateSlot>
    </>
  );
}

export { LoginPage };
