import React from "react";

import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { CellContext } from "@tanstack/react-table";

const MalwareAliases: (
  context: CellContext<SerializedMandiantMalware, SerializedMandiantMalware["aliases"]>,
) => JSX.Element = (context) => {
  const aliases = context.getValue();

  if (!Array.isArray(aliases)) {
    return <>-</>;
  }

  return (
    <div className="text-gray-600">
      {aliases.map((alias, index) => (
        <span key={`alias_${index}_${alias.name}`}>
          {alias.name}
          {index < aliases.length - 1 ? ", " : ""}
        </span>
      ))}
    </div>
  );
};

export default MalwareAliases;
