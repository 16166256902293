import React from "react";

import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { CellContext } from "@tanstack/react-table";

const MalwareIdRenderer: (
  context: CellContext<SerializedMandiantMalware, SerializedMandiantMalware["mandiant_malware_id"]>,
) => JSX.Element = (context) => {
  const id = context.getValue();

  if (!id) {
    return <span className="text-gray-400">-</span>;
  }

  return <span className="ml-6 font-semibold text-center text-gray-600">{id}</span>;
};

export default MalwareIdRenderer;
