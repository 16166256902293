import { DialogBody, DialogHeader, Dialog as MaterialDialog } from "@material-tailwind/react/components/Dialog";
import { Typography } from "@material-tailwind/react/components/Typography";
import classNames from "classnames";
import React from "react";

interface DialogProps {
  Trigger: React.ElementType;
  triggerProps?: React.ComponentPropsWithoutRef<any>;
  title?: string | React.ReactNode;
  description?: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
}

const Dialog = ({
  Trigger,
  triggerProps = {},
  title,
  description,
  isOpen = false,
  onOpenChange,
  children,
}: DialogProps) => {
  const handleOpen = () => onOpenChange?.(!isOpen);
  const handleDialogClose = () => onOpenChange?.(false);

  return (
    <React.Fragment>
      <Trigger {...triggerProps} onClick={handleOpen} />

      <MaterialDialog open={isOpen} handler={handleOpen}>
        {title && (
          <DialogHeader>
            {typeof title === "string" ? (
              <Typography variant="h5" color="blue-gray">
                {title}
              </Typography>
            ) : (
              title
            )}
            <button
              onClick={handleDialogClose}
              className={classNames(
                "absolute top-3.5 right-3.5 inline-flex items-center justify-center rounded-full p-1",
                "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
              )}
            >
              <i className="fa fa-close" />
            </button>
          </DialogHeader>
        )}

        <DialogBody divider>
          {description && <Typography className="text-gray-700 dark:text-gray-400">{description}</Typography>}
          {children}
        </DialogBody>
      </MaterialDialog>
    </React.Fragment>
  );
};

export default Dialog;