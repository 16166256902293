import { useEffect, useState } from "react";
import { isBrowser } from "./utils/misc/is";

type WidthSubscriber = () => void;
const widthSubscribers = new Set<WidthSubscriber>();

let currentWidth = isBrowser ? window.innerWidth : 0;
let listening = false;

function handleResize() {
  const newWidth = window.innerWidth;
  if (newWidth === currentWidth) {
    return;
  }

  currentWidth = newWidth;
  for (const subscriber of widthSubscribers) {
    subscriber();
  }
}

export function useViewportWidth() {
  const [width, setWidth] = useState(currentWidth);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    // Ensure listener is added only once
    if (!listening) {
      window.addEventListener("resize", handleResize);
      listening = true;
    }

    const subscriber = () => setWidth(currentWidth);
    widthSubscribers.add(subscriber);

    return () => {
      widthSubscribers.delete(subscriber);
      if (widthSubscribers.size === 0) {
        window.removeEventListener("resize", handleResize);
        listening = false;
      }
    };
  }, []);

  return width;
}
