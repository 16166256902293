import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const ZdiIdRenderer: (
  context: CellContext<FrontendSerializedThreat, FrontendSerializedThreat["zdi_id"]>,
) => JSX.Element = (context) => {
  const threat = context.row.original;

  if (!threat?.zdi_id) {
    return <span className="text-gray-400">-</span>;
  }

  return <span className="text-gray-600">{threat.zdi_can}</span>;
};

export default ZdiIdRenderer;
