import type React from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

import Toast, { type ToastMessage } from "@components/Toast";
import * as ToastPrimitive from "@radix-ui/react-toast";

type AddMessagePayload = Pick<ToastMessage, "title" | "content" | "duration" | "variant" | "type"> & {
  onDismiss?: () => void;
};

type ToastContextData = {
  addMessage: (data: AddMessagePayload) => void;
};

const ToastContext = createContext({} as ToastContextData);

type Props = {
  children: React.ReactNode;
};

function ToastProvider({ children }: Props) {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addMessage = useCallback((data: AddMessagePayload) => {
    const message: ToastMessage = {
      id: Date.now().toString(),
      title: data.title,
      content: data.content,
      type: data.type,
      variant: data.variant,
      onDismiss: data.onDismiss,
      duration: data.duration,
    };

    setMessages((state) => [...state, message]);
  }, []);

  const onMessageDismissed = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  const contextData = useMemo<ToastContextData>(() => {
    return {
      addMessage,
    };
  }, [addMessage]);

  return (
    <ToastContext.Provider value={contextData}>
      {children}

      <ToastPrimitive.Provider>
        {messages.map((message) => (
          <Toast
            key={message.id}
            message={message}
            onDismiss={() => {
              if (message.onDismiss) {
                message.onDismiss();
              }

              onMessageDismissed(message.id);
            }}
          />
        ))}
        <ToastPrimitive.Viewport />
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastProvider };

export function useCreateToast() {
  return useContext(ToastContext);
}
