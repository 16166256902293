import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

const NVDReferencesRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = context.getValue();

  const references = isArrayLikeString(value) ? safeParseJSONtoArray(value) : []; // something like [{\"url\": \"https://www.vmware.com/security/advisories/VMSA-2023-0013.html\", \"source\": \"security@vmware.com\"}]",

  if (references.length > 0) {
    return (
      <div>
        {references
          .map((ref, index) => {
            // The hyperlink text should be changed to be the domain of whatever URL the link takes you is.
            const url = new URL(ref.url);
            let title = url.hostname;
            // cut www. from the title if it exists
            if (title.startsWith("www.")) {
              title = title.slice(4);
            }

            return (
              <span key={`references-${ref.url}`}>
                <a
                  className="ease-nav-brand text-slate-600 hover:text-blue-700 inline-flex items-start font-semibold transition-all"
                  href={ref.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    {title}
                    <i className="fas fa-external-link-alt ml-1 text-xs" />
                  </span>
                </a>
              </span>
            );
          })
          .reduce((prev, curr) => [prev, " ", curr])}
      </div>
    );
  }

  return <div>-</div>;
};

export default NVDReferencesRenderer;
