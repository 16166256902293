/** !Note: Barrel exports were initially a neat solution for consolidating specific features into a single file. However, this practice is now discouraged as it interferes with effective bundle tree shaking and chunking. We need to refactor these exports to direct imports or devise a better approach. */

import { useOrganizationTier } from "@hooks/useOrganizationsConfiguration";
import useUserData, { useMutateUserData } from "@hooks/useUserData";
import { updateGlobalPageState, useGlobalPageState } from "@pages/state/globalState";

interface QuickStartItem {
  id: string;
  title: string;
  description: string;
  learnMoreLink: string;
  isPaidOnly?: boolean;
}

function useCompletedItems() {
  const { data, isLoading } = useUserData();

  if (isLoading) {
    return null;
  }

  return data?.quick_start_items_completed || [];
}

function useQuickStartCompleteTimestamp() {
  const { data } = useUserData();

  return data?.quick_start_complete_datetime;
}

const QUICK_START_ITEMS_IDS = {
  create_search: "create_search",
  save_search: "save_search",
  monitor_search: "monitor_search",
  // enable_integration: "enable_integration", // disabled 25.03.24
  configure_columns: "configure_columns",
  add_note: "add_note",
  invite_team_members: "invite_team_members",
  add_favorite: "add_favorite",
  add_vuln_tag: "add_vuln_tag",
};

function useQuickStartItems() {
  const organizationTier = useOrganizationTier();
  const completedItems = useCompletedItems();

  if (!completedItems) {
    // loading state
    return null;
  }

  const items: QuickStartItem[] = [
    {
      id: QUICK_START_ITEMS_IDS.configure_columns,
      title: "Configure Table Columns",
      description:
        "Customize the table to compare data sources and fields so you can analyze a vulnerability from numerous angles.",
      isPaidOnly: false,
      learnMoreLink: "https://help.nucleussec.com/docs/1-configure-table-columns",
    },
    {
      id: QUICK_START_ITEMS_IDS.create_search,
      title: "Run a Search",
      description: "Ask questions of the entire vulnerability ecosystem, right at your fingertips.",
      learnMoreLink: "https://help.nucleussec.com/docs/2-run-a-search",
    },
    {
      id: QUICK_START_ITEMS_IDS.save_search,
      title: "Save a Search",
      description: "Save questions that you need answered so you can stay on top of the data you care about.",
      learnMoreLink: "https://help.nucleussec.com/docs/3-save-a-search",
    },
    {
      id: QUICK_START_ITEMS_IDS.monitor_search,
      title: "Monitor a Search",
      description: "Stay up-to-date when the answer to your questions change.",
      learnMoreLink: "https://help.nucleussec.com/docs/4-monitor-a-search",
    },
    {
      id: QUICK_START_ITEMS_IDS.add_favorite,
      title: "Add a Favorite",
      description: "Add a vulnerability to your favorites so you can quickly access it later.",
      learnMoreLink: "https://help.nucleussec.com/docs/5-add-a-favorite",
    },
    // {
    //   id: QUICK_START_ITEMS_IDS.enable_integration,
    //   title: "Enable your first integration",
    //   description:
    //     "Provide your own data feeds so you can leverage existing investments alongside the rest of the aggregated vulnerability intelligence.",
    //   learnMoreLink: "https://help.nucleussec.com/docs/vip-integrations",
    //   isPaidOnly: true,
    // },
    {
      id: QUICK_START_ITEMS_IDS.add_note,
      title: "Add a Vulnerability Note",
      description:
        "Enhance a vulnerability’s profile with data points unique to your organization, and align your team on what is important.",
      learnMoreLink: "https://help.nucleussec.com/docs/6-add-a-note",
    },
    {
      id: QUICK_START_ITEMS_IDS.add_vuln_tag,
      title: "Add a Vulnerability Tag",
      description: "Tag vulnerabilities for quick sorting and filtering.",
      learnMoreLink: "https://help.nucleussec.com/docs/7-add-a-tag",
    },
    {
      id: QUICK_START_ITEMS_IDS.invite_team_members,
      title: "Invite members of your team",
      description:
        "Share the console with your team so you can collaborate and share information with the right people.",
      learnMoreLink: "https://help.nucleussec.com/docs/vip-invite-your-team",
      canManuallyMarkComplete: true,
    },
  ].filter((item) => {
    if (item.isPaidOnly && organizationTier !== "PAID") {
      return false;
    }

    return true;
  });

  return [
    items.filter((item) => !completedItems.includes(item.id)),
    items.filter((item) => completedItems.includes(item.id)),
  ];
}

function useMarkQuickStartItemAsCompleted(quickStartItems: QuickStartItem[][] | null) {
  const { mutateAsync } = useMutateUserData();
  if (!quickStartItems) {
    return null;
  }

  const [nextStepsItems, completedItems] = quickStartItems;

  return async (id: keyof typeof QUICK_START_ITEMS_IDS) => {
    if (completedItems.find((item) => item.id === id)) {
      return;
    }

    // if that was the latest item, marke quick start as completed
    const isCompleted = nextStepsItems.length === 1;

    await mutateAsync({
      quick_start_items_completed: [...completedItems.map((item) => item.id), QUICK_START_ITEMS_IDS[id]],
      quick_start_complete_datetime: isCompleted ? new Date().toISOString() : undefined,
    });
  };
}

function useQuickStart() {
  const completedTimestamp = useQuickStartCompleteTimestamp();
  const quickStartItems = useQuickStartItems();
  const markNextStepAsCompleted = useMarkQuickStartItemAsCompleted(quickStartItems);

  const [nextStepsItems, completedItems] = quickStartItems ?? [[], []];
  const totalItems = nextStepsItems.length + completedItems.length;
  const completionPercent = (completedItems.length / totalItems) * 100;

  // quickStart is available if its not completed or it was completed less than 24 hours ago
  const isAvailable =
    quickStartItems &&
    (completionPercent < 100 ||
      (completedTimestamp && new Date(completedTimestamp).getTime() > Date.now() - 24 * 60 * 60 * 1000));

  return {
    enabled: isAvailable,
    percent: completionPercent,
    nextStepsItems: nextStepsItems,
    completedItems: completedItems,
    totalItems: totalItems,
    isCompleted: completionPercent === 100,
    markNextStepAsCompleted: markNextStepAsCompleted ?? (() => {}),
    progressText: `${completedItems.length}/${totalItems}`,
    url: "https://help.nucleussec.com/docs/getting-started-with-vip",
  };
}

function useQuickStartControls() {
  const { isQuickStartOpen } = useGlobalPageState();

  const openClose = (newState: boolean) => {
    updateGlobalPageState({ isQuickStartOpen: newState });
  };

  return {
    isOpen: isQuickStartOpen,
    openClose,
  };
}

export default useQuickStart;
export { useQuickStartControls, QUICK_START_ITEMS_IDS };
export type { QuickStartItem };
