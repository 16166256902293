import classNames from "classnames";
import { forwardRef } from "react";
import { Link as RouterLink, useNavigate } from "react-router";

interface LinkProps {
  // in case of using react-router-dom Link
  to?: string;
  // in case of using html anchor
  href?: string;
  color?: "primary" | "success" | "text" | "danger";
  size?: "small" | "medium" | "large";
  font?: "normal" | "semiBold" | "bold";
  onClick?: () => void;
  className?: string;
  target?: string;
  rel?: string;
  children: React.ReactNode;
}

const Link = forwardRef<HTMLAnchorElement | HTMLSpanElement, LinkProps>(
  (
    { to, href, color = "primary", size = "medium", font = "normal", onClick, className, children, target, rel },
    ref,
  ) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        e.preventDefault();
        onClick();
      }

      if (to) {
        e.preventDefault();
        navigate(to);
      }
    };

    const linkClasses = classNames(
      "transition-color cursor-pointer",
      {
        "text-blue-600 hover:text-blue-400": color === "primary",
        "text-green-500 hover:text-green-700": color === "success",
        "text-gray-700 hover:text-black": color === "text",
        "text-red-700 hover:text-red-800": color === "danger",
      },
      {
        "text-xs": size === "small",
        "text-sm": size === "medium",
        "text-base": size === "large",
      },
      {
        "font-normal": font === "normal",
        "font-semibold": font === "semiBold",
        "font-bold": font === "bold",
      },
      className,
    );

    if (href) {
      return (
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          href={href}
          className={linkClasses}
          onClick={onClick}
          target={target}
          rel={rel}
        >
          {children}
        </a>
      );
    }

    return to ? (
      <RouterLink ref={ref as React.Ref<HTMLAnchorElement>} to={to} className={linkClasses} onClick={handleClick}>
        {children}
      </RouterLink>
    ) : (
      <span ref={ref as React.Ref<HTMLSpanElement>} className={linkClasses} onClick={handleClick}>
        {children}
      </span>
    );
  },
);

Link.displayName = "Link";

export { Link };