import { useUnseenAlertsCount } from "@features/alerts/hooks/useAlerts";
import useFeeds from "@hooks/useFeeds";
import { useLocation } from "@hooks/useLocation";
import { Badge } from "@material-tailwind/react/components/Badge";
import { Drawer } from "@material-tailwind/react/components/Drawer";
import { Typography } from "@material-tailwind/react/components/Typography";
import { useIsMalwareListingEnabled, useIsWatchSearchesEnabled } from "@queries/useSiteSettings";
import classNames from "classnames";
import type React from "react";
import { useMemo } from "react";
import { HeaderMenu, type HeaderMenuItemProps, type MenuTypographyItemProps } from "./HeaderMenu";

interface NavListProps {
  isMobileNavOpen: boolean;
  setIsMobileNavOpen: (isOpen: boolean) => void;
}

interface DesktopMenuProps {
  navListItems: NavListMenuItemProps[];
  currentPath: string;
}

interface NavListMenuItemProps extends HeaderMenuItemProps {
  subItems?: MenuTypographyItemProps[];
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ navListItems, currentPath }) => {
  return (
    <ul className="hidden lg:flex flex-row items-center gap-4">
      {navListItems.map((item) => {
        const { label, iconClass, url, BadgeWrapper, subItems } = item;
        const isActive = (() => {
          if (subItems && subItems.length > 0) {
            return subItems.some(({ url }) => currentPath === url);
          }

          return currentPath === url;
        })();

        return (
          <div
            key={`NavItem-${label}`}
            className={classNames("relative", {
              "text-blue-600": isActive,
            })}
          >
            <HeaderMenu
              menuItems={subItems && subItems.length > 0 ? subItems : undefined}
              iconClass={iconClass}
              label={label}
              BadgeWrapper={BadgeWrapper}
              isActive={isActive}
              showChevron={subItems && subItems.length > 0}
              url={url}
            />
          </div>
        );
      })}
    </ul>
  );
};

interface MobileDrawerMenuProps {
  navListItems: NavListMenuItemProps[];
  currentPath: string;
  isMobileNavOpen: boolean;
  toggleDrawer: () => void;
}

const MobileDrawerMenu: React.FC<MobileDrawerMenuProps> = ({
  navListItems,
  currentPath,
  isMobileNavOpen,
  toggleDrawer,
}) => {
  return (
    <Drawer open={isMobileNavOpen} onClose={toggleDrawer} className="p-4">
      <ul className="flex flex-col gap-2">
        {navListItems.flatMap((item) => {
          const { label, iconClass, url, BadgeWrapper, subItems } = item;

          // If there are subItems, render them directly instead of the parent item
          if (subItems && subItems.length > 0) {
            return subItems.map((subItem) => {
              const isActive = currentPath === subItem.url;
              return (
                <Typography
                  key={`Drawer-${subItem.label}`}
                  as="a"
                  href={subItem.url}
                  variant="small"
                  color={isActive ? "blue" : "gray"}
                  onClick={toggleDrawer}
                  className={classNames(
                    "flex items-center gap-2 text-sm font-normal tracking-wider rounded-md p-2 hover:bg-blue-500 hover:text-gray-300 transition-colors",
                    {
                      "bg-blue-100 text-blue-600": isActive,
                      "text-black": !isActive,
                    },
                  )}
                >
                  {iconClass && <i className={`fa ${iconClass} h-5 w-5`} />}
                  {subItem.label}
                  {subItem.BadgeWrapper && <subItem.BadgeWrapper>{null}</subItem.BadgeWrapper>}
                </Typography>
              );
            });
          }

          // If no subItems, render the main item
          const isActive = currentPath === url;
          return (
            <Typography
              key={`Drawer-${label}`}
              as="a"
              href={url}
              variant="small"
              color={isActive ? "blue" : "gray"}
              onClick={toggleDrawer}
              className={classNames(
                "flex items-center gap-2 text-sm font-normal tracking-wider rounded-md p-2 hover:bg-blue-500 hover:text-gray-300 transition-colors",
                {
                  "bg-blue-100 text-blue-600": isActive,
                  "text-black": !isActive,
                },
              )}
            >
              {iconClass && <i className={`fa ${iconClass} h-5 w-5`} />}
              {label}
              {BadgeWrapper && <BadgeWrapper>{null}</BadgeWrapper>}
            </Typography>
          );
        })}
      </ul>
    </Drawer>
  );
};

export const NavList: React.FC<NavListProps> = ({ isMobileNavOpen, setIsMobileNavOpen }) => {
  const currentPath = useLocation();
  const isWatchSearchesEnabled = useIsWatchSearchesEnabled();
  const isMalwareListingEnabled = useIsMalwareListingEnabled();
  const unseenAlerts = useUnseenAlertsCount();
  const { feeds } = useFeeds();

  const toggleDrawer = () => setIsMobileNavOpen(!isMobileNavOpen);

  const analyzeSubItems = useMemo<MenuTypographyItemProps[] | undefined>(() => {
    if (isMalwareListingEnabled && feeds?.some((feed) => feed.db_prefix === "mandiant" && feed.is_enabled)) {
      return [
        {
          label: "Vulnerabilities",
          url: "/analyze/",
        },
        {
          label: "Malware [Beta]",
          url: "/malware/",
        },
      ];
    }

    return undefined;
  }, [isMalwareListingEnabled, feeds]);

  const navListItems = useMemo<NavListMenuItemProps[]>(() => {
    const items: NavListMenuItemProps[] = [
      {
        label: isMobileNavOpen ? "Home" : "", // ? very non-optimal way
        iconClass: "far fa-home",
        url: "/",
      },
      {
        label: "Analyze",
        url: "/analyze/",
        subItems: analyzeSubItems,
      },
      {
        label: "Monitor",
        url: "/monitoring/",
        BadgeWrapper:
          unseenAlerts > 0
            ? ({ children }) => (
                <Badge color="red" overlap="square">
                  {children}
                </Badge>
              )
            : undefined,
        subItems: [
          {
            label: "Configure",
            url: "/monitoring/",
          },
          {
            label: "Alerts",
            url: "/monitoring/alerts/",
            BadgeWrapper:
              unseenAlerts > 0
                ? ({ children }) => (
                    <span className="relative block">
                      <span className="absolute top-1 right-1.5 inline-flex items-center justify-center text-xs font-bold leading-none text-red-100 bg-red-500 rounded-full w-5 h-5">
                        {unseenAlerts}
                      </span>
                      {children}
                    </span>
                  )
                : undefined,
          },
        ],
      },
      {
        label: "Integrations",
        url: "/integrations/",
      },
    ];

    // Filter out items based on availability
    return items.filter((item) => {
      if (item.label === "Monitor" && !isWatchSearchesEnabled) {
        return false;
      }

      return true;
    });
  }, [analyzeSubItems, isWatchSearchesEnabled, unseenAlerts, isMobileNavOpen]);

  return (
    <>
      <DesktopMenu navListItems={navListItems} currentPath={currentPath} />
      <MobileDrawerMenu
        navListItems={navListItems}
        currentPath={currentPath}
        isMobileNavOpen={isMobileNavOpen}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};
