import flatpickr from "flatpickr";
import type { Instance } from "flatpickr/dist/types/instance";
import type { Options } from "flatpickr/dist/types/options";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

type FlatpickrValue = string | string[] | Date[] | Date | number | null;
type FnCallback = (selectedDates: Date[], dateStr: string, instance: Instance) => void;
interface RenderFunctionProps extends Omit<FlatpickrProps, "render" | "children"> {
  defaultValue?: string;
  value?: FlatpickrValue;
}
interface FlatpickrProps {
  defaultValue?: string;
  options?: Options;
  value?: FlatpickrValue;
  onChange?: FnCallback;
  onOpen?: FnCallback;
  onClose?: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
  onMonthChange?: FnCallback;
  onYearChange?: FnCallback;
  onReady?: FnCallback;
  onValueUpdate?: FnCallback;
  onDayCreate?: FnCallback;
  onCreate?: (instance: Instance) => void;
  onDestroy?: (instance: Instance) => void;
  className?: string;
  children?: React.ReactNode;
  render?: (
    props: RenderFunctionProps,
    refCallback: (node: HTMLInputElement | HTMLDivElement | null) => void,
  ) => React.ReactNode;
}

const Flatpickr = forwardRef<Instance | null, FlatpickrProps>(
  ({ defaultValue, options = {}, value, onCreate, onDestroy, render, children, ...props }, ref) => {
    const nodeRef = useRef<HTMLInputElement | HTMLDivElement | null>(null);
    const flatpickrInstance = useRef<Instance | null>(null);

    // Expose flatpickr instance via ref
    useImperativeHandle(ref, () => flatpickrInstance.current as Instance);

    // Separate Flatpickr-specific props from HTML props
    const {
      onChange,
      onOpen,
      onClose,
      onMonthChange,
      onYearChange,
      onReady,
      onValueUpdate,
      onDayCreate,
      ...htmlProps
    } = props;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <props triggers re-render>
    useEffect(() => {
      if (nodeRef.current) {
        const mergedOptions = mergeHooks(options, props);

        flatpickrInstance.current = flatpickr(nodeRef.current, {
          ...mergedOptions,
          onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => {
            (nodeRef.current as HTMLInputElement)?.blur?.();
            onClose?.(selectedDates, dateStr, instance);
          },
        });

        if (value) {
          flatpickrInstance.current.setDate(value, false);
        }

        if (onCreate && flatpickrInstance.current) {
          onCreate(flatpickrInstance.current);
        }
      }

      return () => {
        if (flatpickrInstance.current) {
          if (onDestroy) {
            onDestroy(flatpickrInstance.current);
          }
          flatpickrInstance.current.destroy();
          flatpickrInstance.current = null;
        }
      };
    }, [options, onCreate, onDestroy]);

    useEffect(() => {
      if (flatpickrInstance.current && value) {
        flatpickrInstance.current.setDate(value, false);
      }
    }, [value]);

    const handleNodeChange = (node: HTMLInputElement | HTMLDivElement | null) => {
      nodeRef.current = node;
    };

    if (render) {
      return render({ ...props, defaultValue, value }, handleNodeChange);
    }

    return options.wrap ? (
      <div {...htmlProps} ref={handleNodeChange}>
        {children}
      </div>
    ) : (
      <input {...htmlProps} defaultValue={defaultValue} ref={handleNodeChange} />
    );
  },
);

type HookKey =
  | "onChange"
  | "onOpen"
  | "onClose"
  | "onMonthChange"
  | "onYearChange"
  | "onReady"
  | "onValueUpdate"
  | "onDayCreate";

const hooks: HookKey[] = [
  "onChange",
  "onOpen",
  "onClose",
  "onMonthChange",
  "onYearChange",
  "onReady",
  "onValueUpdate",
  "onDayCreate",
];

function mergeHooks(inputOptions: Options, props: FlatpickrProps): Options {
  const options: Options = { ...inputOptions };

  for (const hook of hooks) {
    const hookValue = props[hook];
    if (hookValue) {
      options[hook] = Array.isArray(hookValue) ? hookValue : [hookValue];
    }
  }

  return options;
}

export { Flatpickr };
export type { FlatpickrValue };
