import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, MandiantVendorFixUrl } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_SOURCES_TO = 3;
const MandiantVendorFixUrlsRenderer: (
  context: CellContext<FrontendSerializedThreat, MandiantVendorFixUrl[]>,
) => JSX.Element = (context) => {
  const values = context.getValue();

  if (!Array.isArray(values)) {
    return <>-</>;
  }

  const renderSource = (fixUrl: MandiantVendorFixUrl, index) => (
    <a
      key={`mandiant_fix_urls_${index}`}
      className="flex font-semibold transition-all flex-start ease-nav-brand text-slate-600 hover:text-blue-700"
      href={fixUrl.url}
      target="_blank"
      rel="noreferrer"
    >
      <span>
        {fixUrl.name}
        <i className="inline ml-1 text-xs fas fa-external-link-alt" />
      </span>
    </a>
  );

  const fullView = <div>{values.map(renderSource)}</div>;

  if (values.length > LIMIT_SOURCES_TO) {
    return (
      <div>
        <div>{values.slice(0, LIMIT_SOURCES_TO).map((source, index) => renderSource(source, index))}</div>
        <Tooltip value={fullView}>
          <div className="mt-2 text-xs text-right text-gray-600 cursor-progress">
            And {values.length - LIMIT_SOURCES_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export default MandiantVendorFixUrlsRenderer;
