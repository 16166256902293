import { type DependencyList, type EffectCallback, useEffect, type useLayoutEffect } from "react";
import { useRef } from "react";
import { depsAreSame } from "./misc/depsAreSame";
import { type BasicTarget, getTargetElement } from "./misc/domTarget";
import { useUnmount } from "./useUnmount";

const createEffectWithTarget = (useEffectType: typeof useEffect | typeof useLayoutEffect) => {
  /**
   * Specialized React hook that combines the functionality of useEffect with DOM element targeting. It's particularly useful when you need to manage side effects that depend on both regular dependencies and DOM elements.
   * Tracks changes to both regular dependencies and DOM elements
   * Supports single or multiple target elements
   * Properly handles cleanup when dependencies or targets change
   * @param effect
   * @param deps
   * @param target target should compare ref.current vs ref.current, dom vs dom, ()=>dom vs ()=>dom
   */
  const useEffectWithTarget = (
    effect: EffectCallback,
    deps: DependencyList,
    target: BasicTarget<any> | BasicTarget<any>[],
  ) => {
    const hasInitRef = useRef(false);

    const lastElementRef = useRef<(Element | null)[]>([]);
    const lastDepsRef = useRef<DependencyList>([]);

    const unLoadRef = useRef<any>();

    useEffectType(() => {
      const targets = Array.isArray(target) ? target : [target];
      const els = targets.map((item) => getTargetElement(item));

      // init run
      if (!hasInitRef.current) {
        hasInitRef.current = true;
        lastElementRef.current = els;
        lastDepsRef.current = deps;

        unLoadRef.current = effect();
        return;
      }

      if (
        els.length !== lastElementRef.current.length ||
        !depsAreSame(lastElementRef.current, els) ||
        !depsAreSame(lastDepsRef.current, deps)
      ) {
        unLoadRef.current?.();

        lastElementRef.current = els;
        lastDepsRef.current = deps;
        unLoadRef.current = effect();
      }
    });

    useUnmount(() => {
      unLoadRef.current?.();
      // for react-refresh
      hasInitRef.current = false;
    });
  };

  return useEffectWithTarget;
};

const useEffectWithTarget = createEffectWithTarget(useEffect);

export { useEffectWithTarget, createEffectWithTarget };
