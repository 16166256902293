import classNames from "classnames";
import React from "react";

import * as ToastPrimitive from "@radix-ui/react-toast";
import { Icon } from "./Icon";

export type ToastMessage = {
  id: string;
  title?: string;
  content: string;
  type?: "background" | "foreground";
  duration?: number;
  variant: "success" | "error";
  onDismiss?: () => void;
};

type Props = {
  message: ToastMessage;
  onDismiss: (id: string) => void;
};

const Toast = ({ message, onDismiss }: Props) => {
  return (
    <ToastPrimitive.Root
      open={true}
      onOpenChange={() => onDismiss(message.id)}
      duration={message.duration ?? 3000}
      type={message.type ?? "foreground"}
      className={classNames(
        "z-[99999] fixed bottom-4 inset-x-4 w-auto md:right-4 md:left-auto md:w-full md:max-w-sm shadow-lg rounded-lg",
        "radix-state-open:animate-toast-slide-in-bottom md:radix-state-open:animate-toast-slide-in-right",
        "radix-state-closed:animate-toast-hide",
        "radix-swipe-end:animate-toast-swipe-out",
        "translate-x-radix-toast-swipe-move-x",
        "radix-swipe-cancel:translate-x-0 radix-swipe-cancel:duration-200 radix-swipe-cancel:ease-[ease]",
        "focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75",
        {
          "bg-green-50 border-l-4 border-green-400": message.variant === "success",
          "bg-red-50 border-l-4 border-red-400": message.variant === "error",
        },
      )}
    >
      <div className="flex">
        <div className="flex items-center flex-1 w-0 py-4 pl-5">
          <div className="radix w-full">
            {message.title && (
              <ToastPrimitive.Title
                className={classNames("text-sm font-semibold", {
                  "text-green-900": message.variant === "success",
                  "text-red-900": message.variant === "error",
                })}
              >
                {message.title}
              </ToastPrimitive.Title>
            )}
            {message.content && (
              <ToastPrimitive.Description className="mt-1 text-sm text-gray-700">
                {message.content}
              </ToastPrimitive.Description>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col px-3 py-2 space-y-1">
            <div className="flex">
              <div className="flex flex-col px-3 py-2">
                <ToastPrimitive.Close className="flex items-center justify-center w-5 h-5">
                  <Icon icon="close" fixedWidth className="text-gray-400 hover:text-gray-500" />
                </ToastPrimitive.Close>
              </div>
            </div>
            <div className="flex flex-1 h-0">{/* placeholder */}</div>
          </div>
        </div>
      </div>
    </ToastPrimitive.Root>
  );
};

export default Toast;
