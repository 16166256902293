import classNames from "classnames";
import React from "react";
import { type W, w } from "windstitch";

const Button = w.button(
  "select-none focus:outline-none border-2 rounded font-semibold transition ease-in duration-200 spacing-normal disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center justify-center gap-1",
  {
    variants: {
      color: {
        primary:
          "bg-blue-800 border-blue-800 text-white hover:bg-blue-900 active:bg-blue-1000 focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 focus:ring-offset-blue-100",
        simple:
          "text-blue-800 border-transpant bg-transparent focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 focus:ring-offset-gray-100",
        secondary:
          "bg-white border-blue-700 text-blue-800 hover:border-blue-900 hover:text-blue-900 active:bg-blue-1000 focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 focus:ring-offset-blue-100",
        white:
          "bg-white border-gray-500 text-gray-600 hover:bg-gray-200 active:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 focus:ring-offset-gray-100",
        success:
          "bg-green-800 border-green-800 text-white hover:bg-green-900 active:bg-green-1000 focus:ring-2 focus:ring-offset-2 focus:ring-green-600 focus:ring-offset-green-100",
        danger:
          "bg-red-800 border-red-800 text-white hover:bg-red-900 active:bg-red-1000 focus:ring-2 focus:ring-offset-2 focus:ring-red-600 focus:ring-offset-red-100",
      },
      size: {
        xsmall: "text-xs px-1 py-1 leading-3",
        small: "text-xs px-2 py-1",
        medium: "text-sm px-3 py-1.5",
        large: "text-base px-5 py-1.5",
      },
    },
    defaultVariants: {
      color: "primary",
      size: "medium",
    },
  },
);

Button.displayName = "Button";

type ButtonProps = W.Infer<typeof Button>;

interface ButtonWithModifiersProps extends ButtonProps {
  isLoading?: boolean;
}

const ButtonWithModifiers = React.forwardRef<
  HTMLButtonElement, // Type of the ref
  ButtonWithModifiersProps // Type of the props
>(({ isLoading, ...props }: ButtonWithModifiersProps, ref) => {
  // Add loading and error classes to the button if necessary
  const classes = classNames(
    {
      "pointer-events-none cursor-wait animate-pulse": isLoading,
    },
    props.className,
  );

  return <Button ref={ref} {...props} className={classes} />;
});

export default ButtonWithModifiers;
