import { type IntrospectionsResponse, getIntrospections } from "@queries/useIntrospections";

import type { FieldReferencesFieldsState } from "@features/field_references/fieldReferences.state";
import { getLexer } from "@utils/Lexer";
import transformTokens from "./searchQueryToUrl/transformTokens";
import { validateTokens } from "./searchQueryToUrl/validateTokens";

// "cached" version of searchQueryToUrl
const searchQueryToUrlCache: Record<string, string> = {};

/**
 * The main function that converts a search query string into a URL-friendly format.
 * It tokenizes the search query, validates the tokens, and transforms them into a format
 * that can be sent to the backend API.
 *
 * @param searchQuery The search query string to convert.
 * @param introspections The introspections object containing field configurations.
 * @returns The converted search query string.
 * @throws Error if the search query is invalid.
 */
function searchQueryToUrl(searchQuery: string, introspections: IntrospectionsResponse) {
  // Search_Query is a DjangoQL query, BUT data types might be different from what we have on backend(due to display_type), so we need to convert them.
  const tokens = getLexer().setInput(searchQuery).lexAll();
  validateTokens(introspections, tokens);
  const builtQuery = transformTokens(introspections, tokens);
  return builtQuery;
}

/**
 * A cached version of `searchQueryToUrl` that stores the result of a search query conversion
 * in an object to avoid redundant computations. If the search query has already been converted,
 * it retrieves the result from the cache. Otherwise, it calls `searchQueryToUrl` to convert the
 * search query and stores the result in the cache before returning it.
 *
 * @param searchQuery The search query string to convert.
 * @param introspections The introspections object containing field configurations.
 * @returns The converted search query string.
 * @throws Error if the search query is invalid.
 */
function searchQueryToUrl_cached(searchQuery: string, introspections: IntrospectionsResponse) {
  const cacheKey = searchQuery;

  if (searchQueryToUrlCache[cacheKey]) {
    return searchQueryToUrlCache[cacheKey];
  }

  const resultQuery = searchQueryToUrl(searchQuery, introspections);
  searchQueryToUrlCache[cacheKey] = resultQuery;
  return searchQueryToUrlCache[cacheKey];
}

/**
 * A silent version of `searchQueryToUrl_cached` that catches any errors that may occur during
 * the search query conversion process. If an error occurs, it returns an empty string instead
 * of throwing an error.
 *
 * @param searchQuery The search query string to convert.
 * @param introspections The introspections object containing field configurations.
 * @returns The converted search query string, or an empty string if an error occurs.
 */
function searchQueryToUrl_silent(searchQuery: string, introspections: IntrospectionsResponse): string {
  try {
    return searchQueryToUrl_cached(searchQuery, introspections);
  } catch (e) {
    return "";
  }
}

/**
 * A function that attempts to transform a search query string using the provided introspections
 * object. If no introspections object is provided, it retrieves the introspections using the
 * `getIntrospections` function. If an error occurs during the transformation process, it
 * returns null.
 *
 * @param searchQuery The search query string to transform.
 * @param introspections The introspections object containing field configurations.
 * @returns The transformed search query string, or null if an error occurs.
 */
function transformSearchQuery_silent(
  searchQuery: string,
  introspections: IntrospectionsResponse = getIntrospections(),
) {
  try {
    return searchQueryToUrl(searchQuery, introspections);
  } catch (e) {
    return null;
  }
}

/**
 * A function that validates a search query string by tokenizing it and passing the tokens
 * to the `validateTokens` function along with the introspections object. It returns true
 * if the search query is valid, or throws an error if it is invalid.
 *
 * @param searchQuery The search query string to validate.
 * @returns True if the search query is valid.
 * @throws Error if the search query is invalid.
 */
function validateSearchQuery(searchQuery: string) {
  const tokens = getLexer().setInput(searchQuery).lexAll();
  const introspections = getIntrospections()!;
  return validateTokens(introspections, tokens);
}

/**
 * A function that checks if a search query string is valid by attempting to tokenize it
 * and validate the tokens using the `validateTokens` function along with the introspections
 * object. If the validation succeeds, it returns true. If an error occurs during the validation
 * process, it returns false.
 *
 * @param searchQuery The search query string to check for validity.
 * @returns True if the search query is valid, false otherwise.
 */
function isValidSearchQuery(searchQuery: string) {
  if (!searchQuery) {
    return false;
  }

  try {
    const tokens = getLexer().setInput(searchQuery).lexAll();
    const introspections = getIntrospections();

    if (!introspections) {
      console.error("No introspections found");
      return false;
    }

    return validateTokens(introspections, tokens);
  } catch (e) {
    return false;
  }
}

/**
 * A function that attempts to convert a search query string into a URL-friendly format
 * using the `searchQueryToUrl_cached` function and the provided introspections object.
 * If the search query is empty, it returns an empty string. If an error occurs during
 * the conversion process, it also returns an empty string.
 *
 * @param searchQuery The search query string to convert.
 * @param introspections The introspections object containing field configurations.
 * @returns The converted search query string, or an empty string if the search query is empty or an error occurs.
 */
function getSearchQueryWithValidation(searchQuery: string, introspections: IntrospectionsResponse) {
  if (!searchQuery) {
    return "";
  }

  let searchUrl = "";
  try {
    searchUrl = searchQueryToUrl_cached(searchQuery, introspections);
  } catch (error: any) {
    return "";
  }

  return searchUrl;
}

export default searchQueryToUrl;
export {
  searchQueryToUrl_silent,
  searchQueryToUrl_cached,
  transformSearchQuery_silent,
  isValidSearchQuery,
  getSearchQueryWithValidation,
  validateSearchQuery,
};
