import Badge from "@components/Badge";
import Tooltip from "@components/Tooltip";
import { numberValueToColor } from "@configs/colors";
import type {
  FrontendSerializedThreat,
  ThreatKey,
  VulncheckKevCVSSv2,
  VulncheckKevCVSSv3,
  VulncheckKevCVSSv4,
} from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const renderCVSSBadge = (score: number, fieldName: ThreatKey) => {
  const color = numberValueToColor(fieldName, score);
  return <Badge color={color}>{score}</Badge>;
};

const renderCVSSv2 = (context: CellContext<FrontendSerializedThreat, VulncheckKevCVSSv2>) => {
  const value = context.getValue();
  if (!value) {
    return <div className="text-sm text-gray-500">-</div>;
  }

  return (
    <Tooltip
      value={
        <div className="text-sm text-gray-500">
          <div>Access Complexity: {value.accessComplexity}</div>
          <div>Access Vector: {value.accessVector}</div>
          <div>Authentication: {value.authentication}</div>
          <div>Availability Impact: {value.availabilityImpact}</div>
          <div>Confidentiality Impact: {value.confidentialityImpact}</div>
          <div>Integrity Impact: {value.integrityImpact}</div>
          <div>Vector String: {value.vectorString}</div>
        </div>
      }
    >
      <div className="text-blue-800 font-normal cursor-pointer">
        {renderCVSSBadge(value.baseScore, "nvd_cvssv2_base_score")}
      </div>
    </Tooltip>
  );
};

const renderCVSSv3 = (context: CellContext<FrontendSerializedThreat, VulncheckKevCVSSv3>) => {
  const value = context.getValue();
  if (!value) {
    return <div className="text-sm text-gray-500">-</div>;
  }

  return (
    <Tooltip
      value={
        <div className="text-sm text-gray-500">
          <div>Attack Complexity: {value.attackComplexity}</div>
          <div>Attack Vector: {value.attackVector}</div>
          <div>Availability Impact: {value.availabilityImpact}</div>
          <div>Confidentiality Impact: {value.confidentialityImpact}</div>
          <div>Integrity Impact: {value.integrityImpact}</div>
          <div>Privileges Required: {value.privilegesRequired}</div>
          <div>Scope: {value.scope}</div>
          <div>User Interaction: {value.userInteraction}</div>
          <div>Vector String: {value.vectorString}</div>
          <div>Base Severity: {value.baseSeverity}</div>
        </div>
      }
    >
      <div className="text-blue-800 font-normal cursor-pointer">
        {renderCVSSBadge(value.baseScore, "nvd_cvssv3_base_score")}
      </div>
    </Tooltip>
  );
};

const renderCVSSv4 = (context: CellContext<FrontendSerializedThreat, VulncheckKevCVSSv4>) => {
  const value = context.getValue();
  if (!value) {
    return <div className="text-sm text-gray-500">-</div>;
  }

  // But we might want to edit this once vulncheck_nvd_cvssv4 is implemented
  return renderCVSSv3(context as CellContext<FrontendSerializedThreat, VulncheckKevCVSSv3>);
};

export const vulncheck_nvd_cvssv2 = renderCVSSv2;
export const vulncheck_nvd_cvssv3 = renderCVSSv3;
export const vulncheck_nvd_cvssv4 = renderCVSSv4;
