import { useCreateToast } from "@hooks/useCreateToast";
import useGlobalVariables, { getGlobalVariables } from "@hooks/useGlobalVariables";
import type SerializedAlert from "@interfaces/SerializedAlert";
import {
  type UseQueryOptions,
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation from "@utils/useFetchMutation";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import {
  type AlertsTableState,
  alertsTableState,
  clearSelection,
  useAlertsTableFiltersValue,
  useAlertsTableStateSnapshot,
} from "../state/alertsTable";

type AlertsApiResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SerializedAlert[];
  filter_boundaries: {
    min_date: string;
    max_date: string;
  };
};

const DEFAULT_PER_PAGE = 25;

const buildFilterQueryString = (filters: Partial<AlertsTableState["filters"]>): string => {
  let url = "";
  if (filters.object_id?.length && filters.object_id.length > 0) {
    const object_ids = filters.object_id.join(",");
    url += `filter{object_id.in}=${object_ids}&`;
  }
  if (filters.severity?.length && filters.severity.length > 0) {
    const severities = filters.severity.join(",");
    url += `filter{severity.in}=${severities}&`;
  }
  if (filters.created_at?.length && filters.created_at.length > 0) {
    let dates: string[] = [];
    if (filters.created_at.length >= 2) {
      const firstDate = DateTime.fromISO(filters.created_at[0]!.toISOString());
      const secondDate = DateTime.fromISO(filters.created_at[1]!.toISOString());
      dates = [firstDate.startOf("day").toJSDate().toISOString(), secondDate.endOf("day").toJSDate().toISOString()];
    } else if (filters.created_at.length === 1) {
      const date = DateTime.fromISO(filters.created_at[0]!.toISOString());
      dates = [date.startOf("day").toJSDate().toISOString(), date.endOf("day").toJSDate().toISOString()];
    }
    if (dates.length > 0) {
      url += `filter{created_at.range}=${dates.join(",")}&`;
    }
  }
  return url;
};

const getAlertsUrl = "/api/v1/alerts/get_alerts/?";
async function fetchAlerts<T = AlertsApiResponse>(alertsTableStateSnap?: AlertsTableState): Promise<T> {
  let urlEndpoint = getAlertsUrl;

  if (alertsTableStateSnap?.perPage) {
    urlEndpoint += `per_page=${alertsTableStateSnap.perPage}&`;
  } else {
    urlEndpoint += `per_page=${DEFAULT_PER_PAGE}&`;
  }

  if (alertsTableStateSnap?.page !== undefined) {
    urlEndpoint += `page=${alertsTableStateSnap.page + 1}&`;
  } else {
    urlEndpoint += "page=1&";
  }

  urlEndpoint += buildFilterQueryString(alertsTableStateSnap?.filters || {});

  urlEndpoint += "isApiUse=false&";

  return fetchWithSession(urlEndpoint);
}

const ALERTS_CACHE_KEY = (perPage: number = DEFAULT_PER_PAGE, page = 0, filters?: AlertsTableState["filters"]) => [
  "alerts",
  getGlobalVariables().currentUserID,
  perPage,
  page,
  filters,
];

function useAlertsQuery<TSelectedData = AlertsApiResponse>(
  options: Partial<UseQueryOptions<AlertsApiResponse, unknown, TSelectedData>> = {},
) {
  const alertsTableStateSnap = useAlertsTableStateSnapshot();

  return useQuery({
    queryKey: ALERTS_CACHE_KEY(
      alertsTableStateSnap.perPage || DEFAULT_PER_PAGE,
      alertsTableStateSnap.page || 0,
      alertsTableStateSnap.filters,
    ),
    queryFn: () => fetchAlerts({ ...alertsTableStateSnap, perPage: alertsTableStateSnap.perPage }),
    placeholderData: keepPreviousData,
    ...options,
  });
}

function useDeleteAlerts() {
  const queryClient = useQueryClient();
  const { addMessage } = useCreateToast();

  return useFetchMutation<void, Error, number[], unknown>(
    "/api/v1/alerts/batch_delete/",
    (data: number[]) => {
      // The request should include a JSON payload containing an array of IDs to delete, like this: {"ids": [1, 2, 3]}.
      return {
        ids: data,
      };
    },
    {
      onSuccess: async (_data, variables) => {
        addMessage({
          title: "Alert Deletion Successful",
          content: `${variables.length} ${variables.length === 1 ? "alert" : "alerts"} successfully deleted.`,
          variant: "success",
        });
      },
      onSettled: async () => {
        clearSelection();

        await queryClient.invalidateQueries({
          // will invalidate all possible alerts queries
          queryKey: ["alerts", getGlobalVariables().currentUserID],
        });
      },
    },
  );
}

function useAlertDetailsFeed(id: number) {
  const { currentUserID } = useGlobalVariables();

  return useInfiniteQuery({
    queryKey: ["alertDetails", currentUserID, id],
    queryFn: async ({ pageParam = 1, signal }) => {
      const url = `/api/v1/alerts/${id}/?details_page=${pageParam}`;
      return await fetchWithSession<SerializedAlert>(url, {
        signal: signal,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: SerializedAlert) => {
      // !REFACTOR: technical debt here. Our pagination is using single parameter for page in order to move through pages in any amount of alert details.
      // !REFACTOR: This is not the best approach and we should refactor it in the future. Alert details has to be loadable via API by their IDs and paged thru separately.

      // due to that issue, we have to take MAXimum total_page from all the details
      const { page, total_pages } = lastPage.details.reduce(
        (acc, curr) => {
          return {
            page: Math.max(acc.page, curr.page),
            total_pages: Math.max(acc.total_pages, curr.total_pages),
          };
        },
        { page: 1, total_pages: 1 },
      );

      // Ensure we have valid numbers before comparison
      if (typeof page === "number" && typeof total_pages === "number") {
        return page < total_pages ? page + 1 : undefined;
      }

      return undefined;
    },
    throwOnError: false,
    placeholderData: keepPreviousData,
  });
}

const UNSEEN_ALERTS_CACHE_KEY = () => ["alerts", getGlobalVariables().currentUserID, "unseen"];

function useUnseenAlertsCount() {
  const { data: unseenAlertsCount } = useQuery({
    queryKey: UNSEEN_ALERTS_CACHE_KEY(),
    queryFn: () => fetchWithSession(`${getAlertsUrl}isUnseenOnly=true&`),
    staleTime: 1 * 60 * 1000, // 1 minute
  });

  return unseenAlertsCount ?? 0;
}

function useAlertDetails(id: number, options?: UseQueryOptions<SerializedAlert>) {
  const { currentUserID } = useGlobalVariables();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, error, isSuccess } = useQuery<SerializedAlert>({
    queryKey: ["alerts", currentUserID, id],
    queryFn: () => fetchWithSession(`/api/v1/alerts/${id}/?details_page=0`),
    placeholderData: keepPreviousData,
    ...options,
  });

  const alertsTableStateSnap = useAlertsTableStateSnapshot();

  useEffect(() => {
    // when alert details are requested, its status is updated to seen, we have to refresh alerts
    if (data?.status === "new" && isSuccess) {
      // invalidate alerts on dashboard
      queryClient.invalidateQueries({
        queryKey: ALERTS_CACHE_KEY(
          5, // hardcoded perPage invalidation for insights dashboard, todo: fix this
          alertsTableStateSnap.page || 0,
          alertsTableStateSnap.filters,
        ),
      });

      // also invalidate alerts listing page
      queryClient.invalidateQueries({
        queryKey: ALERTS_CACHE_KEY(
          alertsTableStateSnap.perPage || DEFAULT_PER_PAGE,
          alertsTableStateSnap.page || 0,
          alertsTableStateSnap.filters,
        ),
      });

      // also refetch unseen alerts count
      queryClient.refetchQueries({
        queryKey: UNSEEN_ALERTS_CACHE_KEY(),
      });
    }
  }, [data, queryClient, alertsTableStateSnap, isSuccess]);

  return { data, isLoading, isFetching, error };
}

export { useAlertsQuery, useUnseenAlertsCount };
export type { AlertsApiResponse };
export { useDeleteAlerts, useAlertDetailsFeed, useAlertDetails, UNSEEN_ALERTS_CACHE_KEY };
