import React from "react";

import type { Table } from "@tanstack/react-table";

import PageSelector from "./TablePagination/PageSelector";
import PageSizeSelector from "./TablePagination/PageSizeSelector";
import { PaginationLink } from "./TablePagination/PaginationLink";

function TablePagination({
  table,
  showSizeSelector = true,
}: {
  table: Table<any>;
  showSizeSelector?: boolean;
}) {
  return (
    <nav className="flex items-center justify-between py-2 px-2">
      {showSizeSelector ? <PageSizeSelector table={table} /> : <div className="flex-1" />}

      <ul className="inline-flex items-center gap-3">
        <li>
          <PaginationLink table={table} isPrev={true} />
        </li>
        <li>
          <PageSelector table={table} />
        </li>
        <li>
          <PaginationLink table={table} isPrev={false} />
        </li>
      </ul>
    </nav>
  );
}

export default TablePagination;
