import type { Feed } from "@configs/feeds";
import cisaIcon from "./cisa.svg";
import defenderIcon from "./defender.svg";
import epssIcon from "./epss.svg";
import exploitdbIcon from "./exploitdb.svg";
import greynoiseIcon from "./greynoise.svg";
import intel471Icon from "./intel471.svg";
import mandiantIcon from "./mandiant.png";
import metasploitIcon from "./metasploit.png";
import nucleiIcon from "./nuclei.svg";
import nvdIcon from "./nvd.svg";
import projectIcon from "./project.png";
import rfiIcon from "./rf.svg";
import securinIcon from "./securin.svg";
import shadowserverIcon from "./shadowserver.png";
import shodanIcon from "./shodan.png";
import vIntelIcon from "./v_intel.webp";
import vipIcon from "./vip.webp";
import vulncheckIcon from "./vulncheck.svg";
import zdiIcon from "./zdi.svg";

export const icons: Record<Feed, string> = {
  cisa: cisaIcon,
  defender: defenderIcon,
  epss: epssIcon,
  exploitdb: exploitdbIcon,
  greynoise: greynoiseIcon,
  intel471: intel471Icon,
  mandiant: mandiantIcon,
  metasploit: metasploitIcon,
  nuclei: nucleiIcon,
  nucleus: vipIcon,
  nvd: nvdIcon,
  project_zero: projectIcon,
  rf: rfiIcon,
  securin: securinIcon,
  shadowserver: shadowserverIcon,
  shodan: shodanIcon,
  vIntel: vIntelIcon,
  vip: vipIcon,
  zdi: zdiIcon,
  vulncheck: vulncheckIcon,
} as const;

// Custom size requirements for some feeds
export const iconMetadata = {
  mandiant: { width: 16, height: 16 },
  nvd: { width: 28, height: 12 },
  epss: { width: 36, height: 10 },
} as const;

