import type { ThreatKey } from "@interfaces/SerializedThreat";

import type { SuggestionProps } from "../Suggestion";
import { FIELD_TYPE } from "../utils";

type OperatorKey =
  | "~"
  | "within"
  | "within the next"
  | "more than"
  | "is empty"
  | "is not empty"
  | "="
  | ">"
  | ">="
  | "<"
  | "<="
  | "!~"
  | "!=";

// Etalonic suggestions init props for available operators.
const DEFAULT_SUGGESTIONS_PROPS: Record<OperatorKey, Omit<SuggestionProps, "textarea">> = {
  "~": {
    text: "~",
    snippetAfter: ' "|"',
    suggestionText: "contains",
  },
  within: {
    text: "within",
    snippetAfter: ' "|"',
    suggestionText: "dynamic",
  },
  "within the next": {
    text: "within the next",
    snippetAfter: ' "|"',
    suggestionText: "dynamic",
  },
  "more than": {
    text: "more than",
    snippetAfter: ' "|"',
    suggestionText: "dynamic",
  },
  "is empty": { text: "is empty", snippetAfter: " " },
  "is not empty": { text: "is not empty", snippetAfter: " " },
  "=": { text: "=", snippetAfter: " ", suggestionText: "is equal to" },
  ">": {
    text: ">",
    snippetAfter: " ",
    suggestionText: "is greater than",
  },
  ">=": {
    text: ">=",
    snippetAfter: " ",
    suggestionText: "is greater than or equal to",
  },
  "<": {
    text: "<",
    snippetAfter: " ",
    suggestionText: "is less than",
  },
  "<=": {
    text: "<=",
    snippetAfter: " ",
    suggestionText: "is less than or equal to",
  },
  "!~": {
    text: "!~",
    snippetAfter: ' "|"',
    suggestionText: "does not contain",
  },
  "!=": {
    text: "!=",
    snippetAfter: " ",
    suggestionText: "is not equal to",
  },
};


// Default suggestions for all fields except custom types.
const EMPTY_NONEMPTY_SUGGESTIONS_CONFIG = [
  DEFAULT_SUGGESTIONS_PROPS["is empty"],
  DEFAULT_SUGGESTIONS_PROPS["is not empty"],
];

// Configuration for each FIELD_TYPE, mapping to predefined Suggestion instances.
const SUGGESTIONS_CONFIG: Partial<Record<FIELD_TYPE, Omit<SuggestionProps, "textarea">[]>> = {
  [FIELD_TYPE.COMPUTED]: [DEFAULT_SUGGESTIONS_PROPS["="], DEFAULT_SUGGESTIONS_PROPS["!="]],
  [FIELD_TYPE.MONITORED]: [DEFAULT_SUGGESTIONS_PROPS["="]],
  [FIELD_TYPE.TAGS]: [
    DEFAULT_SUGGESTIONS_PROPS["~"],
    DEFAULT_SUGGESTIONS_PROPS["!~"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.FAVORITE]: [DEFAULT_SUGGESTIONS_PROPS["="]],
  [FIELD_TYPE.NOTES]: [
    DEFAULT_SUGGESTIONS_PROPS["~"],
    { text: "author is", snippetAfter: ' "|"' },
    { text: "author is not", snippetAfter: ' "|"' },
    { text: "created before", snippetAfter: ' "|"' },
    { text: "created after", snippetAfter: ' "|"' },
    { text: "created within", snippetAfter: ' "|"' },
    { text: "created more than", snippetAfter: ' "|"' },
    { text: "updated before", snippetAfter: ' "|"' },
    { text: "updated after", snippetAfter: ' "|"' },
    { text: "updated within", snippetAfter: ' "|"' },
    { text: "updated more than", snippetAfter: ' "|"' },
    { text: "is private", snippetAfter: " " },
    { text: "is global", snippetAfter: " " },
  ],
  [FIELD_TYPE.JSON]: [
    DEFAULT_SUGGESTIONS_PROPS["~"],
    DEFAULT_SUGGESTIONS_PROPS["!~"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.DATETIME]: [
    {
      ...DEFAULT_SUGGESTIONS_PROPS[">"],
      suggestionText: "is after",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS[">="],
      suggestionText: "is after or equal to",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS["<"],
      suggestionText: "is before",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS["<="],
      suggestionText: "is before or equal to",
    },
    DEFAULT_SUGGESTIONS_PROPS.within,
    DEFAULT_SUGGESTIONS_PROPS["within the next"],
    DEFAULT_SUGGESTIONS_PROPS["more than"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.DATE]: [
    DEFAULT_SUGGESTIONS_PROPS["="],
    DEFAULT_SUGGESTIONS_PROPS["!="],
    {
      ...DEFAULT_SUGGESTIONS_PROPS[">"],
      suggestionText: "is after",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS[">="],
      suggestionText: "is after or equal to",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS["<"],
      suggestionText: "is before",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS["<="],
      suggestionText: "is before or equal to",
    },
    DEFAULT_SUGGESTIONS_PROPS.within,
    DEFAULT_SUGGESTIONS_PROPS["within the next"],
    DEFAULT_SUGGESTIONS_PROPS["more than"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.STRING]: [
    DEFAULT_SUGGESTIONS_PROPS["="],
    DEFAULT_SUGGESTIONS_PROPS["!="],
    DEFAULT_SUGGESTIONS_PROPS["~"],
    DEFAULT_SUGGESTIONS_PROPS["!~"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.BOOLEAN]: [
    DEFAULT_SUGGESTIONS_PROPS["="],
    DEFAULT_SUGGESTIONS_PROPS["!="],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.INTEGER]: [
    DEFAULT_SUGGESTIONS_PROPS["="],
    DEFAULT_SUGGESTIONS_PROPS["!="],
    DEFAULT_SUGGESTIONS_PROPS[">"],
    DEFAULT_SUGGESTIONS_PROPS[">="],
    DEFAULT_SUGGESTIONS_PROPS["<"],
    DEFAULT_SUGGESTIONS_PROPS["<="],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.FLOAT]: [
    DEFAULT_SUGGESTIONS_PROPS["="],
    DEFAULT_SUGGESTIONS_PROPS["!="],
    DEFAULT_SUGGESTIONS_PROPS[">"],
    DEFAULT_SUGGESTIONS_PROPS[">="],
    DEFAULT_SUGGESTIONS_PROPS["<"],
    DEFAULT_SUGGESTIONS_PROPS["<="],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.INTELS]: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  [FIELD_TYPE.RISK_LEVEL]: [
    DEFAULT_SUGGESTIONS_PROPS["="],
    DEFAULT_SUGGESTIONS_PROPS["!="],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  [FIELD_TYPE.CVE_STATUS]: [DEFAULT_SUGGESTIONS_PROPS["="]],
};

const STRINGS_WITH_OPTIONS_SUGGESTIONS = [
  {
    text: "~",
    snippetAfter: ' "|"',
    suggestionText: "is equal to",
  },
  {
    text: "!~",
    snippetAfter: ' "|"',
    suggestionText: "is not equal to",
  },
  DEFAULT_SUGGESTIONS_PROPS[">"],
  DEFAULT_SUGGESTIONS_PROPS[">="],
  DEFAULT_SUGGESTIONS_PROPS["<"],
  DEFAULT_SUGGESTIONS_PROPS["<="],
  ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
];

const PER_FIELD_SUGGESTIONS_CONFIG: Partial<Record<ThreatKey, Omit<SuggestionProps, "textarea">[]>> = {
  vip_id: [
    {
      text: "=",
      snippetAfter: ' "|"',
      suggestionText: "is equal to",
    },
    DEFAULT_SUGGESTIONS_PROPS["~"],
    DEFAULT_SUGGESTIONS_PROPS["!~"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  cve_id: [
    {
      text: "=",
      snippetAfter: ' "|"',
      suggestionText: "is equal to",
    },
    DEFAULT_SUGGESTIONS_PROPS["~"],
    DEFAULT_SUGGESTIONS_PROPS["!~"],
    ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG,
  ],
  intel471_risk_level: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  rf_criticality: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv2_severity: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv2_base_severity: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv3_base_severity: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv4_base_severity: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv3_availability_impact: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv3_confidentiality_impact: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nvd_cvssv3_integrity_impact: [...STRINGS_WITH_OPTIONS_SUGGESTIONS],
  nuclei_name: [DEFAULT_SUGGESTIONS_PROPS["~"], ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG],
  nuclei_description: [DEFAULT_SUGGESTIONS_PROPS["~"], ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG],
  nuclei_template_exists: [DEFAULT_SUGGESTIONS_PROPS["="], DEFAULT_SUGGESTIONS_PROPS["!="]],
  nuclei_template_url: [DEFAULT_SUGGESTIONS_PROPS["~"], ...EMPTY_NONEMPTY_SUGGESTIONS_CONFIG],
  nuclei_last_updated_by_nucleus: [
    {
      ...DEFAULT_SUGGESTIONS_PROPS[">"],
      suggestionText: "is after",
    },
    {
      ...DEFAULT_SUGGESTIONS_PROPS["<"],
      suggestionText: "is before",
    },
  ],

  // VIP-524: cisa_action, cisa_product, cisa_short_description, cisa_vendor, cisa_vulnerability_name only have ~ and !~ operators
  cisa_action: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  cisa_product: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  cisa_short_description: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  cisa_vendor: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  cisa_vulnerability_name: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],

  // VIP-524: cisa_date_added_to_catalog,  cisa_due_date, cisa_last_updated_by_nucleus Just show operators: >=, <=, within, more than
  cisa_date_added_to_catalog: [
    DEFAULT_SUGGESTIONS_PROPS[">="],
    DEFAULT_SUGGESTIONS_PROPS["<="],
    DEFAULT_SUGGESTIONS_PROPS.within,
    DEFAULT_SUGGESTIONS_PROPS["within the next"],
    DEFAULT_SUGGESTIONS_PROPS["more than"],
  ],
  cisa_due_date: [
    DEFAULT_SUGGESTIONS_PROPS[">="],
    DEFAULT_SUGGESTIONS_PROPS["<="],
    DEFAULT_SUGGESTIONS_PROPS.within,
    DEFAULT_SUGGESTIONS_PROPS["within the next"],
    DEFAULT_SUGGESTIONS_PROPS["more than"],
  ],
  cisa_last_updated_by_nucleus: [
    DEFAULT_SUGGESTIONS_PROPS[">="],
    DEFAULT_SUGGESTIONS_PROPS["<="],
    DEFAULT_SUGGESTIONS_PROPS.within,
    DEFAULT_SUGGESTIONS_PROPS["within the next"],
    DEFAULT_SUGGESTIONS_PROPS["more than"],
  ],
  cisa_kev_exists: [DEFAULT_SUGGESTIONS_PROPS["="], DEFAULT_SUGGESTIONS_PROPS["!="]],

  // VIP-526 Change shodan search operators
  shodan_hosts: [DEFAULT_SUGGESTIONS_PROPS[">"], DEFAULT_SUGGESTIONS_PROPS["<"]],

  // VIP-528 Simplify mandiant searching
  mandiant_analysis: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_exec_summary: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_exploits: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_malware: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_mitigation_details: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_sources: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_summary: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_vulnerable_products: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],

  mandiant_attacking_ease: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_affects_ot: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_exploit_in_the_wild: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_exploit_rating: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_exploitation_consequence: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_exploited_by_malware: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_status: [DEFAULT_SUGGESTIONS_PROPS["="]],
  mandiant_zero_day: [DEFAULT_SUGGESTIONS_PROPS["="]],

  mandiant_technologies: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_title: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],
  mandiant_vendor_fix_urls: [DEFAULT_SUGGESTIONS_PROPS["~"], DEFAULT_SUGGESTIONS_PROPS["!~"]],

  mandiant_mitigations: [DEFAULT_SUGGESTIONS_PROPS["~"]],
  mandiant_exploitation_vectors: [DEFAULT_SUGGESTIONS_PROPS["~"]],

  mandiant_days_to_patch: [
    DEFAULT_SUGGESTIONS_PROPS[">"],
    DEFAULT_SUGGESTIONS_PROPS["<"],
    DEFAULT_SUGGESTIONS_PROPS["="],
  ],
  mandiant_risk_rating: [
    DEFAULT_SUGGESTIONS_PROPS[">"],
    DEFAULT_SUGGESTIONS_PROPS["<"],
    DEFAULT_SUGGESTIONS_PROPS["="],
  ],

  // VIP-529 Update EPSS search
  epss_last_updated_by_nucleus: [
    DEFAULT_SUGGESTIONS_PROPS[">="],
    DEFAULT_SUGGESTIONS_PROPS["<="],
    DEFAULT_SUGGESTIONS_PROPS.within,
    DEFAULT_SUGGESTIONS_PROPS["within the next"],
    DEFAULT_SUGGESTIONS_PROPS["more than"],
  ],
  epss_percentile: [DEFAULT_SUGGESTIONS_PROPS[">"], DEFAULT_SUGGESTIONS_PROPS["<"], DEFAULT_SUGGESTIONS_PROPS["="]],
  epss_score: [DEFAULT_SUGGESTIONS_PROPS[">"], DEFAULT_SUGGESTIONS_PROPS["<"], DEFAULT_SUGGESTIONS_PROPS["="]],
};

export { PER_FIELD_SUGGESTIONS_CONFIG, SUGGESTIONS_CONFIG };
