import classNames from "classnames";
import React from "react";

import Tooltip from "@components/Tooltip";
import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import { type HeaderGroup, flexRender } from "@tanstack/react-table";

const TooltipFieldNames = {
  quick_bookmark_status: "Favorites",
};

function HeaderGroupRenderer({
  headerGroup,
  deltaOffset,
}: Readonly<{
  headerGroup: HeaderGroup<FrontendSerializedThreat | SerializedMandiantMalware>;
  deltaOffset: number;
}>) {
  const handleColumnSortingClick = (header: any) => {
    if (!header.column.getCanSort()) {
      return;
    }

    const currentSort = header.column.getIsSorted();
    const _newStatus = currentSort === "asc" ? "desc" : false;
    const newStatus = currentSort === false ? "asc" : _newStatus;

    if (newStatus === false) {
      header.column.clearSorting();
    } else {
      header.column.toggleSorting(newStatus === "desc");
    }
  };

  return (
    <tr key={headerGroup.id} className="text-xs text-gray-600 uppercase">
      {headerGroup.headers.map((header, index) => {
        const size = header.getSize();
        return (
          <Tooltip
            key={header.id + index}
            value={
              header.column.getCanSort()
                ? `Click to sort (${header.id})`
                : `(${TooltipFieldNames[header.id as keyof typeof TooltipFieldNames] ?? header.id})`
            }
          >
            <th
              scope="col"
              className={`bg-gray-200 sticky top-0 px-2 py-2 ${header.id}`}
              colSpan={header.colSpan}
              style={{
                width: size,
                minWidth: size,
                maxWidth: size,
              }}
            >
              <div
                className={classNames("flex flex-row items-center", {
                  "cursor-pointer select-none": header.column.getCanSort(),
                })}
                onClick={() => handleColumnSortingClick(header)}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {{
                  asc: <i className="ml-1 fas fa-sort-up" />,
                  desc: <i className="ml-1 fas fa-sort-down" />,
                }[header.column.getIsSorted() as string] ?? <></>}
              </div>
              {header.column.getCanResize() && (
                <div
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={classNames(
                    "absolute right-0 top-0 h-full w-1 bg-blue-200 cursor-col-resize select-none touch-none hover:bg-blue-600",
                    {
                      "bg-blue-700 opacity-1": header.column.getIsResizing(),
                    },
                  )}
                  style={{
                    transform: header.column.getIsResizing() ? `translateX(${deltaOffset}px)` : "",
                  }}
                />
              )}
            </th>
          </Tooltip>
        );
      })}
    </tr>
  );
}

export default HeaderGroupRenderer;
