import React from "react";

import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { Table } from "@tanstack/react-table";

import HeaderGroupRenderer from "./TableHeader/HeaderGroupRenderer";

function TableHeader({
  table,
}: {
  table: Table<FrontendSerializedThreat | SerializedMandiantMalware>;
}) {
  return (
    <thead
      style={{
        width: "calc( 100% - 1em )",
      }}
    >
      {table.getHeaderGroups().map((headerGroup) => {
        return (
          <HeaderGroupRenderer
            key={headerGroup.id}
            headerGroup={headerGroup}
            deltaOffset={table.getState().columnSizingInfo.deltaOffset ?? 0}
          />
        );
      })}
    </thead>
  );
}

export default TableHeader;
