class ErrorDjangoQL extends Error {
  url: string;
  serverResponse: string;

  constructor(message: string, url: string, serverResponse: string) {
    super(message);

    this.name = "ErrorDjangoQL";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorDjangoQL);
    }

    this.url = url;
    this.serverResponse = serverResponse;

    // Make properties non-enumerable to ensure they show up in Sentry
    Object.defineProperty(this, "url", {
      enumerable: true,
      writable: true,
      value: url,
    });

    Object.defineProperty(this, "serverResponse", {
      enumerable: true,
      writable: true,
      value: serverResponse,
    });
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      url: this.url,
      serverResponse: this.serverResponse,
      stack: this.stack,
    };
  }
}

export { ErrorDjangoQL };