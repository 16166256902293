import { useIsPDFExport } from "@features/threatDetails/threatDetails.state";
import { Chip } from "@material-tailwind/react/components/Chip";
import { Tooltip } from "@material-tailwind/react/components/Tooltip";

function Tag({
  onClose,
  isLoading,
  tagValue,
  isGlobal,
  size = "sm",
}: {
  onClose?: () => void;
  isLoading: boolean;
  tagValue: string;
  isGlobal: boolean;
  size?: "sm" | "md" | "lg";
}) {
  const isPDFExport = useIsPDFExport();

  return (
    <Chip
      size={size}
      color="blue"
      variant={"ghost"}
      key={tagValue}
      className="text-gray-700"
      value={
        <span className="flex flex-row gap-1">
          {tagValue}
          {isGlobal ? (
            <Tooltip content="Tag provided by Nucleus">
              <div className="ml-0.5">
                <img src="/static/img/logos/logo.webp" alt="Nucleus logo" className="w-4 h-4" width={20} height={20} />
              </div>
            </Tooltip>
          ) : null}
        </span>
      }
      onClose={isPDFExport ? undefined : onClose}
      icon={isLoading ? <span className="animate-pulse">...</span> : undefined}
    />
  );
}

export { Tag };
