import cx from "classnames";
import type React from "react";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";

const Tooltip = ({
  children,
  value,
  container,
}: {
  children: React.ReactNode;
  value: React.ReactNode;
  container?: HTMLDivElement | null;
}) => {
  return (
    <TooltipPrimitive.Provider delayDuration={100}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal container={container ?? undefined}>
          <TooltipPrimitive.Content
            sideOffset={10}
            className={cx(
              "z-50 border border-gray-300 bg-white text-gray-900 rounded-md shadow-lg",
              "inline-flex items-center rounded-md px-1 py-2.5 z-50",
              "bg-white translate-y-radix-toast-swipe-move-y",
              "will-change-transform ",
              "radix-side-top:animate-slide-down-fade",
              "radix-side-right:animate-slide-left-fade",
              "radix-side-bottom:animate-slide-up-fade",
              "radix-side-left:animate-slide-right-fade",
            )}
          >
            <TooltipPrimitive.Arrow className="text-white fill-current" width={10} height={5} />
            <div className="max-h-96 scrollbar-thumb-gray-600 scrollbar-track-gray-100 scrollbar-thin block max-w-xs px-3 overflow-y-auto text-xs text-gray-700 break-words">
              {value}
            </div>
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
