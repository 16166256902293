import type React from "react";

function RadialProgress({
  percent,
  children,
  color = "text-nucleus",
  size = "w-6 h-6",
  textSize = "text-[8px]",
}: {
  percent: number;
  children?: React.ReactNode;
  color?: string;
  size?: string;
  textSize?: string;
}) {
  const circumference = 2 * Math.PI * 13;
  const offset = circumference - (percent / 100) * circumference;

  return (
    <div className={`relative inline-flex items-center justify-center ${size}`}>
      <svg viewBox="0 0 32 32" fill="none" strokeWidth="5" className={size}>
        <circle
          cx="16"
          cy="16"
          r="13"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset="0"
          transform="rotate(-90 16 16)"
          strokeLinecap="round"
          className="text-gray-300 stroke-current"
        />
        <circle
          cx="16"
          cy="16"
          r="13"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={`${offset}`}
          transform="rotate(-90 16 16)"
          strokeLinecap="round"
          className={`stroke-current transition-all !duration-500 ${color}`}
        />
      </svg>
      <div className={`absolute ${color} ${textSize}`}>{children}</div>
    </div>
  );
}

export { RadialProgress };
