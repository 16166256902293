import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const SingleUrlRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = context.getValue();

  if (!value) {
    return <div />;
  }

  const render = (url: string) => (
    <a
      key={`mandiant_fix_urls_${url}`}
      className="flex-start ease-nav-brand text-slate-600 hover:text-blue-700 flex font-semibold transition-all"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <span>
        {url}
        <i className="fas fa-external-link-alt inline ml-1 text-xs" />
      </span>
    </a>
  );

  return <div>{render(value)}</div>;
};

export default SingleUrlRenderer;
