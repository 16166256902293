declare const currentUserID: number; // declared in main template html file and passed from django

import type React from "react";
import { Suspense } from "react";

import ErrorUnauthorized from "@errors/ErrorUnauthorized";
import { ToastProvider } from "@hooks/useCreateToast";
import { ThemeProvider } from "@material-tailwind/react/context/theme";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { nucleusTheme } from "./nucleusTheme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 2, // 2 minutes
    },
  },
  queryCache: new QueryCache({
    onError(error: Error) {
      if (error instanceof ErrorUnauthorized) {
        window.location.href = `/login/?next=${window.location.pathname}`;
      }
    },
  }),
});

const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
  key: `_session_${currentUserID}`,
});

type ReactNodeLike = React.ReactNode;
const App = ({ children }: { children: React.ReactNode }): NonNullable<ReactNodeLike> => {
  return (
    <div>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: sessionStoragePersister }}>
        <Suspense fallback={<div />}>
          <ToastProvider>{children}</ToastProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="bottom-left" />
        </Suspense>
      </PersistQueryClientProvider>
    </div>
  );
};


// Wrappers which are used in all pages
const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider value={nucleusTheme}>
      <App>{children}</App>
    </ThemeProvider>
  );
};

export default Providers;
