import { proxy, useSnapshot } from "valtio";

interface GlobalPageState {
  isQuickStartOpen: boolean;
}

const globalPageStateProxy = proxy<GlobalPageState>({
  isQuickStartOpen: false,
});

const useGlobalPageState = () => {
  return useSnapshot(globalPageStateProxy);
};

const updateGlobalPageState = (newState: Partial<GlobalPageState>) => {
  Object.assign(globalPageStateProxy, newState);
};

export { globalPageStateProxy, useGlobalPageState, updateGlobalPageState };
