import type { FieldReferenceState } from "@features/field_references/fieldReferences.state";
import { LEXER_TOKENS, TOKEN_TYPE } from "@utils/Lexer";
import AsyncMenuSuggestion from "../AsyncMenuSuggestion";
import DatePickerSuggestion from "../DatePickerSuggestion";
import type QLContext from "../QLContext";
import { CONTEXT_SCOPE } from "../QLContext";
import { Suggestion } from "../Suggestion";
import FieldModel from "./FieldModel";

class NotesFieldModel extends FieldModel {
  isAdmin: boolean;

  constructor(fieldReference: FieldReferenceState, isAdmin: boolean, textarea: HTMLTextAreaElement) {
    super("vip_notes", fieldReference, textarea);

    this.isAdmin = isAdmin;
  }

  getFieldOptions(context: QLContext): Suggestion[] {
    const { lastToken } = context;
    if (lastToken?.name === LEXER_TOKENS.AUTHOR_IS || lastToken?.name === LEXER_TOKENS.AUTHOR_IS_NOT) {
      return [
        new AsyncMenuSuggestion({
          context,
          url: "/api/v1/autocomplete/authors/",
          textarea: this.textarea,
        }),
      ];
    }
    if (lastToken?.name === LEXER_TOKENS.ORGANIZATION_IS || lastToken?.name === LEXER_TOKENS.ORGANIZATION_IS_NOT) {
      return [
        new AsyncMenuSuggestion({
          context,
          url: "/api/v1/autocomplete/organizations/",
          textarea: this.textarea,
        }),
      ];
    }

    return [];
  }

  getOperatorSuggestionsConfigs() {
    const suggestionsConfigs = super.getOperatorSuggestionsConfigs();

    if (this.isAdmin) {
      const ADMIN_SUGGESTIONS = [
        new Suggestion({
          text: "organization is",
          snippetAfter: ' "|"',
          textarea: this.textarea,
        }),
        new Suggestion({
          text: "organization is not",
          snippetAfter: ' "|"',
          textarea: this.textarea,
        }),
      ];

      return [
        ...suggestionsConfigs.map(
          (conf) =>
            new Suggestion({
              ...conf,
              textarea: this.textarea,
            }),
        ),
        ...ADMIN_SUGGESTIONS,
      ];
    }

    return suggestionsConfigs.map(
      (conf) =>
        new Suggestion({
          ...conf,
          textarea: this.textarea,
        }),
    );
  }

  hasOptions(context: QLContext) {
    const { lastToken } = context;

    if (
      lastToken?.name === LEXER_TOKENS.AUTHOR_IS ||
      lastToken?.name === LEXER_TOKENS.AUTHOR_IS_NOT ||
      lastToken?.name === LEXER_TOKENS.ORGANIZATION_IS ||
      lastToken?.name === LEXER_TOKENS.ORGANIZATION_IS_NOT
    ) {
      return true;
    }

    return false;
  }

  getSuggestions(context: QLContext): Suggestion[] {
    if (context.scope === CONTEXT_SCOPE.VALUE) {
      if (this.hasOptions(context)) {
        return this.getFieldOptions(context);
      }

      // Notes field has various values based on the context of operator
      const { lastToken } = context;
      if (
        lastToken?.type === TOKEN_TYPE.OPERATOR &&
        (lastToken?.name === LEXER_TOKENS.CREATED_AT || lastToken?.name === LEXER_TOKENS.UPDATED_AT) &&
        lastToken?.value
      ) {
        // created at variants with many variants
        switch (lastToken.value) {
          case "updated after":
          case "updated before":
          case "created after":
          case "created before": {
            // showing date picker
            return [
              new DatePickerSuggestion({
                snippetAfter: ' "|"',
                type: "date",
                textarea: this.textarea,
              }),
            ];
          }

          case "updated within":
          case "updated more than":
          case "created within":
          case "created more than": {
            // using dynamic datestring
            return DatePickerSuggestion.generateDatestringSuggestions(context, this.textarea);
          }
        }
      }
    }

    return super.getSuggestions(context);
  }
}

export default NotesFieldModel;
