import { RecordsType } from "@features/listing/enums";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import useIntrospections from "@queries/useIntrospections";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useFieldReferences } from "./fieldReferences.query";
import { type FieldReferencesFieldsState, updateFieldReferencesState } from "./fieldReferences.state";

const COMBINED_FIELD_REFERENCES_KEY = "combinedFieldReferences";

function useFieldReferencesStateSync() {
  const queryClient = useQueryClient();

  const fieldReferencesQuery = useFieldReferences({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  const { data: fieldReferences } = fieldReferencesQuery;

  const introspectionsQuery = useIntrospections(RecordsType.CVEs);
  const { data: introspections } = introspectionsQuery;

  const queryFn = useCallback(() => {
    if (!fieldReferences || !introspections) {
      return null;
    }

    const fieldReferencesIntrospections = introspections.fields;

    const combinedData = Object.entries(fieldReferencesIntrospections).reduce<FieldReferencesFieldsState>(
      (acc, [key, fieldIntrospection]) => {
        const fieldReference = fieldReferences.find((ref) => ref.field === key);

        const { options, ...constrains } = fieldIntrospection.constrains;

        acc[key as ThreatKey] = {
          ...constrains,
          id: key as ThreatKey,
          type: fieldIntrospection.type,
          nullable: fieldIntrospection.nullable,
          description: fieldReference?.description ?? "",
          possible_values: fieldReference?.possible_values?.length ? fieldReference.possible_values : options,
        };

        return acc;
      },
      {},
    );

    return combinedData;
  }, [fieldReferences, introspections]);

  const { data } = useQuery({
    queryKey: [COMBINED_FIELD_REFERENCES_KEY],
    queryFn,
    enabled: fieldReferencesQuery.isSuccess && introspectionsQuery.isSuccess,
    staleTime: 10 * 60 * 1000, // 10 minutes
    gcTime: Number.POSITIVE_INFINITY,
  });

  // Prefetch the data if it's not already in the cache
  useEffect(() => {
    if (
      !queryClient.getQueryData([COMBINED_FIELD_REFERENCES_KEY]) &&
      fieldReferencesQuery.isSuccess &&
      introspectionsQuery.isSuccess
    ) {
      queryClient.prefetchQuery({ queryKey: [COMBINED_FIELD_REFERENCES_KEY] });
    }
  }, [queryClient, fieldReferencesQuery.isSuccess, introspectionsQuery.isSuccess]);

  useEffect(() => {
    if (!data) {
      return;
    }

    updateFieldReferencesState(data);
  }, [data]);
}

function useCombinedFieldReferences() {
  const queryClient = useQueryClient();

  return queryClient.getQueryData<FieldReferencesFieldsState>([COMBINED_FIELD_REFERENCES_KEY]);
}

export { useFieldReferencesStateSync, useCombinedFieldReferences };
