import type { FieldReferencesFieldsState } from "@features/field_references/fieldReferences.state";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { HistorySuggestion } from "../HistorySuggestion";
import type QLContext from "../QLContext";
import type { Suggestion } from "../Suggestion";
import { FIELD_TYPE } from "../utils";
import FieldModel from "./FieldModel";

class FunctionFieldModel extends FieldModel {
  fieldReferences: FieldReferencesFieldsState;
  constructor(name: string, value: string, textarea: HTMLTextAreaElement, fieldReferences: FieldReferencesFieldsState) {
    const id = name.toLowerCase() as ThreatKey;
    const fieldReference = {
      id,
      name,
      value,
      searchable: true,
      type: FIELD_TYPE.FUNCTION,
    };

    super(id, fieldReference, textarea);

    this.fieldReferences = fieldReferences;
  }

  getSuggestions(_context: QLContext): Suggestion[] {
    return [
      new HistorySuggestion({
        text: "history",
        snippetAfter: " ",
        textarea: this.textarea,
        fieldReferences: this.fieldReferences,
      }),
    ];
  }
}

export default FunctionFieldModel;
