class ErrorUnauthorized extends Error {
  constructor(message: string) {
    super(message);

    this.name = "ErrorUnauthorized";
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorUnauthorized);
    }
  }
}

export default ErrorUnauthorized;
