import { useContext } from "react";
import { useSnapshot } from "valtio";

import { ListingTableContext } from "@components/ListingTable";
import type { ListingTableState } from "@features/listing/ListingTableState";

/**
 * Returns the state of the closest listing table from its context.
 * @need_suspence
 */
function useClosestListingTableState() {
  // Writeable state
  const state = useContext(ListingTableContext);

  // Read-only state
  const snap = useSnapshot<ListingTableState>(state);

  return {
    listingTableState: state,
    listingTableSnap: snap as Readonly<ListingTableState>,
  };
}

function useClosestListingTableStateValue() {
  const { listingTableSnap } = useClosestListingTableState();
  return listingTableSnap;
}

function useClosestListingTableStateSetter() {
  const listingTableState = useContext(ListingTableContext);
  return listingTableState;
}

function useClosestListingTableMode() {
  const { recordsType } = useClosestListingTableStateValue();
  return recordsType;
}

export {
  useClosestListingTableState,
  useClosestListingTableMode,
  useClosestListingTableStateValue,
  useClosestListingTableStateSetter,
};
