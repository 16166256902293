import { QuickStartDrawer } from "./Portals/QuickStartDrawer";

function Portals() {
  return (
    <>
      <QuickStartDrawer />
    </>
  );
}

export default Portals;
