import React from "react";

import type { FrontendSerializedThreat, MandiantCpeRange } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_RECORDS_TO = 3;
const MandiantCpeRangesRenderer: (context: CellContext<FrontendSerializedThreat, MandiantCpeRange[]>) => JSX.Element = (
  context,
) => {
  const value: MandiantCpeRange[] = context.getValue();

  if (!value || !Array.isArray(value)) {
    return <>-</>;
  }

  const renderRecord = (record: MandiantCpeRange, index) => (
    //   add comma after each record except the last one
    <span key={`mandiant_cpe_range_${index}`}>
      {record.start_cpe?.product ?? record.end_cpe?.product}
      {index !== value.length - 1 ? <span>, </span> : null}
    </span>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map(renderRecord)}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export default MandiantCpeRangesRenderer;
