import React from "react";

import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { CellContext } from "@tanstack/react-table";

/**
 * interface SerializedMandiantMalwareActor {
  country_name: string;
  id: string;
  iso2: string;
  last_updated: string;
  name: string;
}
 */
const MalwareActors: (
  context: CellContext<SerializedMandiantMalware, SerializedMandiantMalware["actors"]>,
) => JSX.Element = (context) => {
  const actors = context.getValue();

  if (!Array.isArray(actors)) {
    return <>-</>;
  }

  return (
    <div className="text-gray-600">
      {actors.map((actor, index) => (
        <span key={`actor_${index}`}>
          {actor.name}
          {index < actors.length - 1 ? ", " : ""}
        </span>
      ))}
    </div>
  );
};

export default MalwareActors;
