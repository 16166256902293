import React from "react";

import type { Table } from "@tanstack/react-table";

function PageSizeSelector({ table }: { table: Table<any> }) {
  const handlePageSizeOptionSelect = (e: any) => {
    e.preventDefault();

    table.setPageSize(Number(e.target.value));
  };

  const options = [25, 50, 100, 250];
  return (
    <div className="sm:flex items-center hidden text-xs">
      <span className="font-normal text-gray-500">Show</span>
      <select
        className="px-2 py-1 ml-1 border border-gray-200 rounded"
        onChange={handlePageSizeOptionSelect}
        defaultValue={table.getState().pagination.pageSize}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <span className="ml-1 font-normal text-gray-500">entries</span>
    </div>
  );
}

export default PageSizeSelector;
