import { type W, w } from "windstitch";

const Badge = w.div("inline-flex items-center justify-center rounded-sm font-semibold min-w-[80px]", {
  variants: {
    color: {
      error: "bg-red-200 text-red-800",
      warning: "bg-orange-100 text-yellow-900",
      notice: "bg-yellow-200 text-yellow-800",
      info: "bg-blue-200 text-blue-900",
      success: "bg-green-100 text-green-900",
      primary: "bg-gray-300 text-gray-700",
      default: "bg-gray-200 text-gray-600",
    },
    size: {
      small: "text-xs px-2 py-0.5",
      medium: "text-xs px-3 py-1",
      large: "text-sm px-4 py-1.5",
    },
    modifier: {
      none: "",
      outline: "border border-solid border-gray-400",
    },
  },
  defaultVariants: {
    color: "default",
    size: "medium",
    modifier: "none",
  },
  compoundVariants: [],
  defaultProps: {},
});

Badge.displayName = "Badge";

export default Badge;

export type BadgeProps = W.Infer<typeof Badge>;
