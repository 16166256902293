import useGlobalVariables from "@hooks/useGlobalVariables";
import type SerializedProfile from "@interfaces/SerializedProfile";
import { type UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation from "@utils/useFetchMutation";
import { usePageContext } from "@utils/usePageContext";

const clientSettingsUrlEndpoint = (userID: number, isGET = true) =>
  `/api/v1/profiles/${userID}/${
    isGET
      ? "?include[]=client_settings&include[]=organization_configuration&include[]=user_data&include[]=organization"
      : ""
  }`;

const fetchProfile = async (currentUserID: number) => {
  return fetchWithSession<UserProfileResponse>(clientSettingsUrlEndpoint(currentUserID)).then((res) => res.profile);
};

interface UserProfileResponse {
  profile: SerializedProfile;
}

const PROFILE_QUERY_KEY = (currentUserID: number) => ["userProfile", currentUserID];

const useCurrentUserProfile = <TData = SerializedProfile>(
  options?: Partial<UseQueryOptions<SerializedProfile, Error, TData, (string | number)[]>>,
) => {
  const { currentUserID } = useGlobalVariables();
  const { userprofile } = usePageContext();

  return useQuery<SerializedProfile, Error, TData, (string | number)[]>({
    queryKey: PROFILE_QUERY_KEY(currentUserID),
    queryFn: () => fetchProfile(currentUserID),
    staleTime: 3 * 60 * 1000,
    initialData: userprofile as any,
    enabled: !!currentUserID,
    ...options,
  });
};

function useMutateCurrentUserProfile() {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();

  return useFetchMutation<UserProfileResponse, Error, Partial<SerializedProfile>, unknown>(
    clientSettingsUrlEndpoint(currentUserID, false),
    (partialProfile) => partialProfile,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(PROFILE_QUERY_KEY(currentUserID), data.profile);
      },
    },
    "PATCH",
  );
}

export { useCurrentUserProfile, PROFILE_QUERY_KEY, clientSettingsUrlEndpoint, useMutateCurrentUserProfile };

export type { UserProfileResponse };
