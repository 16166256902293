import React from "react";

import Tooltip from "@components/Tooltip";
import type { ExploitDBExploit, FrontendSerializedThreat, ZdiCan } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

const limitRecordsToShow = 3;

const renderRecord = (zdiCan: ZdiCan, index) => {
  const exploitDBUrl = `https://${zdiCan.url}`;
  if (!zdiCan.can || !zdiCan.id || !zdiCan.name || !zdiCan.url) {
    return null;
  }

  return (
    <div key={`zdi_can_${index}`}>
      <Tooltip
        value={
          <div>
            <div className="text-xs text-gray-600">CAN: {zdiCan.can}</div>
            <div className="text-xs text-gray-600">ID: {zdiCan.id}</div>
            <div className="text-xs text-gray-600">Name: {zdiCan.name}</div>
            <div className="text-xs text-gray-600">Published: {zdiCan.published || "N/A"}</div>
            <div className="text-xs text-gray-600">Updated: {zdiCan.updated || "N/A"}</div>
            <div className="text-xs text-gray-600">
              URL: <a href={exploitDBUrl}>{exploitDBUrl}</a>
            </div>
          </div>
        }
      >
        <a
          className="ease-nav-brand text-slate-600 hover:text-blue-700 inline-flex items-center font-semibold transition-all"
          href={exploitDBUrl}
          target="_blank"
          rel="noreferrer"
        >
          {zdiCan.id}
          <i className="fas fa-external-link-alt ml-1 text-xs" />
        </a>
      </Tooltip>
    </div>
  );
};

const ZdiCansRenderer: (context: CellContext<FrontendSerializedThreat, ExploitDBExploit[]>) => JSX.Element = (
  context,
) => {
  const value = context.getValue();

  const zdicans: ZdiCan[] = isArrayLikeString(value) ? safeParseJSONtoArray(value) : [];

  if (zdicans?.length > 0) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row flex-wrap gap-2">
          {zdicans
            .slice(0, zdicans.length > limitRecordsToShow ? limitRecordsToShow : zdicans.length)
            .map((zdiCan, index) => {
              return renderRecord(zdiCan, index);
            })}
        </div>
        {zdicans.length > limitRecordsToShow ? (
          <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
            And {zdicans.length - limitRecordsToShow} more...
          </div>
        ) : null}
      </div>
    );
  }

  return <div>-</div>;
};

export default ZdiCansRenderer;
