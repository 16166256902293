import { SearchQueryWidget } from "@components/SearchQueryWidget";
import { useViewportWidth } from "@hooks/useViewportWidth";
import { useClickAway } from "@hooks/utils/useClickAway";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";

function mapRange(value: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function IndexSearchWidget() {
  const containerRef = useRef<HTMLDivElement>(null);
  const viewportWidth = useViewportWidth();
  const [isActive, setIsActive] = useState(false);

  const { initialWidth, extendedWidth } = useMemo(() => {
    // Small screens (<650px) [not actual, since we hide it until sm]
    // if (viewportWidth < 650) {
    //   return {
    //     initialWidth: Math.floor(viewportWidth * 0.3),
    //     extendedWidth: viewportWidth - 200,
    //   };
    // }

    // Medium screens (650px-1023px)
    if (viewportWidth < 1024) {
      const initial = Math.floor(mapRange(viewportWidth, 650, 1023, 220, 360));
      const extended = Math.floor(mapRange(viewportWidth, 650, 1023, 380, 480));
      return {
        initialWidth: initial,
        extendedWidth: extended,
      };
    }

    // Large screens (1024px+)
    const maxWidth = Math.min(1600, viewportWidth); // Cap at 1600px
    const initial = Math.floor(mapRange(maxWidth, 1024, 1600, 220, 400));
    const extended = Math.floor(mapRange(maxWidth, 1024, 1600, 380, 600));

    return {
      initialWidth: initial,
      extendedWidth: extended,
    };
  }, [viewportWidth]);

  // Handle clicking outside
  useClickAway(
    () => {
      setIsActive(false);
    },
    containerRef,
    "mousedown",
    [".flatpickr-calendar"],
  );

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isActive) {
        setIsActive(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [isActive]);

  const toggleActive = () => {
    setIsActive(true);
  };

  const handleSubmit = () => {
    setIsActive(false);
    window.location.href = "/analyze/";
  };

  return (
    <div ref={containerRef} className="relative items-start text-gray-1000 hidden sm:flex">
      {/* Base container that maintains layout space */}
      <div className="sm:min-w-64 h-8">
        {/* Transforming container */}
        <motion.div
          initial={false}
          animate={{
            width: isActive ? `${extendedWidth}px` : `${initialWidth}px`,
          }}
          transition={{
            type: "spring",
            stiffness: 200,
            damping: 25,
          }}
          className="absolute right-0 z-20"
        >
          <div onClick={toggleActive}>
            <SearchQueryWidget
              showSubmitButton={isActive}
              showResetButton={false}
              onSubmit={handleSubmit}
              customClassNames={{
                textarea: classNames("!min-h-[32px]", "transition-all duration-200 !text-gray-600", {
                  "!px-2 !py-0": !isActive,
                  "!bg-gray-400 !border rounded !border-gray-300 cursor-pointer hover:!border-gray-600 transition-all placeholder:!text-gray-600":
                    !isActive,
                  "leading-8 max-h-8 h-8 !overflow-hidden truncate": !isActive,
                }),
                ctaButtonIcon: " !top-2 !right-2",
              }}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export { IndexSearchWidget };
