export enum FIELD_TYPE {
  JSON = "json",
  STRING = "str",
  INTEGER = "int",
  FLOAT = "float",
  BOOLEAN = "boolean",
  DATE = "date",
  DATETIME = "datetime",

  // custom types
  NOTES = "notes",
  FAVORITE = "favorite",
  INTELS = "feeds",
  TAGS = "tags",
  MONITORED = "monitored",
  RISK_LEVEL = "risk_level",
  CVE_STATUS = "cve_status",
  COMPUTED = "computed",
  FUNCTION = "function",
}

export function escapeRegExp(str: string) {
  // http://stackoverflow.com
  // /questions/3446170/escape-string-for-use-in-javascript-regex
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
