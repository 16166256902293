import { type UseQueryOptions, useQuery } from "@tanstack/react-query";

import fetchWithSession from "@utils/fetchWithSession";
import type { SerializedRiskLevel } from "../interfaces";

export const organizationRiskLevelsUrlEndpoint = "/api/v1/organization/risk-levels/";

export const riskLevelsQueryKey = ["riskLevels"];

export const riskLevelsChangesQueryKey = ["riskLevelChangeLogs"];

const fetchRiskLevels = async () => {
  try {
    return await fetchWithSession(organizationRiskLevelsUrlEndpoint, { allowErrorResponse: true });
  } catch (error) {
    return Promise.reject(error);
  }
};

export function useRiskLevelsQuery(options: Partial<UseQueryOptions<SerializedRiskLevel[]>> = {}) {
  return useQuery<SerializedRiskLevel[]>({
    queryKey: riskLevelsQueryKey,
    queryFn: fetchRiskLevels,
    staleTime: 1 * 60 * 1000,
    retry: false,
    ...options,
  });
}
