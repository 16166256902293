import React from "react";

import type { FrontendSerializedThreat, SecurinCpe } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 15;
const SecurinCpesRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value: SecurinCpe[] = safeParseJSONtoArray(context.getValue());

  const renderRecord = (record: SecurinCpe, index: number) => (
    //   add comma after each record except the last one
    <span key={`securin_cpe_${index}`}>
      <span>{record.product}</span>
      {index !== value.length - 1 ? <span>, </span> : null}
    </span>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map(renderRecord)}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export default SecurinCpesRenderer;
