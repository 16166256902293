import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, MandiantMalware } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_RECORDS_TO = 3;
const MandiantMalwareRenderer: (context: CellContext<FrontendSerializedThreat, MandiantMalware[]>) => JSX.Element = (
  context,
) => {
  const value: MandiantMalware[] = context.getValue();

  if (!Array.isArray(value)) {
    return <>-</>;
  }

  const renderMalware = (malware: MandiantMalware, index: number) => (
    <span key={`mandiant_sources_${index}`} className="mr-2">
      {/* <a
        className="inline-flex items-center font-semibold transition-all ease-nav-brand text-slate-600 hover:text-blue-700"
        href={`/v/${malware.cve_id}`}
      > */}
      {malware.malware_name}
      {/* <i className="ml-1 text-xs fas fa-external-link-alt"></i> */}
      {/* </a> */}
    </span>
  );

  const fullView = <div>{value.map(renderMalware)}</div>;

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderMalware(source, index))}</div>
        <Tooltip value={fullView}>
          <div className="mt-2 text-xs text-right text-gray-600 cursor-progress">
            And {value.length - LIMIT_RECORDS_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export default MandiantMalwareRenderer;
