import { useFieldReferencesStateSync } from "@features/field_references/useFieldReferencesStateSync";
import { useLoadingAnimationCleanup } from "@hooks/useLoadingAnimationCleanup";
import { useLocation } from "@hooks/useLocation";
import { useOrganizationTier } from "@hooks/useOrganizationsConfiguration";
import { IconButton } from "@material-tailwind/react/components/IconButton";
import { Navbar } from "@material-tailwind/react/components/Navbar";
import { is_flag_enabled } from "@utils/is_flag_enabled";
import { usePageContext } from "@utils/usePageContext";
import React, { useState, Suspense, useEffect } from "react";
import { NavLink } from "react-router";
import { ImpersonatedBanner } from "./ImpersonatedBanner";
import { AdminMenu } from "./NucleusNavbar/AdminMenu";
import { HelpSection } from "./NucleusNavbar/HelpSection";
import { IndexSearchWidget } from "./NucleusNavbar/IndexSearchWidget";
import { NavList } from "./NucleusNavbar/NavList";
import { Notifications } from "./NucleusNavbar/Notifications";
import { ProfileMenu } from "./NucleusNavbar/ProfileMenu";
import { QuickStart } from "./NucleusNavbar/QuickStart";
import TierNotifyBanner from "./NucleusNavbar/TierNotifyBanner";

function NucleusNavbar() {
  useLoadingAnimationCleanup();
  useFieldReferencesStateSync();
  const { is_impersonated, userprofile } = usePageContext();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const toggleIsMobileNavOpen = () => setIsMobileNavOpen((cur) => !cur);

  useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth >= 960 && setIsMobileNavOpen(false));
  }, []);

  const currentUserOrganizationTier = useOrganizationTier();
  const username = userprofile?.username ?? userprofile.email;
  const currentPath = useLocation();

  const hasIndexSearchWidget = currentPath === "/" && is_flag_enabled("NEW_DASHBOARD_FLAG"); // need some better way
  return (
    <>
      <Navbar className="top w-full max-w-full py-1 !rounded-none !backdrop-blur-none !shadow-none" color="transparent">
        <div className="container mx-auto">
          <div className="flex items-center justify-between flex-1">
            <NavLink to="/" className="md:justify-start flex items-center justify-center text-gray-900 gap-2">
              <img
                className="md:w-6 md:h-6 w-5 h-5"
                height="24"
                width="24"
                src="/static/img/logos/nucleus_logo.svg"
                alt="logo"
              />
              <span className="lg:text-2xl text-xl font-normal leading-none text-space-gray font-[Sora]">VIP</span>
            </NavLink>
            <IconButton size="sm" variant="text" onClick={toggleIsMobileNavOpen} className="lg:hidden">
              <i className="fas fa-lg fa-bars" />
            </IconButton>
            {/* vertical separator */}
            <div className="lg:block hidden w-px h-6 bg-gray-400 mx-4 p-0" />
            <div>
              <Suspense fallback={<div />}>
                <NavList isMobileNavOpen={isMobileNavOpen} setIsMobileNavOpen={setIsMobileNavOpen} />
              </Suspense>
            </div>
            <div className="grow" />
            {hasIndexSearchWidget && <IndexSearchWidget />}
            <QuickStart />
            {currentUserOrganizationTier && currentUserOrganizationTier !== "PAID" ? <TierNotifyBanner /> : null}
            <React.Suspense fallback={<div />}>
              <HelpSection />
            </React.Suspense>
            <AdminMenu />
            <Notifications />
            <ProfileMenu is_impersonated={is_impersonated} />
          </div>
        </div>
      </Navbar>
      {is_impersonated && <ImpersonatedBanner username={username} currentPath={currentPath} />}
    </>
  );
}

export { NucleusNavbar };
