import type React from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

// Rendering a React component into a DOM element that is not a direct child of the root React element. This way we are able to render multiple React widgets in our Django template.
function TemplateSlot({
  children,
  slotID,
  keepContent = false,
  classNames,
  keepClasses = false,
}: {
  children?: React.ReactNode;
  slotID: string;
  keepContent?: boolean;
  classNames?: string;
  keepClasses?: boolean;
}) {
  const [wrapperElement, setWrapperElement] = useState<any>();

  useEffect(() => {
    const element = document.getElementById(slotID);
    if (!element) {
      return;
    }

    if (!keepContent) {
      element.innerHTML = ""; // replacing template content with react component, so we can use original template for displaying loading initial state

      // clear classes from template element
      if (!keepClasses) {
        element.classList.remove(...(element.classList as any));
      }

      if (classNames) {
        for (const className of classNames.split(" ")) {
          element.classList.add(className);
        }
      }
    }

    if (element) {
      setWrapperElement(element);
    }

    return () => {
      setWrapperElement(null);
    };
  }, [slotID]);

  // wrapperElement state will be null on the very first render or if slot wasnt found
  if (!wrapperElement) {
    return null;
  }

  return createPortal(children, wrapperElement, slotID);
}

export default TemplateSlot;
