import { iconMetadata, icons } from "@assets/feeds";
import type { Feed } from "@configs/feeds";
import classNames from "classnames";

type IconSize = "xs" | "sm" | "md" | "lg";

const containerSizes: Record<IconSize, string> = {
  xs: "w-6 h-6",
  sm: "w-8 h-8",
  md: "w-10 h-10",
  lg: "w-12 h-12",
};

const iconSizes: Record<IconSize, string> = {
  xs: "w-4 h-4",
  sm: "w-5 h-5",
  md: "w-6 h-6",
  lg: "w-8 h-8",
};

interface FeedIconProps {
  fieldPrefix: Feed;
  isActive?: boolean;
  isBordered?: boolean;
  size?: IconSize;
}

const FeedIcon = ({ fieldPrefix, isActive = true, isBordered = false, size = "xs" }: FeedIconProps) => {
  const iconSrc = icons[fieldPrefix];
  if (!iconSrc) {
    return null;
  }

  const customSize = iconMetadata[fieldPrefix as keyof typeof iconMetadata];
  const baseIconSize = iconSizes[size];

  // Calculate scaled dimensions for custom-sized icons
  const iconSize = customSize
    ? {
        width: Math.round((customSize?.width / 16) * Number.parseInt(baseIconSize?.split(" ")[0]?.slice(2) || "0")),
        height: Math.round((customSize?.height / 16) * Number.parseInt(baseIconSize?.split(" ")[0]?.slice(2) || "0")),
      }
    : null;

  const iconClassName = classNames({
    [baseIconSize]: !customSize,
    [`w-[${iconSize?.width}px] h-[${iconSize?.height}px]`]: customSize,
    "grayscale opacity-40": !isActive,
  });

  return (
    <picture
      className={classNames("flex items-center justify-center mr-1", containerSizes[size], {
        "border border-gray-300 rounded": isBordered,
      })}
    >
      <img src={iconSrc} alt={fieldPrefix} className={iconClassName} />
    </picture>
  );
};

export { FeedIcon };
