import {
  type FieldReferencesFieldsState,
  getFieldReferencesFieldsSnapshot,
} from "@features/field_references/fieldReferences.state";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { createRoot } from "react-dom/client";
import { FilterPopover } from "./FilterPopover";
import type QLContext from "./QLContext";
import { Suggestion, type SuggestionProps } from "./Suggestion";

interface HistorySuggestionProps extends SuggestionProps {
  fieldReferences: FieldReferencesFieldsState;
}

export class HistorySuggestion extends Suggestion {
  private popover: HTMLElement | null = null;
  private root: ReturnType<typeof createRoot> | null = null;
  private context!: QLContext;
  private fieldReferences: FieldReferencesFieldsState;

  constructor(props: HistorySuggestionProps) {
    super(props);
    this.type = "widget";
    this.fieldReferences = props.fieldReferences;
  }

  createInstance = (_id: string, context: QLContext) => {
    this.popover = document.createElement("div");
    document.body.appendChild(this.popover);

    const initialData = this.parseInitialData(context);

    this.root = createRoot(this.popover);
    this.root.render(
      (
        <FilterPopover
          onSubmit={this.handleSubmit}
          onClose={this.handleClose}
          position={this.getPopoverPosition()}
          initialData={initialData}
          fieldReferences={this.fieldReferences}
        />
      ) as any,
    );

    this.context = context;
    this.isReplacement = true;

    return null;
  };

  parseInitialData = (context: QLContext) => {
    const initialData = {
      field: "",
      dateOfChange: { operator: "=", value: "" },
      newValue: { operator: "=", value: "" },
      oldValue: { operator: "=", value: "" },
    };

    const currentFullToken = context.currentFullToken;
    if (!currentFullToken) {
      return initialData;
    }

    const strValue = currentFullToken.value;
    const parts = strValue.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/).map((part) => part.trim());

    // Set the field
    const field = parts[0] as ThreatKey;
    const fieldReferences = getFieldReferencesFieldsSnapshot();
    if (field && fieldReferences[field]) {
      initialData.field = field;
    }

    // Parse the rest of the parts
    for (const part of parts.slice(1)) {
      const [key, ...valueParts] = part.split(/([><!=]+)/);
      let operator = valueParts[0] || "=";
      let cleanValue = valueParts.slice(1).join("").replace(/^"|"$/g, "");
      if (operator === "!" && cleanValue.startsWith("=")) {
        operator = "!=";
        cleanValue = cleanValue.slice(1).trim();
      }

      switch (key?.trim()) {
        case "new_value":
          initialData.newValue = { operator, value: cleanValue };
          break;
        case "old_value":
          initialData.oldValue = { operator, value: cleanValue };
          break;
        case "date_of_change":
          initialData.dateOfChange = { operator, value: cleanValue };
          break;
        default:
          break;
      }
    }

    return initialData;
  };

  getPopoverPosition = () => {
    const textareaRect = this.textarea.getBoundingClientRect();
    return {
      top: textareaRect.bottom + window.scrollY,
      left: textareaRect.left + window.scrollX,
    };
  };

  handleSubmit = (data: {
    field: string;
    dateOfChange: { operator: string; value: string | null };
    newValue: { operator: string; value: string };
    oldValue: { operator: string; value: string };
  }) => {
    // build the query from the data
    let query = `(${data.field}`;

    if (data.newValue.value) {
      query += `, new_value${data.newValue.operator}"${data.newValue.value}"`;
    }

    if (data.oldValue.value) {
      query += `, old_value${data.oldValue.operator}"${data.oldValue.value}"`;
    }

    const { operator, value } = data.dateOfChange;
    if (value) {
      query += `, date_of_change${operator}"${value}"`;
    }

    query += ")";

    this.snippetAfter = `${query} `;
    this.onSelect(this.context);
    this.destroy();
  };

  handleClose = () => {
    this.destroy();
  };

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
    if (this.popover) {
      this.popover.remove();
      this.popover = null;
    }
  }
}
