import { RecordsType, useClosestListingTableState, useClosestListingTableStateSetter } from "@features/listing";
import Tooltip from "@material-tailwind/react/components/Tooltip";
import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { NucleusQL } from "./NucleusQL";
import type { NucleusQLState } from "./NucleusQL/NucleusQLState";
import { useClosestNucleusQLState } from "./NucleusQL/useClosestNucleusQLState";
import type { SearchQueryTextAreaCustomClassNames } from "./SearchQueryTextArea";

const PlaceholderButton = ({ customClassNames }: { customClassNames?: SearchQueryTextAreaCustomClassNames }) => {
  return (
    <i className={classNames("far fa-search absolute text-gray-500 right-3 top-3", customClassNames?.ctaButtonIcon)} />
  );
};

const ResetButton = () => {
  const { listingTableState } = useClosestListingTableState();
  const [nucleusQLState] = useClosestNucleusQLState();

  const onResetClickHandler = () => {
    listingTableState.updateSearchQuery("");
    nucleusQLState.updateState({
      value: "",
      queryParsingError: null,
    });
  };

  return (
    <i
      title="Reset search query"
      className="fas fa-backspace hover:text-gray-700 top-3 right-3 absolute text-gray-300 transition-all cursor-pointer"
      onClick={onResetClickHandler}
    />
  );
};

const SubmitButton = ({
  onSubmit,
  customClassNames,
}: { onSubmit?: () => void; customClassNames?: SearchQueryTextAreaCustomClassNames }) => {
  const [nucleusQLState, nucleusQLSnap] = useClosestNucleusQLState();
  const listingTableState = useClosestListingTableStateSetter();

  const onSubmitClickHandler = () => {
    // todo: dry with searchInput?
    if (!listingTableState.updateSearchQuery(nucleusQLSnap.value)) {
      // if query is invalid, widget wont be updated by effect, we have to trigger validation there
      nucleusQLState.updateState(
        {
          value: nucleusQLSnap.value,
        },
        true,
      );
    } else {
      onSubmit?.();
    }
  };

  return (
    <Tooltip content="Submit search query" placement="bottom">
      <i
        className={classNames(
          "fas fa-long-arrow-right text-gray-600 hover:text-green-700 top-3 right-3 absolute  transition-all cursor-pointer",
          customClassNames?.ctaButtonIcon,
        )}
        onClick={onSubmitClickHandler}
      />
    </Tooltip>
  );
};

interface SearchQueryWidgetProps {
  customClassNames?: SearchQueryTextAreaCustomClassNames;
  showResetButton?: boolean;
  showSubmitButton?: boolean;
  onSubmit?: () => void;
}

function SearchQueryWidget({
  customClassNames,
  showResetButton = true,
  showSubmitButton = false,
  onSubmit,
}: SearchQueryWidgetProps) {
  const { listingTableState, listingTableSnap } = useClosestListingTableState();
  const [nucleusQLState, nucleusQLSnap] = useClosestNucleusQLState();
  const isValidParsing = !nucleusQLSnap.queryParsingError;

  // We need to update widget state when listing searchQuery changes since it could be changed somewhere else
  // biome-ignore lint/correctness/useExhaustiveDependencies: <adding rest of depenncies breaks everything, since it should be one-way binding>
  useEffect(() => {
    if (nucleusQLSnap.value !== listingTableSnap.searchQuery) {
      nucleusQLState.updateState({ value: listingTableSnap.searchQuery });
    }
  }, [listingTableSnap.searchQuery]);

  const isInputValueEmpty = nucleusQLSnap.value.length === 0;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onChangeCallback = useCallback((value?: Partial<NucleusQLState>, _isValidate?: boolean, isSubmit?: boolean) => {
    if (isSubmit) {
      listingTableState.updateSearchQuery(value?.value ?? "") && onSubmit?.();
    }
  }, []);

  const placeholder = `Search ${listingTableSnap.recordsType === RecordsType.CVEs ? "vulnerabilities" : "malwares"}`;

  return (
    <div className="relative">
      <NucleusQL
        onChangeCallback={onChangeCallback}
        inputName="searchQueryWidget"
        placeholder={placeholder}
        customClassNames={customClassNames}
      />
      {!isValidParsing && (
        // display error message from widgetState.state.queryParsingError
        <div className="text-xs text-orange-500 truncate bg-white">{nucleusQLSnap.queryParsingError}</div>
      )}
      {!isInputValueEmpty && showResetButton && !showSubmitButton && <ResetButton />}
      {!isInputValueEmpty && showSubmitButton && (
        <SubmitButton onSubmit={onSubmit} customClassNames={customClassNames} />
      )}
      {isInputValueEmpty && <PlaceholderButton customClassNames={customClassNames} />}
    </div>
  );
}

export { SearchQueryWidget };
