import { v4 as uuidv4 } from "uuid";

import type { SerializedRiskLevel, SerializedRiskLevelPost } from "./interfaces";

export class SerializedRiskLevelModel implements SerializedRiskLevel {
  id?: number;
  risk_level = "";
  priority = 0;
  count: {
    last_revalidated: string;
    count: number;
  } | null = null;
  search = "";
  transformed_search?: string = undefined;
  color = "";
  description?: string = undefined;

  // Unique identifier for frontend operations
  uuid: string = uuidv4();
  _is_generated = false;

  constructor(riskLevel: SerializedRiskLevel | SerializedRiskLevelModel) {
    Object.assign(this, riskLevel);

    if (!(riskLevel as SerializedRiskLevelModel).uuid || (riskLevel as SerializedRiskLevelModel)._is_generated) {
      // If we dont have a uuid, or if the risk level is generated, we need to generate a new uuid
      this.uuid = uuidv4();
      this._is_generated = true;
    }
  }

  toApi(): SerializedRiskLevelPost {
    return {
      id: this.id,
      risk_level: this.risk_level,
      priority: this.priority,
      search: this.search,
      transformed_search: this.transformed_search,
      color: this.color,
      description: this.description && this.description.length > 0 ? this.description : undefined,
    };
  }
}
