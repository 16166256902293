import type { UserData } from "@interfaces/SerializedProfile";
import {
  PROFILE_QUERY_KEY,
  type UserProfileResponse,
  clientSettingsUrlEndpoint,
  useCurrentUserProfile,
} from "@queries/useCurrentUserProfile";
import { useQueryClient } from "@tanstack/react-query";
import useFetchMutation from "@utils/useFetchMutation";

import useGlobalVariables from "./useGlobalVariables";

function useUserData() {
  return useCurrentUserProfile<UserData>({
    select: (profile) => {
      const { user_data } = profile || {};

      // ideally we need some validation here, as well as migrations for old settings
      if (user_data && Object.keys(user_data).length) {
        return user_data as UserData;
      }

      // use defaults
      return {} as UserData;
    },
  });
}

function useMutateUserData() {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();
  const { data: userData } = useUserData();

  return useFetchMutation<UserProfileResponse, Error, Partial<UserData>, unknown>(
    clientSettingsUrlEndpoint(currentUserID, false),
    (partialUserData) => {
      // merge partial data with existing data
      // merge data sub-folders manually, as we dont want old values to be kept
      const updatedUserData = {
        ...userData,
        ...partialUserData,
      };

      return {
        user_data: updatedUserData,
      };
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(PROFILE_QUERY_KEY(currentUserID), data.profile);
      },
    },
    "PATCH",
  );
}

export { useMutateUserData };

function useIsAdmin() {
  const { data: userProfile } = useCurrentUserProfile();

  return Boolean(userProfile?.is_staff);
}

export { useIsAdmin };

export default useUserData;
