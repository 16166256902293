import { type BasicTarget, getTargetElement } from "./misc/domTarget";
import { getDocumentOrShadow } from "./misc/getDocumentOrShadow";
import { useEffectWithTarget } from "./useEffectWithTarget";
import { useLatest } from "./useLatest";

type DocumentEventKey = keyof DocumentEventMap;

export function useClickAway<T extends Event = Event>(
  onClickAway: (event: T) => void,
  target: BasicTarget | BasicTarget[],
  eventName: DocumentEventKey | DocumentEventKey[] = "click",
  excludedClassNames: string[] = [],
) {
  const onClickAwayRef = useLatest(onClickAway);

  useEffectWithTarget(
    () => {
      const handler = (event: any) => {
        // For touch events, use the first touch point
        const eventTarget = event.touches ? event.touches[0] : event;
        const targets = Array.isArray(target) ? target : [target];

        // Check if click/touch is inside excluded class
        const isExcludedClass = excludedClassNames.some((className) => eventTarget.target?.closest?.(className));
        if (isExcludedClass) {
          return;
        }

        if (
          targets.some((item) => {
            const targetElement = getTargetElement(item);
            return !targetElement || targetElement.contains(event.target);
          })
        ) {
          return;
        }
        onClickAwayRef.current(event);
      };

      const documentOrShadow = getDocumentOrShadow(target);
      const eventNames = Array.isArray(eventName) ? eventName : [eventName];

      for (const event of eventNames) {
        documentOrShadow.addEventListener(event, handler);
      }

      return () => {
        for (const event of eventNames) {
          documentOrShadow.removeEventListener(event, handler);
        }
      };
    },
    Array.isArray(eventName) ? eventName : [eventName],
    target,
  );
}
