import { isEqual } from "lodash-es";

import type { RecordsType } from "@features/listing";
import type { ColumnSort } from "@tanstack/react-table";

import { useClosestContextListing } from "./useClosestContextListing";

// Equals to ThreatModel.ordering
const DEFAULT_SORTING = ["-nvd_published_date", "-cve_threat_intel_id"]; // TODO:IMP: this should be taken from the backend or at least some constant

// Generates the original field names for sorting based on the applied ordering.
function useListingAppliedOrdering(recordsType: RecordsType) {
  const { data } = useClosestContextListing({
    enabled: recordsType === "CVEs",
  });

  const { sorting = [] } = data ?? {};

  // Sorting is in format like ['-vip_favorite', '-nvd_published_date', 'cve_threat_intel_id']
  // OR like ['mandiant_exploit_rating_sorted'] is its applied from frontend by user
  // if '_sorted' is present, we should remove it to get the original field name
  return sorting.map((sort) =>
    // sorted for fields with options, count for jsonb fields
    sort
      .replace("_sorted", "")
      .replace("_count", ""),
  );
}

function useClientSorting(tableDefinedSorting: ReadonlyArray<ColumnSort>, recordsType: RecordsType) {
  // Actually returns the applied sorting to the query(backend)
  const queryAppliedSorting = useListingAppliedOrdering(recordsType); // Sorting is in format like ['-vip_favorite', '-nvd_published_date', 'cve_threat_intel_id']

  // converting sorting to the format that react-table expects and adding default sorting which is added by backend
  const tableSortingNormalized = tableDefinedSorting.map((sort) => {
    return `${sort.desc ? "-" : ""}${sort.id}`;
  });

  // we have to compare query applied sorting with the one we have in the table, to figure out if we should allow the table to handle sorting at all
  const isTableSortingApplied =
    tableSortingNormalized.length > 0 && tableSortingNormalized[0] === queryAppliedSorting[0];

  const isQueryLanguageSortingApplied = queryAppliedSorting.length > 0 && !isTableSortingApplied;

  const isDefaultSortingApplied = isEqual(DEFAULT_SORTING, queryAppliedSorting);

  // returns true if we should allow the table to handle sorting
  const canHandleSorting = isDefaultSortingApplied || (isTableSortingApplied && !isQueryLanguageSortingApplied);

  const appliedSorting = isQueryLanguageSortingApplied
    ? (queryAppliedSorting.map((sort) => {
        const desc = sort.startsWith("-");
        const id = desc ? sort.slice(1) : sort;
        return { id, desc };
      }) as ReadonlyArray<ColumnSort>)
    : tableDefinedSorting;

  // we only allow one sorting direction shown at a time
  return [canHandleSorting, appliedSorting.slice(0, 1)] as const;
}

export { useClientSorting };
