import { useLatest } from "./useLatest";

import { useEffect } from "react";
import { isDev, isFunction } from "./misc/is";

export const useUnmount = (fn: () => void) => {
  if (isDev) {
    if (!isFunction(fn)) {
      console.error(`useUnmount expected parameter is a function, got ${typeof fn}`);
    }
  }

  const fnRef = useLatest(fn);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <its ref>
  useEffect(
    () => () => {
      fnRef.current();
    },
    [],
  );
};
