import React from "react";

import Badge from "@components/Badge";
import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 6;
//  "[{\"geo_code\":\"PH\",\"unique_ips\":7},{\"geo_code\":\"TH\",\"unique_ips\":12},{\"geo_code\":\"RU\",\"unique_ips\":4},{\"geo_code\":\"KR\",\"unique_ips\":7},{\"geo_code\":\"BR\",\"unique_ips\":5},{\"geo_code\":\"TW\",\"unique_ips\":3},{\"geo_code\":\"ID\",\"unique_ips\":8},{\"geo_code\":\"DE\",\"unique_ips\":19},{\"geo_code\":\"NL\",\"unique_ips\":3},{\"geo_code\":\"JP\",\"unique_ips\":11},{\"geo_code\":\"NG\",\"unique_ips\":3},{\"geo_code\":\"HK\",\"unique_ips\":19},{\"geo_code\":\"UK\",\"unique_ips\":16},{\"geo_code\":\"IN\",\"unique_ips\":7},{\"geo_code\":\"SG\",\"unique_ips\":18},{\"geo_code\":\"AU\",\"unique_ips\":4},{\"geo_code\":\"US\",\"unique_ips\":43},{\"geo_code\":\"MY\",\"unique_ips\":1},{\"geo_code\":\"AE\",\"unique_ips\":4}]"
const ShadowserverGeoRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = context.getValue();

  if (!value || value.length === 0) {
    return <div className="text-gray-400">None</div>;
  }

  const geos = isArrayLikeString(value) ? safeParseJSONtoArray(value) : [];

  if (!Array.isArray(geos) || geos.length === 0) {
    return <div className="text-gray-400">None</div>;
  }

  const renderRecord = (record, index) => {
    const geoCode = record.geo_code;
    const uniqueIps = record.unique_ips;
    return (
      <div key={`geo_${geoCode}`} className="mb-2 mr-2">
        <Badge color="default">
          <div className="text-xs">
            {geoCode} ({uniqueIps})
          </div>
        </Badge>
      </div>
    );
  };

  if (geos.length > LIMIT_RECORDS_TO) {
    const fullView = <div className="flex flex-wrap">{geos.map((source, index) => renderRecord(source, index))}</div>;

    return (
      <div>
        <div className="flex flex-wrap">
          {geos.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderRecord(source, index))}
        </div>
        <Tooltip value={fullView}>
          <div className="mt-1 text-xs text-right text-gray-600">And {geos.length - LIMIT_RECORDS_TO} more...</div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap">
      {geos.map((geo, index) => {
        return renderRecord(geo, index);
      })}
    </div>
  );
};

export default ShadowserverGeoRenderer;
