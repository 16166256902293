import React from "react";

import { RecordsType } from "@features/listing";
import { useOrganizationConfiguration } from "@hooks/useOrganizationsConfiguration";
import { SerializedMandiantMalwareKeys } from "@interfaces/SerializedMandiantMalware";
import useIntrospections from "@queries/useIntrospections";
import { is_flag_enabled } from "@utils/is_flag_enabled";

// todo: We need to sort out usage of useVisibleColumns and useAvailableColumns hooks to grab columns, introspections and available fields
// Returns available threat columns by introspections
function useAvailableColumns(recordsType: RecordsType) {
  const { data: introspections } = useIntrospections();
  const { data: organizationData } = useOrganizationConfiguration();

  return React.useMemo(() => {
    if (recordsType === RecordsType.Malware) {
      return SerializedMandiantMalwareKeys;
    }

    // vip_id is always shown
    // Filter out vip_intels field since its always included into vip_id rendering
    // Filter out vip_favorite field since its always included into vip_id rendering
    // Literally fields which shouldnt be in the columns toggle
    const fieldsToExclude = ["vip_id", "cve_id", "cve_threat_intel_id", "vip_intels", "vip_favorite", "vip_history"];

    if (!organizationData?.are_custom_risk_levels_enabled) {
      fieldsToExclude.push("vip_risk_level");
    }

    if (!is_flag_enabled("VULNERABILITY_STATUS_FLAG")) {
      fieldsToExclude.push("vip_status");
    }

    const availableColumns = Object.keys(introspections.fields)
      .map((key) => key)
      .filter((field) => !fieldsToExclude.includes(field));

    return availableColumns;
  }, [introspections, recordsType, organizationData]);
}

export default useAvailableColumns;
