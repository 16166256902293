import useSiteSettings from "@queries/useSiteSettings";
import { HeaderMenu, type MenuTypographyItemProps } from "./HeaderMenu";

const HelpSection: React.FC = () => {
  const { data: siteSettings } = useSiteSettings();
  const { is_field_reference_enabled: isFieldReferenceEnabled } = siteSettings ?? {};

  const menuItems: MenuTypographyItemProps[] = [
    {
      label: "Documentation",
      iconClass: "far fa-file-alt",
      url: siteSettings?.documentation_url ?? "",
      external: true,
    },
    {
      label: "Support & Feature Requests",
      iconClass: "far fa-heart",
      url: siteSettings?.support_url ?? "",
      external: true,
    },
    {
      label: "Vulnerability Intelligence Sources",
      iconClass: "far fa-database",
      url: isFieldReferenceEnabled ? "/sources" : "",
    },
  ].filter((item) => item.url?.length);

  if (!menuItems.length) {
    return null;
  }

  return <HeaderMenu menuItems={menuItems} iconClass="far fa-question-circle" />;
};

export { HelpSection };
