// Create a mapping of icon names to their CSS classes
export const icons = {
  close: "fa-times",
  users: "fa-users",
  remove: "fa-trash",
  tags: "fa-tags",
  riskLevels: "fa-exclamation-triangle",
  statuses: "fa-check-square",
  clipboard: "fa-clipboard",
  edit: "fa-edit",
  spinner: "fa-spin fa-spinner",
  bookmark: "fa-bookmark",
  checkCircle: "fa-check-circle",
  xCircle: "fa-times-circle",
  api: "fa-code",
  monitor: "fa-eye",
  monitorOff: "fa-eye-slash",
  pdf: "fa-file-pdf",
  error: "fa-exclamation-triangle",
  star: "fa-star",
  emptySet: "fa-empty-set",
  redo: "fa-redo",
  search: "fa-search",
} as const;

// Helper function to get the full class name including style prefix
export function getIconClass(icon: keyof typeof icons, style: "fas" | "far" | "fal" = "far") {
  return `${style} ${icons[icon]}`;
}
