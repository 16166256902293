import type SerializedProfile from "@interfaces/SerializedProfile";
import type { IntrospectionsResponse } from "@queries/useIntrospections";
import { proxy, useSnapshot } from "valtio";

const contextElementID = "page-context";

// Common page context that is available on all pages by default
interface CommonPageContext {
  introspections: IntrospectionsResponse;
  userprofile: SerializedProfile;
  is_impersonated: boolean;
  notifications_count: number;
}

const pageContextState = proxy<CommonPageContext>(getInitialPageContext());

function getInitialPageContext(): CommonPageContext {
  const pageContextElement = document.getElementById(contextElementID);
  if (!pageContextElement) {
    throw new Error("Page context element not found");
  }

  const pageContext = pageContextElement.textContent;
  if (pageContext) {
    try {
      return JSON.parse(pageContext) as CommonPageContext;
    } catch (e) {
      throw new Error("Failed to parse page context");
    }
  }

  throw new Error("Page context is empty");
}

function usePageContext(): CommonPageContext {
  return useSnapshot(pageContextState) as CommonPageContext;
}

function optimisticUpdatePageContext<K extends keyof CommonPageContext>(key: K, value: CommonPageContext[K]) {
  // Update both Valtio state and DOM element
  pageContextState[key] = value;
  document.getElementById(contextElementID)!.textContent = JSON.stringify(pageContextState);
}

export { getInitialPageContext, usePageContext, optimisticUpdatePageContext };
export type { CommonPageContext };