import { LEXER_TOKENS } from "@utils/Lexer";
import { MenuSuggestion } from "./MenuSuggestion";
import type QLContext from "./QLContext";
import { Suggestion } from "./Suggestion";

const LENGHT_TO_GROUP_BY_CATEGORY = 18; // at which fields length we should group them by categories
const createMenuSuggestionWithCategories = (
  allFieldsKeys: string[],
  context: QLContext,
  textarea: HTMLTextAreaElement,
) => {
  // we have all fields available in our model, `cve_threat_intel_id` is not shown as its internal field
  const all_fields = allFieldsKeys.filter(
    (field) => field !== "cve_threat_intel_id" && field !== "vip_id" && field !== "cve_id",
  );

  // we need to figure out categories for fields, based on their '(.*)_.*' path, for example we have 'cisa_id' and 'cisa_name' fields, so we have 'cisa' category and those fields are children of it
  const categories = (
    all_fields.length > LENGHT_TO_GROUP_BY_CATEGORY
      ? all_fields
          .map((field) => {
            const prefix = field.split("_")[0];
            return prefix === "nucleus" || prefix === "vip" ? "vip" : prefix;
          })
          .filter((value, index, self) => self.indexOf(value) === index)
      : all_fields
  ) as string[];

  // now fill categories with fields from those categories, {'cisa': ['cisa_id', 'cisa_name']}
  const fields_by_category = new Map<string, string[]>();
  for (const category of categories) {
    fields_by_category.set(
      category,
      all_fields.filter((field) => {
        const prefix = field.split("_")[0];
        return category === "vip" ? prefix === "vip" || prefix === "nucleus" : field.startsWith(category);
      }),
    );
  }

  // if thats ordering, we cut custom fields(Nucleus VIP) from suggestions
  if (context.lastToken?.name === LEXER_TOKENS.ORDER_BY) {
    fields_by_category.delete("vip");
  }

  const dropdownMenuChildren: any = [];
  // vip_id goes separately from menu category
  if (allFieldsKeys.includes("vip_id")) {
    dropdownMenuChildren.push(
      new Suggestion({
        text: "vip_id",
        snippetAfter: " ",
        textarea: textarea,
      }),
    );
  }

  // same for cve_id
  if (allFieldsKeys.includes("cve_id")) {
    dropdownMenuChildren.push(
      new Suggestion({
        text: "cve_id",
        snippetAfter: " ",
        textarea: textarea,
      }),
    );
  }

  // generate suggestion for each category with inside fields
  fields_by_category.forEach((fields, category) => {
    const category_suggestion = new MenuSuggestion({
      context: context,
      text: category,
      textarea: textarea,
      children: fields.map((field) => {
        return new Suggestion({
          text: field,
          snippetAfter: field === "vip_history" ? "" : " ",
          textarea: textarea,
        });
      }),
    });

    dropdownMenuChildren.push(category_suggestion);
  });

  const dropdownMenuSuggestion = new MenuSuggestion({
    children: dropdownMenuChildren,
    type: "menu",
    context: context,
    textarea: textarea,
  });

  return dropdownMenuSuggestion;
};

export default createMenuSuggestionWithCategories;
