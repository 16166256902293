import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

const NVDVendorsRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = context.getValue();

  const vendors = isArrayLikeString(value) ? safeParseJSONtoArray(value) : [];

  if (vendors.length > 0) {
    return (
      <div className="flex flex-row flex-wrap gap-1">
        {vendors.map((ref, index) => {
          return (
            <span key={`references-${ref}`}>
              {ref}
              {index < vendors.length - 1 ? "," : ""}
            </span>
          );
        })}
      </div>
    );
  }

  return <div>-</div>;
};

export default NVDVendorsRenderer;
