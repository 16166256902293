interface SerializedMandiantMalwareActor {
  country_name: string;
  id: string;
  iso2: string;
  last_updated: string;
  name: string;
}

interface SerializedMandiantMalwareAlias {
  name: string;
}

interface SerializedMandiantMalwareCapability {
  description: string;
  name: string;
}

interface SerialinzedMandiantMalwareIndustry {
  id: string;
  name: string;
}

interface SerialinzedMandiantMalwareYara {
  id: string;
  name: string;
}

interface SerializedMandiantMalwareCVE {
  cve_id: string;
  id: string;
}

export default interface SerializedMandiantMalware {
  mandiant_malware_id: number;
  id: string;
  actors: SerializedMandiantMalwareActor[];
  aliases: SerializedMandiantMalwareAlias[];
  capabilities: SerializedMandiantMalwareCapability[];
  cves: SerializedMandiantMalwareCVE[];
  description: string;
  detections: string[];
  industries: SerialinzedMandiantMalwareIndustry[];
  inherently_malicious: number; // 0 or 1
  last_activity_time: string;
  last_updated: string;
  name: string;
  operating_systems: string[];
  roles: string[];
  yara: SerialinzedMandiantMalwareYara[];
}

export const SerializedMandiantMalwareKeys = [
  "mandiant_malware_id",
  "id",
  "actors",
  "aliases",
  "capabilities",
  "cves",
  "description",
  "detections",
  "industries",
  "inherently_malicious",
  "last_activity_time",
  "last_updated",
  "name",
  "operating_systems",
  "roles",
  "yara",
] as const;

export type SerializedMandiantMalwareKey = (typeof SerializedMandiantMalwareKeys)[number];
