import React from "react";

import Tooltip from "@components/Tooltip";
import { MandiantTechnologyInstance } from "@interfaces/MandiantTechnologyInstance";
import type { FrontendSerializedThreat, MandiantTechnology } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_RECORDS_TO = 6;
const MandiantTechnologiesRenderer: (
  context: CellContext<FrontendSerializedThreat, MandiantTechnology[]>,
) => JSX.Element = (context) => {
  const value: MandiantTechnology[] = context.getValue();

  if (!value || !Array.isArray(value)) {
    return <>-</>;
  }

  const textClass = "text-sm text-gray-500";

  const renderRecord = (record: MandiantTechnology, index) => {
    const technologyInstance = new MandiantTechnologyInstance(record);
    return (
      <Tooltip
        key={`mandiant_exploit_${technologyInstance.cpe}`}
        value={<div className={textClass}>{technologyInstance.cpe}</div>}
      >
        <div>
          <span className="text-xs">{technologyInstance.getTechnologyName()}</span>
          {index < value.length - 1 ? ", " : ""}
        </div>
      </Tooltip>
    );
  };

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderRecord(source, index))}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export default MandiantTechnologiesRenderer;
