import ColoredLabel from "@components/ListingTable/TableInstance/fieldRenderers/ColoredLabel";
import { DefaultCellRenderer } from "@components/ListingTable/TableInstance/fieldRenderers/DefaultCellRenderer";
import QuickBookmarkStatusRenderer from "@components/ListingTable/TableInstance/fieldRenderers/QuickBookmarkStatusRenderer";
import { CveIdRenderer } from "@components/ListingTable/TableInstance/fieldRenderers/cve_id";
import { headerRenderer } from "@components/ListingTable/TableInstance/headerRenderer";
import { COLORS_CONFIGURATION } from "@configs/colors";
import { RecordsType } from "@features/listing";
import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { SerializedMandiantMalwareKey } from "@interfaces/SerializedMandiantMalware";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import useIntrospections from "@queries/useIntrospections";
import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { defaultColumnSizing, minColumnSizing } from "../ListingTableState";
import { cvesRenderersConfig } from "./cvesRenderersConfig";
import { malwaresRenderersConfig } from "./malwaresRenderersConfig";

function useCVEsColumnsConfiguration(visibleColumns: string[]): ColumnDef<FrontendSerializedThreat, any>[] {
  const columnHelper = createColumnHelper<FrontendSerializedThreat>();
  const {
    data: { fields: fieldsDefinition },
  } = useIntrospections();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return useMemo(() => {
    const configuration = visibleColumns.map(
      // @ts-expect-error[need investigation]
      (column_id: keyof FrontendSerializedThreat): ReturnType<typeof columnHelper.accessor> => {
        const hasCustomRenderer = !!cvesRenderersConfig[column_id];

        // fields with known options and color configuration available
        const isColorableByStringValue = !!(
          fieldsDefinition[column_id]?.constrains?.options?.length && COLORS_CONFIGURATION[column_id]
        );

        const enableSorting = fieldsDefinition[column_id]?.constrains?.sortable !== false;

        if (hasCustomRenderer || isColorableByStringValue) {
          return columnHelper.accessor((row) => row[column_id], {
            id: column_id,
            size: defaultColumnSizing[column_id] ?? undefined,
            minSize: minColumnSizing[column_id] ?? undefined,
            enableSorting,
            cell: hasCustomRenderer ? (cvesRenderersConfig[column_id] as any) : ColoredLabel,
            header: headerRenderer,
          });
        }

        return columnHelper.accessor((row) => row[column_id], {
          id: column_id,
          size: defaultColumnSizing[column_id],
          minSize: minColumnSizing[column_id],
          cell: DefaultCellRenderer,
          enableSorting,
          header: headerRenderer,
        });
      },
    );

    return [
      // Quick bookmark status, available for all views by default
      columnHelper.accessor((row: FrontendSerializedThreat) => row, {
        id: "quick_bookmark_status", // should not match the ID in threat since we need full threat in the context
        size: defaultColumnSizing.vip_favorite,
        enableResizing: false,
        enableSorting: false,
        cell: QuickBookmarkStatusRenderer,
        header: () => <span />,
      }),
      // vip_id is always visible
      columnHelper.accessor((row) => row, {
        id: "vip_id",
        size: defaultColumnSizing.vip_id,
        minSize: minColumnSizing.vip_id,
        enableSorting: true,
        cell: CveIdRenderer,
        header: headerRenderer,
      }),
      ...configuration,
    ];
  }, [visibleColumns]);
}

function useMandiantMalwareColumnsConfiguration(visibleColumns: string[]): ColumnDef<SerializedMandiantMalware, any>[] {
  const columnHelper = createColumnHelper<SerializedMandiantMalware>();
  return useMemo(() => {
    const configuration = visibleColumns.map(
      // @ts-expect-error[need investigation]
      (column_id: SerializedMandiantMalwareKey): ReturnType<typeof columnHelper.accessor> => {
        const hasCustomRenderer = !!malwaresRenderersConfig[column_id];

        // @ts-expect-error[need investigation]
        return columnHelper.accessor((row) => row[column_id], {
          id: column_id,
          cell: hasCustomRenderer ? (malwaresRenderersConfig[column_id] as any) : DefaultCellRenderer,
          enableSorting: false,
          header: headerRenderer,
        });
      },
    );

    return [...configuration];
  }, [visibleColumns, columnHelper.accessor]);
}

function useColumnConfiguration(
  visibleColumns: any[],
  recordsType: RecordsType,
): ColumnDef<FrontendSerializedThreat, any>[] | ColumnDef<SerializedMandiantMalware, any>[] {
  const columnsConfiguration = useCVEsColumnsConfiguration(visibleColumns);
  const malwareColumnsConfiguration = useMandiantMalwareColumnsConfiguration(visibleColumns);

  switch (recordsType) {
    case RecordsType.Malware:
      return malwareColumnsConfiguration;
    default:
      return columnsConfiguration;
  }
}

export { useColumnConfiguration };
