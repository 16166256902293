import * as ScrollArea from "@radix-ui/react-scroll-area";
import classNames from "classnames";
import { type ReactNode, forwardRef } from "react";

interface ResponsiveScrollAreaProps {
  children: ReactNode;
  rootClassName?: string;
  orientation?: "vertical" | "horizontal" | "both";
}

const ResponsiveScrollArea = forwardRef<HTMLDivElement, ResponsiveScrollAreaProps>(
  ({ children, rootClassName, orientation = "both" }, ref) => {
    return (
      <ScrollArea.Root
        ref={ref}
        className={classNames("border-b border-gray-300", "overflow-hidden h-full", rootClassName)}
      >
        <ScrollArea.Viewport className={"relative h-full w-full"}>
          <div className="absolute inset-0">{children}</div>
        </ScrollArea.Viewport>
        {(orientation === "vertical" || orientation === "both") && (
          <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        )}
        {(orientation === "horizontal" || orientation === "both") && (
          <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        )}
        <ScrollArea.Corner className="ScrollAreaCorner" />
      </ScrollArea.Root>
    );
  },
);

ResponsiveScrollArea.displayName = "ResponsiveScrollArea";

export { ResponsiveScrollArea };