import { useCallback, useEffect } from "react";
import { debounce } from "remeda";

const SIZING_STYLE = ["boxSizing", "width", "fontSize", "lineHeight", "padding", "border", "wordBreak"] as const;

function getSizingData(node: HTMLTextAreaElement) {
  const style = window.getComputedStyle(node);
  return {
    boxSizing: style.boxSizing,
    padding: Number.parseInt(style.paddingTop, 10) + Number.parseInt(style.paddingBottom, 10),
    border: Number.parseInt(style.borderTopWidth, 10) + Number.parseInt(style.borderBottomWidth, 10),
  };
}

let hiddenTextarea: HTMLTextAreaElement | null = null;

export function useAutoHeight({
  value,
  placeholder,
  inputRef,
}: {
  value: string;
  placeholder?: string;
  inputRef: React.RefObject<HTMLTextAreaElement>;
}) {
  const calculateHeight = useCallback(() => {
    const textarea = inputRef.current;
    if (!textarea) {
      return;
    }

    if (!hiddenTextarea) {
      hiddenTextarea = document.createElement("textarea");
      hiddenTextarea.setAttribute("aria-hidden", "true");
      hiddenTextarea.setAttribute("tab-index", "-1");
      Object.assign(hiddenTextarea.style, {
        "min-height": "0",
        "max-height": "none",
        height: "0",
        visibility: "hidden",
        overflow: "hidden",
        position: "absolute",
        "z-index": "-1000",
        top: "0",
        right: "0",
      });
      document.body.appendChild(hiddenTextarea);
    }

    const style = window.getComputedStyle(textarea);
    for (const key of SIZING_STYLE) {
      hiddenTextarea!.style[key] = style[key];
    }

    const { boxSizing, padding, border } = getSizingData(textarea);
    hiddenTextarea.value = value || placeholder || "x";

    let height = hiddenTextarea.scrollHeight;
    height = boxSizing === "border-box" ? height + border : height - padding;

    const minHeight = 32;
    const maxHeight = 208;
    height = Math.max(minHeight, Math.min(height, maxHeight));

    if (textarea.style.height !== `${height}px`) {
      textarea.style.height = `${height}px`;
    }

    textarea.style.overflowY = height <= maxHeight ? "hidden" : "auto";
  }, [value, placeholder, inputRef]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight]);

  useEffect(() => {
    const handleResize = debounce(calculateHeight, { maxWaitMs: 100 });
    window.addEventListener("resize", handleResize.call);
    return () => window.removeEventListener("resize", handleResize.call);
  }, [calculateHeight]);

  return calculateHeight;
}
