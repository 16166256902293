import type { FieldReferenceState } from "@features/field_references/fieldReferences.state";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import AsyncMenuSuggestion from "../AsyncMenuSuggestion";
import DatePickerSuggestion from "../DatePickerSuggestion";
import type QLContext from "../QLContext";
import { CONTEXT_SCOPE } from "../QLContext";
import { Suggestion } from "../Suggestion";
import { FIELD_TYPE } from "../utils";
import { PER_FIELD_SUGGESTIONS_CONFIG, SUGGESTIONS_CONFIG } from "./FieldModel.config";

class FieldModel implements FieldReferenceState {
  id: ThreatKey;
  type!: FIELD_TYPE;
  nullable = false;
  name?: string;
  autocomplete?: boolean;
  searchable?: boolean;
  priority?: number;
  description?: string;
  possible_values?: string[];

  textarea: HTMLTextAreaElement;

  constructor(id: ThreatKey, fieldReference: FieldReferenceState, textarea: HTMLTextAreaElement) {
    this.id = id;
    this.textarea = textarea;
    Object.assign(this, fieldReference);
  }

  getFieldOptions(_context: QLContext): Suggestion[] {
    let fieldOptions: string[] = [];

    if (this.possible_values?.length && Array.isArray(this.possible_values)) {
      fieldOptions = this.possible_values;

      return fieldOptions.map((text) => {
        return new Suggestion({
          text,
          snippetBefore: '"',
          snippetAfter: '" ',
          textarea: this.textarea,
        });
      });
    }

    if (this?.autocomplete) {
      const value = _context?.currentFullToken?.value;
      return [
        new AsyncMenuSuggestion({
          context: _context,
          url: `/api/v1/autocomplete/${this.id}${value ? `/${value}/` : "/"}`,
          textarea: this.textarea,
        }),
      ];
    }

    return [];
  }

  hasOptions(_context: QLContext) {
    return this.possible_values?.length || this?.autocomplete;
  }

  getOperatorSuggestionsConfigs() {
    if (typeof this.id === "string" && PER_FIELD_SUGGESTIONS_CONFIG[this.id]) {
      return PER_FIELD_SUGGESTIONS_CONFIG[this.id] || [];
    }

    return SUGGESTIONS_CONFIG[this.type] || [];
  }

  getSuggestions(context: QLContext): Suggestion[] {
    let suggestions: Suggestion[] = [];
    if (context.scope === CONTEXT_SCOPE.COMPARISON) {
      suggestions = [
        ...this.getOperatorSuggestionsConfigs()
          .filter((item) => {
            // use "starts with" search filter instead of default
            // See http://stackoverflow.com/a/4579228
            return item.text?.lastIndexOf(context.prefix, 0) === 0;
          })
          .map((conf) => {
            return new Suggestion({
              ...conf,
              textarea: this.textarea,
            });
          }),
      ];
    } else if (context.scope === CONTEXT_SCOPE.VALUE) {
      if (this.hasOptions(context)) {
        suggestions = this.getFieldOptions(context);
      } else if (
        this.type === FIELD_TYPE.BOOLEAN ||
        this.type === FIELD_TYPE.FAVORITE ||
        this.type === FIELD_TYPE.MONITORED ||
        this.type === FIELD_TYPE.COMPUTED
      ) {
        suggestions = [
          new Suggestion({
            text: "True",
            snippetAfter: " ",
            textarea: this.textarea,
          }),
          new Suggestion({
            text: "False",
            snippetAfter: " ",
            textarea: this.textarea,
          }),
        ];

        // VIP-77: for fields with display_type bool, we should not show "None" option
        if (this.nullable && this.type === FIELD_TYPE.BOOLEAN) {
          suggestions.push(
            new Suggestion({
              text: "None",
              snippetAfter: " ",
              textarea: this.textarea,
            }),
          );
        }
      } else if (this.type === FIELD_TYPE.JSON) {
        if (context.lastToken?.name === "IS") {
          suggestions.push(
            new Suggestion({
              text: "empty",
              snippetAfter: " ",
              textarea: this.textarea,
            }),
          );
          suggestions.push(
            new Suggestion({
              text: "not empty",
              snippetAfter: " ",
              textarea: this.textarea,
            }),
          );
        }
      } else if (this.type === FIELD_TYPE.DATETIME || this.type === FIELD_TYPE.DATE) {
        // show date picker only if no input yet is provided
        if (DatePickerSuggestion.shouldBeVisible(context)) {
          suggestions = [
            new DatePickerSuggestion({
              snippetAfter: ' "|"',
              type: "date",
              textarea: this.textarea,
            }),
          ];
        } else {
          suggestions = DatePickerSuggestion.generateDatestringSuggestions(context, this.textarea);
        }
      }
    }

    return suggestions;
  }
}

export default FieldModel;
