import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react/components/Dialog";
import { Typography } from "@material-tailwind/react/components/Typography";
import React from "react";

interface AlertDialogProps {
  Trigger: React.ElementType;
  title?: string;
  description?: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  triggerProps?: React.ComponentProps<React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>>;
  customBody?: React.ReactNode;
}

const AlertDialog = ({
  Trigger,
  title,
  description,
  isOpen = false,
  onOpenChange,
  children,
  triggerProps,
  customBody,
}: AlertDialogProps) => {
  const handleOpen = () => {
    if (onOpenChange) {
      onOpenChange(!isOpen);
    }
  };

  return (
    <React.Fragment>
      <Trigger {...triggerProps} onClick={handleOpen} />

      <Dialog open={isOpen} handler={handleOpen}>
        <DialogHeader>
          {title && (
            <Typography variant="h5" color="blue-gray">
              {title}
            </Typography>
          )}
        </DialogHeader>

        <DialogBody divider className="space-y-2">
          {description ? <Typography className="text-gray-700 dark:text-gray-400">{description}</Typography> : null}

          {customBody ? customBody : null}
        </DialogBody>

        <DialogFooter>
          {/* Actions should be direct children without additional wrappers */}
          {children}
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};

export { AlertDialog };
