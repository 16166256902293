import autoFixSearchQuery from "@components/DjangoQL/autoFixSearchQuery";
import * as Sentry from "@sentry/react";
import { type Query, type QueryKey, type UseSuspenseQueryOptions, useQueryClient } from "@tanstack/react-query";
import { transformSearchQuery_silent } from "@utils/searchQueryToUrl";
import useFetchMutation from "@utils/useFetchMutation";
import { colord } from "colord";
import { isEqual } from "lodash-es";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { proxy, useSnapshot } from "valtio";
import { SerializedRiskLevelModel } from "./riskLevels/SerializedRiskLevelModel";
import type { SerializedRiskLevel, SerializedRiskLevelPost } from "./riskLevels/interfaces";
import { generatePriorityColors } from "./riskLevels/prioritiesColors";
import { useMutateRiskLevels } from "./riskLevels/queries/useMutateRiskLevels";
import {
  organizationRiskLevelsUrlEndpoint,
  riskLevelsChangesQueryKey,
  riskLevelsQueryKey,
  useRiskLevelsQuery,
} from "./riskLevels/queries/useRiskLevelsQuery";

// Valtio state for managing mode
type RiskLevelState = {
  mode: "read" | "write";
  riskLevels: SerializedRiskLevelModel[];
  originalRiskLevels: SerializedRiskLevel[];
  hasUnsavedChanges: boolean;
  isLoading: boolean;
  error: Error | null;
  requestedComputingAt?: DateTime;
  isDefault: boolean;
};

export const _riskLevelState = proxy<RiskLevelState>({
  mode: "read",
  riskLevels: [],
  originalRiskLevels: [],
  hasUnsavedChanges: false,
  isLoading: false,
  error: null,
  requestedComputingAt: undefined,
  isDefault: false,
});

function useRiskLevelsStateSnapshot() {
  return useSnapshot(_riskLevelState);
}

// Transforming the risk levels data into a format that is ready to be used in the UI and API
export function RiskLevelsFactory(data: (SerializedRiskLevel | SerializedRiskLevelModel)[] = []) {
  return [
    ...data.map((riskLevel, index) => {
      // if we dont have color(default risk levels), we autogenerate it
      const color = riskLevel.color ?? autogenerateSeverityColor(riskLevel.priority, data.length);

      // if we dont have transformed_search, we autogenerate it
      let transformed_search = riskLevel.transformed_search;

      if (!transformed_search) {
        const autoFixedSearchQuery = autoFixSearchQuery(riskLevel.search);

        const transformedQueryResult = transformSearchQuery_silent(autoFixedSearchQuery);
        if (transformedQueryResult) {
          transformed_search = transformedQueryResult;
        } else {
          // Log sentry error to track that down
          Sentry.captureException(new Error(`Invalid search query in a RiskLevelsFactory: ${riskLevel.search}`));
        }
      }

      if (transformed_search === "") {
        // This should not happen, since its validated elsewhere, but just in case.
        throw new Error("Invalid search query");
      }

      return new SerializedRiskLevelModel({
        ...riskLevel,
        priority: index + 1,
        color,
        transformed_search,
      });
    }),
  ];
}

function useReorderRiskLevels() {
  const riskLevels = useSnapshot(_riskLevelState.riskLevels);
  return useCallback(
    (uuid1: string, uuid2: string) => {
      const riskLevelsCopy = [...riskLevels];
      const riskLevel1Index = riskLevelsCopy.findIndex((riskLevel) => riskLevel.uuid === uuid1);
      const riskLevel2Index = riskLevelsCopy.findIndex((riskLevel) => riskLevel.uuid === uuid2);

      if (riskLevel1Index !== -1 && riskLevel2Index !== -1) {
        const riskLevel1 = riskLevelsCopy[riskLevel1Index];
        riskLevelsCopy.splice(riskLevel1Index, 1);
        riskLevelsCopy.splice(riskLevel2Index, 0, riskLevel1!);

        const updatedRiskLevels = RiskLevelsFactory(riskLevelsCopy);
        _riskLevelState.riskLevels = updatedRiskLevels;
        _riskLevelState.hasUnsavedChanges = true;
      }
    },
    [riskLevels],
  );
}

function useDeleteRiskLevel() {
  const riskLevels = useSnapshot(_riskLevelState.riskLevels);
  return useCallback(
    (uuid: string) => {
      _riskLevelState.hasUnsavedChanges = true;
      _riskLevelState.riskLevels = RiskLevelsFactory(riskLevels.filter((riskLevel) => riskLevel.uuid !== uuid));
    },
    [riskLevels],
  );
}

function useSaveRiskLevels() {
  const { mutateAsync } = useMutateRiskLevels();
  const queryClient = useQueryClient();
  return useCallback(
    async (riskLevels: SerializedRiskLevelPost[], keepManualRiskLevels: boolean) => {
      await mutateAsync({
        riskLevels,
        keep_manual_risk_levels: keepManualRiskLevels,
      });

      _riskLevelState.hasUnsavedChanges = false;
      _riskLevelState.mode = "read";
      _riskLevelState.requestedComputingAt = DateTime.now();

      queryClient.invalidateQueries({ queryKey: riskLevelsChangesQueryKey });
    },
    [mutateAsync, queryClient],
  );
}

function useResetRiskLevelsChanges() {
  const originalRiskLevels = useSnapshot(_riskLevelState.originalRiskLevels);
  return useCallback(() => {
    _riskLevelState.riskLevels = RiskLevelsFactory(originalRiskLevels);

    _riskLevelState.hasUnsavedChanges = false;
    _riskLevelState.mode = "read";
  }, [originalRiskLevels]);
}

const useRiskLevelMode = () => {
  const state = useRiskLevelsStateSnapshot();
  return {
    mode: state.mode,
    toggleMode: () => {
      _riskLevelState.mode = state.mode === "read" ? "write" : "read";
    },
  };
};

function useIsWriteMode() {
  const snapshot = useRiskLevelMode();
  return snapshot.mode === "write";
}

// Syncs with react-query data
function RiskLevelsDataSync() {
  const riskLevelStateSnap = useRiskLevelsStateSnapshot();
  const { data, isLoading, error } = useRiskLevelsQuery({
    refetchInterval: (query: Query<SerializedRiskLevel[]>) => {
      const data = query.state.data;
      if (data?.some((riskLevel) => riskLevel.count === undefined)) {
        return 5000;
      }

      return false;
    },
  });

  useEffect(() => {
    if (data?.length && !isEqual(riskLevelStateSnap.originalRiskLevels, data)) {
      _riskLevelState.riskLevels = RiskLevelsFactory(data);
      _riskLevelState.originalRiskLevels = data;
      _riskLevelState.isDefault = data.some((riskLevel) => riskLevel.is_default);
    }
  }, [data, riskLevelStateSnap.originalRiskLevels]);

  useEffect(() => {
    if (riskLevelStateSnap.isLoading !== isLoading) {
      _riskLevelState.isLoading = isLoading;
    }

    if (error !== riskLevelStateSnap.error) {
      _riskLevelState.error = error;
    }
  }, [isLoading, error, riskLevelStateSnap]);

  return null;
}

function autogenerateSeverityColor(severity: number, maxSeverity: number) {
  if (severity < 1 || severity > maxSeverity) {
    throw new Error("Priority must be between 1 and the max severity");
  }

  const priorityColors = generatePriorityColors(maxSeverity);
  return priorityColors[severity - 1];
}

function getLabelTextColor(color?: string) {
  if (!color) {
    return "text-gray-700";
  }

  return colord(color).brightness() < 0.65 ? "text-white" : "text-gray-700";
}

function useVipRiskLevelsColors() {
  const { data, isLoading, isError } = useRiskLevelsQuery({
    staleTime: 30 * 1000,
  });

  const riskLevelColors = useMemo(() => {
    if (!data) {
      return new Map();
    }

    return new Map(
      data.map((riskLevel) => {
        const color = riskLevel.color ?? autogenerateSeverityColor(riskLevel.priority, data.length);
        const textColor = getLabelTextColor(color!);
        return [
          riskLevel.risk_level,
          {
            riskLevel,
            colorClass: [color, textColor],
          },
        ];
      }),
    );
  }, [data]);

  return { riskLevelColors, isLoading, isError };
}

function useVipRiskColor(value: string) {
  const { riskLevelColors, isLoading, isError } = useVipRiskLevelsColors();

  const { colorClass, matchingRiskLevel } = useMemo(() => {
    if (!value || !riskLevelColors.has(value)) {
      return {
        colorClass: ["", "text-gray-500"],
        matchingRiskLevel: undefined,
      };
    }

    const riskLevelData = riskLevelColors.get(value);
    return {
      colorClass: riskLevelData.colorClass,
      matchingRiskLevel: riskLevelData.riskLevel,
    };
  }, [riskLevelColors, value]);

  return { isLoading, isError, colorClass, matchingRiskLevel };
}

function useRiskLevelsModels(
  options: Partial<UseSuspenseQueryOptions<SerializedRiskLevel[], Error, SerializedRiskLevel[], QueryKey>>,
) {
  const { data, isError, isLoading } = useRiskLevelsQuery(options);

  return {
    riskLevels: RiskLevelsFactory(data),
    isError,
    isLoading,
  };
}

function useChangeThreatRiskLevel(cve_threat_intel_id: number) {
  return useFetchMutation(
    `/api/v1/threats/${cve_threat_intel_id.toString()}/risk_level/`,
    (riskLevelValue: string) => {
      return {
        risk_level: riskLevelValue,
      };
    },
    {},
    "PATCH",
  );
}

function useResetRiskLevels() {
  const queryClient = useQueryClient();
  return useFetchMutation<void, Error, void, void>(
    organizationRiskLevelsUrlEndpoint,
    {},
    {
      onSuccess: () => {
        // risk levels are resetted on backend, so we need to force-sync this
        queryClient.invalidateQueries({ queryKey: riskLevelsQueryKey });
        _riskLevelState.originalRiskLevels = []; // this will force sync to resync
        _riskLevelState.hasUnsavedChanges = false; // back to read mode
        _riskLevelState.mode = "read";
      },
    },
    "DELETE",
  );
}

export {
  RiskLevelsDataSync,
  SerializedRiskLevelModel,
  useRiskLevelsStateSnapshot,
  useReorderRiskLevels,
  useDeleteRiskLevel,
  useRiskLevelMode,
  useResetRiskLevelsChanges,
  useSaveRiskLevels,
  useIsWriteMode,
  autogenerateSeverityColor,
  getLabelTextColor,
  useVipRiskColor,
  useRiskLevelsModels,
  useChangeThreatRiskLevel,
  useResetRiskLevels,
  useVipRiskLevelsColors,
};

export type { RiskLevelState, SerializedRiskLevel };
