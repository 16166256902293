import { useIsFetching } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { pipe } from "remeda";

interface LoadingBarProps {
  className?: string;
}

function LoadingBar({ className = "w-full h-1 bg-nucleus animate-pulse" }: LoadingBarProps) {
  return <div className={className} />;
}

const minLoadingTime = 600;
function createLoadingTimer(callback: () => void): () => void {
  const timer = setTimeout(callback, minLoadingTime);
  return () => clearTimeout(timer);
}

export function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const cleanup = pipe(isFetching, (isFetching) => {
      if (isFetching) {
        setShouldShow(true);
        return () => {};
      }
      return createLoadingTimer(() => setShouldShow(false));
    });

    return cleanup;
  }, [isFetching]);

  if (!shouldShow) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 top-0 z-50">
      <LoadingBar />
    </div>
  );
}