import classNames from "classnames";
import type React from "react";
import { useCallback, useEffect, useState } from "react";

import { useAutoHeight } from "./SearchQueryTextArea/useAutoHeight";
import { useCVEPaste } from "./SearchQueryTextArea/useCVEPaste";

type SearchQueryTextAreaCustomClassNames = {
  textarea?: string;
  ctaButtonIcon?: string;
};

type SearchQueryTextAreaProps = Readonly<{
  isValidParsing: boolean;
  value: string;
  cursorPosition: number | null;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  onChange: (value: string, cursorPos: number) => void;
  placeholder?: string;
  name?: string;
  customClassNames?: SearchQueryTextAreaCustomClassNames;
}>;


// Having textarea in a separate component to hold innerState for textarea, which allows us to not rerender cursor position on every valtion value change as well as use React.useEffect to update local state when valtio state changes from other sources
export function SearchQueryTextArea({
  isValidParsing,
  value,
  cursorPosition,
  inputRef,
  onChange,
  placeholder,
  name,
  customClassNames = {},
}: SearchQueryTextAreaProps) {
  const [innerValue, setInnerValue] = useState(value);
  useAutoHeight({ value: innerValue, placeholder, inputRef });

  const onTextAreaValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textValue = e.target.value;
    setInnerValue(textValue);
    onChange(textValue, e.target.selectionStart);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <>
  const onPaste = useCallback(
    (newValue: string, pastePosition: number) => {
      setInnerValue((innerValue) => innerValue.slice(0, pastePosition) + newValue + innerValue.slice(pastePosition));
      onChange(
        innerValue.slice(0, pastePosition) + newValue + innerValue.slice(pastePosition),
        pastePosition + newValue.length,
      );
    },
    [innerValue],
  );

  const { handlePaste, DialogComponent } = useCVEPaste({
    onValueChange: onPaste,
  });


  // Update inner state when valtio state changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: <honestly afraid to touch this>
  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);

      if (inputRef.current && cursorPosition) {
        // set cursor position to the one from state with a delay, since it's not possible to set cursor position immediately after value change
        setTimeout(() => {
          if (!inputRef.current) {
            return;
          }

          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
      }
    }
  }, [value]);

  return (
    <>
      <textarea
        className={classNames(
          // Border styling
          "border border-gray-400 focus:border-gray-600 rounded-b rounded-t",
          {
            "border-orange-500": !isValidParsing,
          },
          // Max height and overflow
          "max-h-52",
          // Base styles
          "w-full outline-none text-sm !self-center",
          // Default padding and sizing
          "pt-2 pl-2 pb-2 pr-6",
          // Scrollbar styling
          "scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full",
          // Placeholder styling, also add padding left to keep it aligned with the placeholder icon
          "placeholder:text-gray-500",
          // Custom classes passed from parent
          customClassNames.textarea,
        )}
        placeholder={placeholder ?? "Search..."}
        style={{
          resize: "none",
        }}
        onChange={onTextAreaValueChange}
        onPaste={handlePaste}
        cols={60}
        rows={1}
        value={innerValue}
        ref={inputRef}
        spellCheck={false}
        name={name}
        id={name}
      />
      <DialogComponent />
    </>
  );
}

export type { SearchQueryTextAreaCustomClassNames };
