export const FEEDS_LABELS_MAPPING = {
  cisa: "CISA KEV",
  epss: "EPSS",
  nvd: "NVD",
  greynoise: "GreyNoise",
  intel471: "Intel 471",
  mandiant: "Mandiant",
  defender: "Microsoft Defender",
  rf: "Recorded Future",
  zdi: "Zeroday Initiative",
  vip: "Nucleus VIP",
  nucleus: "Nucleus VIP",
  project_zero: "Google Project Zero",
  vulncheck: "VulnCheck Community",
} as const;

export type Feed = keyof typeof FEEDS_LABELS_MAPPING | (string & {});
