import flatpickr from "flatpickr";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";

import { TOKEN_TYPE } from "@utils/Lexer";

import type QLContext from "./QLContext";
import { Suggestion, type SuggestionProps } from "./Suggestion";

export interface DatePickerSuggestionProps extends SuggestionProps {}

const CONFIRM_CLASSNAME = "flatpickr-confirm";
class DatePickerSuggestion extends Suggestion {
  datepicker: flatpickr.Instance;
  boundHandleConfirmButtonClick: (e: Event) => void;

  createInstance = (_id: string, context: QLContext) => {
    // create new hidden input for datepicker and append it to the QLInput
    const datepickerElement = document.createElement("input");
    // we cannot hide it in css using display or visiblity, since datepicker wont work then, but we need to fully visually make it invisible
    datepickerElement.style.position = "absolute";
    datepickerElement.style.opacity = "0";
    datepickerElement.style.top = `${this.textarea.scrollHeight}px`;
    datepickerElement.style.left = "-1px";
    datepickerElement.style.width = "1px";
    datepickerElement.style.height = "1px";

    this.textarea.appendChild(datepickerElement);

    const datepicker = flatpickr(datepickerElement, {
      enableTime: true,
      plugins: [
        confirmDatePlugin({
          confirmText: "Confirm date selection",
          confirmIcon: "",
        }),
      ],
      dateFormat: "Y-m-d H:i", // YYYY-MM-DD HH:MM
      onClose: () => {
        // remove datepicker element
        console.log("onClose datepicker");
        this.datepicker.destroy();
      },
    });

    this.datepicker = datepicker;

    datepickerElement.focus();
    datepicker.open(undefined, this.textarea);

    // Add confirm button behavior
    if (datepickerElement.parentNode !== null) {
      const confirmButton = document.querySelector(`.${CONFIRM_CLASSNAME}`);

      if (confirmButton === null) {
        return null;
      }

      const boundHandleConfirmButtonClick = (e: Event) => {
        // Prevent default behavior of the button and stop propagation to not close the widget
        e.preventDefault();
        e.stopPropagation();

        this.handleConfirmButtonClick(e, context);
        return false;
      };

      // Store the bound function in the instance so you can remove the event listener later
      this.boundHandleConfirmButtonClick = boundHandleConfirmButtonClick;

      confirmButton.addEventListener("click", boundHandleConfirmButtonClick);
    }

    return null;
  };

  handleConfirmButtonClick = (e: Event, context: QLContext) => {
    // If we have datepicker suggestion, then we need to update its snippetAfter and select it
    this.snippetAfter = ` "${this.datepicker.input.value}"`;

    this.datepicker.close();
    // Trigger click on current suggestion in order to trigger onSelect further
    this.onSelect(context);

    // Remove datepicker element
    this.datepicker.destroy();
  };

  // static method to figure out if datepicker instanse should be visible
  static shouldBeVisible = (context: QLContext) => {
    if (context.lastToken?.name && ["WITHIN", "MORE_THAN", "WITHIN_NEXT"].includes(context.lastToken.name)) {
      return false;
    }

    return context.lastToken?.type === TOKEN_TYPE.OPERATOR;
  };

  destroy = () => {
    if (this.datepicker !== undefined) {
      // Remove datepicker element from the DOM
      const datepickerElement = this.datepicker.input;
      if (datepickerElement?.parentNode) {
        datepickerElement.parentNode.removeChild(datepickerElement);
      }

      // Remove click event listener from confirmButton
      const confirmButton = document.querySelector(`.${CONFIRM_CLASSNAME}`);
      if (confirmButton !== null) {
        confirmButton.removeEventListener("click", this.boundHandleConfirmButtonClick);
      }

      // Destroy the datepicker instance
      this.datepicker.destroy();
    }
  };

  /**
   * If no datepicker yet shown, we have plenty of usecases
   * I want to be able to enter a “int string(choice)” representing the following:
   * x hours
   * x days
   * x weeks
   * x months
   * x years,
   * receive suggestions for next possible input, as well as receive final suggestion when datestring is correct
   */
  static generateDatestringSuggestions(context: QLContext, textarea: HTMLTextAreaElement) {
    const suggestions: Suggestion[] = [];

    // next, we got a number input, which is shown in prefix like "3" for example, we suggest next wording(hours, days, weeks, months, years), also could be a space after number "3 "
    const matchedNumber = context.prefix.match(/\d+ ?/);
    if (matchedNumber) {
      const number = matchedNumber[0].replace(/ /g, "");

      const timeUnits = ["hour", "day", "week", "month", "year"];
      for (const word of timeUnits) {
        // make it plural if needed
        const wordWithPlural = number === "1" ? word : `${word}s`;
        let text: string;

        switch (context.lastToken?.name) {
          case "MORE_THAN":
            text = `${number} ${wordWithPlural} ago`;
            break;
          case "WITHIN_NEXT":
            text = `${number} ${wordWithPlural}`;
            break;
          default:
            text = `the last ${number} ${wordWithPlural}`;
        }

        suggestions.push(
          new Suggestion({
            // based on context.prefix and already typed word
            value: `${number} ${wordWithPlural}`,
            // we base this on operator which is used with this suggestion
            //  < means “within” and > means “more than x ago”
            text: text,
            snippetBefore: '"',
            snippetAfter: '" ',
            type: "text",
            textarea: textarea,
          }),
        );
      }
    } else if (context.prefix === '"' || context.prefix === "") {
      suggestions.push(
        new Suggestion({
          text: "Start typing a duration (e.g., '3 days').",
          isDisabled: true,
          className: "text-gray-500",
          textarea: textarea,
        }),
      );
    }

    return suggestions;
  }
}

export default DatePickerSuggestion;
