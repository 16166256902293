import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, SecurinSource } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_SOURCES_TO = 10;
const SecurinSourcesRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const values = safeParseJSONtoArray<SecurinSource>(context.getValue());

  if (!Array.isArray(values)) {
    return <>-</>;
  }

  const renderSource = (fixUrl: SecurinSource, index: number) => (
    <a
      key={`securin_source_${index}`}
      className="flex-start ease-nav-brand text-slate-600 hover:text-blue-700 flex font-semibold transition-all"
      href={fixUrl.url}
      target="_blank"
      rel="noreferrer"
    >
      <span>
        {fixUrl.name}
        <i className="fas fa-external-link-alt inline ml-1 text-xs" />
      </span>
    </a>
  );

  const fullView = <div>{values.map(renderSource)}</div>;

  if (values.length > LIMIT_SOURCES_TO) {
    return (
      <div>
        <div>{values.slice(0, LIMIT_SOURCES_TO).map((source, index) => renderSource(source, index))}</div>
        <Tooltip value={fullView}>
          <div className="cursor-progress mt-2 text-xs text-right text-gray-600">
            And {values.length - LIMIT_SOURCES_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export default SecurinSourcesRenderer;
