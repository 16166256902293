import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

const SecurinAffectedIndustriesRenderer: (
  context: CellContext<FrontendSerializedThreat, FrontendSerializedThreat["securin_affected_industries"]>,
) => JSX.Element = (context) => {
  const value = context.getValue(); // like "[\"Water and Wastewater\",\"Critical Manufacturing\",\"Energy\"]"

  const parsedValue = isArrayLikeString(value) ? safeParseJSONtoArray(value) : [];

  const displayValue = parsedValue?.join(", ") || "None";

  return <div>{displayValue}</div>;
};

export default SecurinAffectedIndustriesRenderer;
