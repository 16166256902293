import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

// Limiting text size to calculated ~3 lines of text, showing tooltip on hover
const LongTextBox: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = context.getValue();

  if (!value) {
    return <span className="text-gray-400">-</span>;
  }

  const textClass = "text-sm text-gray-500";

  // determine 3 lines of text length, assuming that there are no line breaks
  const cellSize = context.column.getSize(); // width in pixels
  const averageLines = 3;
  const slicingThreshold = averageLines * Math.floor((cellSize - 30) / 6.5); // 6.5 is the average width of a character in pixels
  if (value.length > slicingThreshold) {
    return (
      <Tooltip value={<div className={textClass}>{value}</div>}>
        <div className={textClass}>{`${value.slice(0, slicingThreshold)}...`}</div>
      </Tooltip>
    );
  }

  return <div className={textClass}>{value}</div>;
};

export default LongTextBox;
