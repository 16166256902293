import { useCreateToast } from "@hooks/useCreateToast";
import { useCallback, useEffect, useState } from "react";
import type { ActionMeta, OnChangeValue } from "react-select";
import { debounce } from "remeda";
import useQuickStart from "./quickStart";
import type { TagSelectOption, TagWithPermissions } from "./tags/tags.interfaces";
import { fetchTags, useMutateTag } from "./tags/tags.query";
import { useTagPermissions } from "./tags/useTagPermissions";

interface UseTagsControlOptions {
  initialTags: TagWithPermissions[];
  // threatId states:
  // - number: when working with a specific threat's tags
  // - null: when working with saved search tags (limited functionality)
  // - undefined: initial empty state before initialization
  threatId?: number | undefined | null;
  isReady?: boolean;
}

interface UseTagsControlReturn {
  isSaving: boolean;
  hasChanges: boolean;
  selectedTags: TagSelectOption[];
  loadTagOptions: (inputValue: string, callback: (options: TagSelectOption[]) => void) => void;
  handleTagChange: (newValue: OnChangeValue<TagSelectOption, true>, actionMeta: ActionMeta<TagSelectOption>) => void;
  handleTagCreate: (inputValue: string) => void;
  handleSave: () => Promise<void>;
}

const createOptionFromTag = (tag: TagWithPermissions): TagSelectOption => ({
  value: tag.id ? tag.id.toString() : tag.value,
  label: tag.value,
  isEditable: !!tag.isEditable,
  isGlobal: tag.is_global,
});

export function useTagsControl({
  initialTags = [],
  threatId,
  isReady = false,
}: UseTagsControlOptions): UseTagsControlReturn {
  const quickStart = useQuickStart();
  const { addMessage } = useCreateToast();
  const { enrichTagsWithPermissions, isReady: isReadyPermissions } = useTagPermissions();
  const [selectedOptions, setSelectedOptions] = useState<TagSelectOption[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const { mutateAsync: mutateTags, isPending: isSaving } = useMutateTag();
  // initializedForThreatId tracks the initialization state to prevent infinite rerenders:
  // - undefined: initial state or needs reinitialization
  // - number: tracks which threatId we've initialized for
  // - null: initialized for saved search scenario
  const [initializedForThreatId, setInitializedForThreatId] = useState<number | null>();

  // Initialize selected tags when ready
  useEffect(() => {
    // Only initialize when:
    // 1. Component is ready AND
    // 2. Not yet initialized (initializedForThreatId is undefined) AND
    // 3. We have a valid threatId (not undefined)
    if (isReady && initializedForThreatId === undefined && threatId !== undefined) {
      setSelectedOptions(initialTags.map(createOptionFromTag));
      setInitializedForThreatId(threatId);
    }
    // Reset if switching to a different threat:
    // Clear selections and mark as uninitialized to trigger re-initialization
    else if (isReady && threatId !== undefined && initializedForThreatId !== threatId) {
      setSelectedOptions([]);
      setInitializedForThreatId(undefined);
    }
  }, [isReady, initialTags, initializedForThreatId, threatId]);

  // Load and filter available tags
  const loadTagOptions = useCallback(
    (inputValue: string, callback: (options: TagSelectOption[]) => void) => {
      if (!isReadyPermissions) return;

      fetchTags(inputValue).then((tags) => {
        const options = tags.map((tag) => createOptionFromTag(enrichTagsWithPermissions([tag])[0]!));
        callback(options);
      });
    },
    [enrichTagsWithPermissions, isReadyPermissions],
  );

  // Debounced version of tag loading for better performance
  const debouncedLoadTags = debounce(loadTagOptions, {
    timing: "trailing",
    waitMs: 500,
  });

  // Sort tags with uneditable ones first
  const orderOptions = useCallback((values: readonly TagSelectOption[]) => {
    return [...values.filter((v) => !v.isEditable), ...values.filter((v) => v.isEditable)];
  }, []);

  // Handle tag selection changes
  const handleTagChange = useCallback(
    (newValue: OnChangeValue<TagSelectOption, true>, actionMeta: ActionMeta<TagSelectOption>) => {
      let updatedValue = newValue;

      switch (actionMeta.action) {
        case "remove-value":
        case "pop-value":
          if (!actionMeta.removedValue.isEditable) return;
          break;
        case "clear":
          updatedValue = selectedOptions.filter((v) => !v.isEditable);
          break;
        case "select-option":
          updatedValue = newValue.map((tag) =>
            actionMeta.option?.value === tag.value ? { ...tag, isEditable: true } : tag,
          );
          break;
      }

      setSelectedOptions(orderOptions(updatedValue));
      setHasChanges(true);
    },
    [selectedOptions, orderOptions],
  );

  // Handle creation of new tags
  const handleTagCreate = useCallback(
    (inputValue: string) => {
      mutateTags(
        {
          values: [inputValue],
          action: "create",
          ...(threatId ? { threat_id: threatId } : {}),
        },
        {
          onSuccess: (data) => {
            const newTag = createOptionFromTag({
              ...data,
              id: data.id!,
              value: data.value!,
              created_by: data.created_by!,
              created_at: data.created_at!,
              is_global: false, // we just created it, so it's not global
              isEditable: true, // we just created it, so it's editable
            });

            setSelectedOptions((prev) => [...prev, newTag]);
            quickStart.markNextStepAsCompleted("add_vuln_tag");
          },
          onError: (error: Error) => {
            addMessage({
              title: "Error!",
              content: `Failed to create tag: ${error.message}`,
              variant: "error",
              duration: 3000,
            });
          },
        },
      );
      setHasChanges(true);
    },
    [mutateTags, threatId, quickStart, addMessage],
  );

  // Save changes to tags
  const handleSave = useCallback(async () => {
    const addedTags = selectedOptions.filter((tag) => !initialTags.find((t) => t.value === tag.label));
    const removedTags = initialTags.filter((tag) => !selectedOptions.find((t) => t.label === tag.value));

    const promises: Promise<unknown>[] = [];

    if (threatId) {
      if (addedTags.length > 0) {
        promises.push(
          mutateTags({
            values: addedTags.map((t) => t.label),
            action: "assign",
            threat_id: threatId,
          }),
        );
      }

      if (removedTags.length > 0) {
        promises.push(
          mutateTags({
            values: removedTags.map((t) => t.value),
            action: "unassign",
            threat_id: threatId,
          }),
        );
      }
    } else if (addedTags.length > 0) {
      promises.push(
        mutateTags({
          values: addedTags.map((t) => t.label),
          action: "create",
        }),
      );
    }

    await Promise.all(promises);

    if (promises.length > 0) {
      addMessage({
        title: "Success!",
        content: "Tags updated successfully",
        variant: "success",
        duration: 2000,
      });
    }

    setHasChanges(false);
  }, [selectedOptions, initialTags, mutateTags, threatId, addMessage]);

  return {
    isSaving,
    hasChanges,
    selectedTags: selectedOptions,
    loadTagOptions: debouncedLoadTags.call,
    handleTagChange,
    handleTagCreate,
    handleSave,
  };
}
