import type React from "react";

import MalwareActors from "@components/ListingTable/TableInstance/fieldRenderers/malware_actors";
import MalwareAliases from "@components/ListingTable/TableInstance/fieldRenderers/malware_aliases";
import MalwareCapabilities from "@components/ListingTable/TableInstance/fieldRenderers/malware_capabilities";
import MalwareCves from "@components/ListingTable/TableInstance/fieldRenderers/malware_cves";
import MalwareIndustries from "@components/ListingTable/TableInstance/fieldRenderers/malware_industries";
import MalwareYara from "@components/ListingTable/TableInstance/fieldRenderers/malware_yara";
import MandiantMalwareId from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_malware_id";
import type { SerializedMandiantMalwareKey } from "@interfaces/SerializedMandiantMalware";

const malwaresRenderersConfig: Partial<Record<SerializedMandiantMalwareKey, React.ComponentType<any>>> = {
  mandiant_malware_id: MandiantMalwareId,
  aliases: MalwareAliases,
  actors: MalwareActors,
  cves: MalwareCves,
  capabilities: MalwareCapabilities,
  industries: MalwareIndustries,
  yara: MalwareYara,
};

export { malwaresRenderersConfig };
