import { useOrganizationConfiguration } from "@hooks/useOrganizationsConfiguration";

function useFeeds() {
  const { data } = useOrganizationConfiguration();

  const { feeds } = data || {};
  const { is_organization_admin, tier } = data || {};

  return {
    feeds: [...(feeds ?? [])],
    isReadOnly: !is_organization_admin || tier !== "PAID",
    isPremium: tier !== "FREE",
    readOnlyMessage:
      tier !== "PAID"
        ? "Your organization is not subscribed to any paid plan, consider upgrading to enable this feature."
        : "You don't have permissions to add new feeds, contact your organization admin to ask them to enable the feed.",
  };
}

export default useFeeds;
