import { DateFilter } from "@components/forms/DateFilter";
import { SelectionFilter } from "@components/tableElements/SelectionFilter";
import type { FieldReferencesFieldsState } from "@features/field_references/fieldReferences.state";
import { Button } from "@material-tailwind/react/components/Button";
import { Input } from "@material-tailwind/react/components/Input";
import { Option, Select } from "@material-tailwind/react/components/Select";
import { DateTime } from "luxon";
import type React from "react";
import { useEffect, useRef, useState } from "react";

interface FilterPopoverProps {
  fieldReferences: FieldReferencesFieldsState;
  onSubmit: (data: {
    field: string;
    dateOfChange: { operator: string; value: string | null };
    newValue: { operator: string; value: string };
    oldValue: { operator: string; value: string };
  }) => void;
  onClose: () => void;
  position: { top: number; left: number };
  initialData?: {
    field: string;
    dateOfChange: { operator: string; value: string | null };
    newValue: { operator: string; value: string };
    oldValue: { operator: string; value: string };
  };
}

const operators = ["=", ">", "<", ">=", "<=", "!="];

const FilterPopover: React.FC<FilterPopoverProps> = ({
  fieldReferences,
  onSubmit,
  onClose,
  position,
  initialData = {
    field: "",
    dateOfChange: { operator: ">", value: "" },
    newValue: { operator: "=", value: "" },
    oldValue: { operator: "=", value: "" },
  },
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  const [field, setField] = useState(initialData.field);
  const [date, setDate] = useState<Date | null>(
    initialData.dateOfChange.value
      ? DateTime.fromFormat(initialData.dateOfChange.value, "yyyy-MM-dd").toJSDate()
      : null,
  );
  const [dateOperator, setDateOperator] = useState(initialData.dateOfChange.operator);
  const [newValue, setNewValue] = useState(initialData.newValue);
  const [oldValue, setOldValue] = useState(initialData.oldValue);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        // Check if the click is inside a Flatpickr calendar
        const flatpickrCalendar = document.querySelector(".flatpickr-calendar");
        if (flatpickrCalendar?.contains(event.target as Node)) {
          return;
        }
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dateOfChange = {
      operator: dateOperator,
      value: date ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd") : null,
    };

    onSubmit({
      field,
      dateOfChange,
      newValue,
      oldValue,
    });
  };

  const fieldOptions = Object.values(fieldReferences)
    // Filter fields which are not tracked in changes
    .filter((field) => !field.id.startsWith("vip") && !field.id.startsWith("nucleus"))
    .map((field) => ({
      label: field.id,
      value: field.id,
    }));

  const onDateChange = (value: Date) => {
    setDate(value);
  };

  const handleFieldChange = (selectedFields: string) => {
    setField(selectedFields);
  };

  const hasAnyValue = newValue.value || oldValue.value || date;

  return (
    <div ref={popoverRef} className="bg-white p-4 rounded-lg shadow-lg absolute max-w-[480px] z-50" style={position}>
      <h3 className="text-base font-semibold mb-1">CVE Change History Search</h3>
      <p className="text-xs text-gray-600 mb-2">
        Select a field and specify how its value has changed to find relevant updates.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="field" className="block mb-1 text-xs font-medium text-gray-700">
            Changed CVE field
          </label>
          <SelectionFilter
            header=""
            accessorKey="field"
            options={fieldOptions}
            isMulti={false}
            filteredOptions={fieldOptions}
            currentValue={field ? [field] : []}
            updateFilterState={handleFieldChange}
            usePopover={false}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="date_operator" className="block mb-1 text-xs font-medium text-gray-700">
            Date of Change
          </label>
          <div className="flex flex-row space-x-2">
            <div className="flex flex-col space-y-2">
              <Select
                className="text-xs"
                size="md"
                value={dateOperator}
                onChange={(value) => setDateOperator(value as string)}
              >
                {operators.map((op) => (
                  <Option key={op} value={op}>
                    {op}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex-1">
              <DateFilter
                value={date}
                onChange={onDateChange}
                className="text-xs"
                mode="single"
                showResetButton={true}
              />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="new_value" className="block mb-1 text-xs font-medium text-gray-700">
            Changed to
          </label>
          <div className="flex items-center space-x-2 relative">
            <Select
              className="text-xs"
              size="md"
              value={newValue.operator}
              onChange={(value) => setNewValue({ ...newValue, operator: value as string })}
            >
              {operators.map((op) => (
                <Option key={op} value={op}>
                  {op}
                </Option>
              ))}
            </Select>
            <Input
              type="text"
              id="new_value"
              value={newValue.value}
              autoComplete="off"
              onChange={(e) => setNewValue({ ...newValue, value: e.target.value })}
            />
            {newValue.value && (
              <i
                className="fas fa-times text-xs cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setNewValue({ ...newValue, value: "" })}
              />
            )}
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="old_value" className="block mb-1 text-xs font-medium text-gray-700">
            Changed from
          </label>
          <div className="flex items-center space-x-2 relative">
            <Select
              className="text-xs"
              size="md"
              value={oldValue.operator}
              onChange={(value) => setOldValue({ ...oldValue, operator: value as string })}
            >
              {operators.map((op) => (
                <Option key={op} value={op}>
                  {op}
                </Option>
              ))}
            </Select>
            <Input
              type="text"
              id="old_value"
              value={oldValue.value}
              autoComplete="off"
              onChange={(e) => setOldValue({ ...oldValue, value: e.target.value })}
            />
            {oldValue.value && (
              <i
                className="fas fa-times text-xs cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setOldValue({ ...oldValue, value: "" })}
              />
            )}
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <Button type="button" onClick={onClose} variant="outlined" size="sm">
            Cancel
          </Button>
          <Button disabled={!hasAnyValue} type="submit" size="sm" color="blue">
            Apply
          </Button>
        </div>
      </form>
    </div>
  );
};

export { FilterPopover };
