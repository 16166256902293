import React from "react";

import type { FrontendSerializedThreat, SecurinWeakness } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 3;
const SecurinWeaknessesRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (
  context,
) => {
  const value = safeParseJSONtoArray<SecurinWeakness>(context.getValue());

  if (!value || !Array.isArray(value)) {
    return <>-</>;
  }

  const renderRecord = (record: SecurinWeakness, index: number) => (
    //   add comma after each record except the last one
    <span key={`securin_weakness_${index}`}>
      <span>{record.title}</span>
      {index !== value.length - 1 ? <span>, </span> : null}
    </span>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map(renderRecord)}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export default SecurinWeaknessesRenderer;
