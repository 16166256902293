import React from "react";

import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { CellContext } from "@tanstack/react-table";

/**
interface SerialinzedMandiantMalwareIndustry {
  id: string;
  name: string;
}
 */
const MalwareIndusties: (
  context: CellContext<SerializedMandiantMalware, SerializedMandiantMalware["industries"]>,
) => JSX.Element = (context) => {
  const capabilities = context.getValue();

  if (!Array.isArray(capabilities)) {
    return <>-</>;
  }

  return (
    <div className="flex flex-wrap">
      {capabilities.map((capability, index) => (
        <span key={`cve_${index}_${capability.name}`}>
          {capability.name}
          {index < capabilities.length - 1 ? ", " : ""}
        </span>
      ))}
    </div>
  );
};

export default MalwareIndusties;
