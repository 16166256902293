import type { ThreatKey } from "@interfaces/SerializedThreat";
import type { SerializedVulnerabilityFeed } from "@interfaces/SerializedVulnerabilityFeed";
import { proxy } from "valtio";
import type { CombinedFieldReference } from "./fieldReferences.interfaces";

class SourcesFieldsReferenceState {
  combinedData: CombinedFieldReference[] = [];
  feeds: SerializedVulnerabilityFeed[] = [];
  modifiedData: { [field: string]: CombinedFieldReference } = {};
  hasChanges = false; // todo: can be dropped
  errorData: Partial<Record<ThreatKey, Record<string, string[]>>> = {};
}

const sourcesFieldsReferenceState = proxy<SourcesFieldsReferenceState>(new SourcesFieldsReferenceState());

function setCombinedData(data: CombinedFieldReference[], hasChanges?: boolean) {
  sourcesFieldsReferenceState.combinedData = data;
  if (hasChanges !== undefined) {
    sourcesFieldsReferenceState.hasChanges = hasChanges;
  }
}

function updateModifiedData(field: string, data: CombinedFieldReference) {
  sourcesFieldsReferenceState.modifiedData[field] = data;
}

function setErrorData(data: Partial<Record<ThreatKey, Record<string, string[]>>>, hasChanges?: boolean) {
  sourcesFieldsReferenceState.errorData = data;

  if (hasChanges !== undefined) {
    sourcesFieldsReferenceState.hasChanges = hasChanges;
  }
}

function resetModifiedData() {
  sourcesFieldsReferenceState.modifiedData = {};
}

export { sourcesFieldsReferenceState, setCombinedData, updateModifiedData, setErrorData, resetModifiedData };
