import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, VulncheckKevXDBEntry } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 6;
const vulncheck_kev_xdb_entries: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (
  context,
) => {
  const value = safeParseJSONtoArray<VulncheckKevXDBEntry>(context.getValue());
  const textClass = "text-sm text-gray-500";

  if (!value || value?.length === 0) {
    return <div className={textClass}>-</div>;
  }

  const renderRecord = (record: VulncheckKevXDBEntry, index: number) => (
    <Tooltip
      key={`vulncheck_kev_xdb_entry_${index}`}
      value={
        <div className={textClass}>
          <div>Exploit Type: {record.exploit_type}</div>
          <div>Date Added: {record.date_added}</div>
          <div>XDB URL: {record.xdb_url}</div>
        </div>
      }
    >
      <div className="text-blue-800 font-normal cursor-pointer">
        <span className="text-xs">{record.xdb_id}</span>
        {index < value.length - 1 ? ", " : ""}
      </div>
    </Tooltip>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderRecord(source, index))}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export { vulncheck_kev_xdb_entries };
