import { FIELD_TYPE } from "@components/DjangoQL/utils";
import { getFieldReferencesFieldsSnapshot } from "@features/field_references/fieldReferences.state";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import type { IntrospectionsResponse } from "@queries/useIntrospections";
import { LEXER_TOKENS, type LexerToken, TOKEN_TYPE } from "@utils/Lexer";
import { getFieldConfig } from "@utils/getFieldConfig";

/**
 * Validate tokens order/amount/parenthesis. Checks if the tokens follow a specific order (field, operator, value), verifies that field names are valid, ensures that logical tokens are used correctly, checks for balanced parentheses, and detects duplicate teokens (except for brackets).
 * @param introspections
 * @param tokens
 * @returns
 */
function validateTokens(introspections: IntrospectionsResponse, tokens: LexerToken[]) {
  // Todo: this might be moved up to avoid state lookup on every call
  const fieldReferences = getFieldReferencesFieldsSnapshot();
  // tokens always must be in this order: TOKEN_TYPE.FIELD, TOKEN_TYPE.OPERATOR, TOKEN_TYPE.VALUE. TOKEN_TYPE.MISC can be anywhere and ignored. TOKEN_TYPE.FUNCTION is also a field, but has a complex logic inside and is self-dependent.
  const fieldTokens = tokens.filter((token: LexerToken) => token.type === TOKEN_TYPE.FIELD);
  const operatorTokens = tokens.filter((token: LexerToken) => token.type === TOKEN_TYPE.OPERATOR);
  const valueTokens = tokens.filter((token: LexerToken) => token.type === TOKEN_TYPE.VALUE);
  const logicalTokens = tokens.filter((token: LexerToken) => token.type === TOKEN_TYPE.LOGIC);
  const functionTokens = tokens.filter((token: LexerToken) => token.type === TOKEN_TYPE.FUNCTION);

  if (valueTokens.length > 0) {
    // Empty string values are considered as invalid
    const emptyStringValues = valueTokens.filter(
      (token: LexerToken) => token.name === "STRING_VALUE" && token.value === "",
    );

    if (emptyStringValues.length > 0) {
      throw new Error("Search query is invalid. Please check your query for empty string values.");
    }

    // If theres a type OPERATOR token and then a VALUE, OPERATOR might be one of 'WITHIN' or 'MORE_THAN', then its following 'VALUE' should be a specific format, in order to consider this use case correct: X "hour", "day", "week", "month", "year", f.e. "2 days", "8 months", "1 year"
    const validTimeUnits = ["hour", "day", "week", "month", "year", "hours", "days", "weeks", "months", "years"];
    for (let i = 0; i < tokens.length - 1; i++) {
      const currentToken = tokens[i]!;
      const nextToken = tokens[i + 1];
      if (
        currentToken.type === TOKEN_TYPE.OPERATOR &&
        (currentToken.name === "WITHIN" || currentToken.name === "MORE_THAN" || currentToken.name === "WITHIN_NEXT") &&
        nextToken?.type === TOKEN_TYPE.VALUE
      ) {
        const valueParts = nextToken.value.split(" ");
        if (
          valueParts.length !== 2 ||
          Number.isNaN(Number(valueParts[0])) ||
          (valueParts[1] && !validTimeUnits.includes(valueParts[1]))
        ) {
          throw new Error(
            `Search query is invalid. The value after '${currentToken.name}' must be in the format 'X hours/days/weeks/months/years', e.g., '2 days', '8 months', '1 year'.`,
          );
        }
      }
    }
  }

  if (
    fieldTokens.length !== operatorTokens.length ||
    fieldTokens.length !== valueTokens.length ||
    operatorTokens.length !== valueTokens.length
  ) {
    throw new Error("Search query is invalid. Please check your query for invalid tokens combination.");
  }

  // verify that field names are valid
  const invalidFieldNames = fieldTokens.filter(
    (token: LexerToken) => !getFieldConfig(introspections, token.value as ThreatKey),
  );

  if (invalidFieldNames.length > 0) {
    throw new Error("Search query is invalid. Please check your query for invalid field names.");
  }

  if (logicalTokens.length > 0) {
    // logicalTokens increases amount of fieldTokens and can be validated
    // 1 logical token can be between 2 field tokens
    // 2 logical tokens requires 3 field tokens
    // 3 logical tokens requires 4 field tokens
    // etc
    const logicalTokensCount = logicalTokens.length;
    const fieldTokensCount = fieldTokens.length + functionTokens.length; // function tokens are also field tokens
    if (fieldTokensCount < logicalTokensCount + 1) {
      throw new Error("Search query is invalid. Please check your query for invalid tokens.");
    }
  }

  // verify that parenthesis is balanced
  const openingBrackets = tokens.filter((token: LexerToken) => token.name === "PAREN_R");
  const closingBrackets = tokens.filter((token: LexerToken) => token.name === "PAREN_L");

  if (openingBrackets.length !== closingBrackets.length) {
    throw new Error("Search query is invalid. Please check parenthesis in your query.");
  }

  // verify that there are no two same tokens in a row, unless its a brackets

  const sameTokens = tokens.filter(
    (token: LexerToken, index) =>
      index > 0 && token.name === tokens[index - 1]!.name && token.name !== "PAREN_L" && token.name !== "PAREN_R",
  );

  if (sameTokens.length > 0) {
    throw new Error("Search query is invalid. Please check your query for duplicate tokens.");
  }

  /** fieldReferences contains info about fieldTokens type and possible values which we may validate against
   */

  for (let i = 0; i < tokens.length - 2; i++) {
    const fieldToken = tokens[i];
    const operatorToken = tokens[i + 1];
    const valueToken = tokens[i + 2];

    if (!fieldToken || !operatorToken || !valueToken) {
      continue;
    }

    const strictValueMatchOperators = [
      LEXER_TOKENS.EQUALS,
      LEXER_TOKENS.NOT_EQUALS,
      LEXER_TOKENS.GREATER,
      LEXER_TOKENS.GREATER_EQUAL,
      LEXER_TOKENS.LESS,
      LEXER_TOKENS.LESS_EQUAL,
      LEXER_TOKENS.CONTAINS,
      LEXER_TOKENS.NOT_CONTAINS,
    ] as const;

    type StrictValueMatchOperator = (typeof strictValueMatchOperators)[number];

    const isStrictValueMatchNeeded = strictValueMatchOperators.includes(operatorToken.name as StrictValueMatchOperator);

    if (!isStrictValueMatchNeeded) {
      continue;
    }

    if (
      // we have full token chain
      fieldToken?.type === TOKEN_TYPE.FIELD &&
      operatorToken?.type === TOKEN_TYPE.OPERATOR &&
      valueToken?.type === TOKEN_TYPE.VALUE
    ) {
      const fieldReference = fieldReferences[fieldToken.value as ThreatKey];

      // check if the value exists in possible_values
      if (fieldReference?.possible_values && fieldReference.possible_values.length > 0) {
        const fieldValue = valueToken.value.toString();

        if (!fieldReference.possible_values.includes(fieldValue)) {
          throw new Error(
            `Invalid value "${fieldValue}" for field "${fieldToken.value}". Allowed values are: ${fieldReference.possible_values
              .slice(0, 5)
              .join(", ")}${fieldReference.possible_values.length > 5 ? " and more" : ""}`,
          );
        }
      }

      // Validate type constraints
      if (fieldReference?.type) {
        const fieldValue = valueToken.value;

        switch (fieldReference.type) {
          case FIELD_TYPE.JSON:
          case FIELD_TYPE.STRING: {
            if (valueToken.name !== LEXER_TOKENS.STRING_VALUE) {
              throw new Error(
                `Invalid value for field "${fieldReference.id}". Expected a string, got "${valueToken.name}"`,
              );
            }
            break;
          }
          case FIELD_TYPE.DATE:
          case FIELD_TYPE.DATETIME: {
            if (valueToken.name !== LEXER_TOKENS.STRING_VALUE) {
              throw new Error(
                `Invalid value for field "${fieldReference.id}". Expected a date, got "${valueToken.name}"`,
              );
            }

            // date must be date
            if (Number.isNaN(Date.parse(fieldValue))) {
              throw new Error(`Invalid date for field "${fieldReference.id}". Expected a date, got "${fieldValue}"`);
            }

            break;
          }

          case FIELD_TYPE.FLOAT:
          case FIELD_TYPE.INTEGER:
            if (Number.isNaN(Number(valueToken.value))) {
              throw new Error(
                `Invalid value for field "${fieldReference.id}". Expected a number, got "${valueToken.value}"`,
              );
            }
            break;
          case FIELD_TYPE.BOOLEAN:
            if (valueToken.name !== LEXER_TOKENS.TRUE && valueToken.name !== LEXER_TOKENS.FALSE) {
              throw new Error(
                `Invalid value for field "${fieldReference.id}". Expected a boolean (True/False), got "${valueToken.name}"`,
              );
            }
            break;
        }
      }
    }
  }

  return true;
}

export { validateTokens };
