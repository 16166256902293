import Badge from "@components/Badge";
import { COLORS_CONFIGURATION } from "@configs/colors";
import type SerializedThreat from "@interfaces/SerializedThreat";
import type { IntrospectionsResponse, ModelPropertyConfigurationConstraint } from "@queries/useIntrospections";
import classNames from "classnames";
import { isNil, isString } from "lodash-es";

export const renderField = (
  field: keyof SerializedThreat,
  introspections: IntrospectionsResponse,
  threat: Partial<SerializedThreat>,
  titleExtraClasses?: string,
  valuesExtraClasses?: string,
) => {
  const fieldConstraint: ModelPropertyConfigurationConstraint = introspections.fields[field]?.constrains;

  const currentValue = threat[field];

  if (!fieldConstraint || isNil(currentValue) || !isString(currentValue) || currentValue === "") {
    return null;
  }

  // warn developer if current value is not in constraints
  if (!fieldConstraint.options?.includes(currentValue)) {
    console.warn(`Value ${currentValue} for field ${field} is not in constraints: ${fieldConstraint.options}`);
  }

  const valueForColor = currentValue.toUpperCase();
  const hasColorConfigured =
    COLORS_CONFIGURATION[field] && Object.keys(COLORS_CONFIGURATION[field]!).includes(valueForColor);
  const color = hasColorConfigured ? COLORS_CONFIGURATION[field]![valueForColor] : "default";

  if (!hasColorConfigured) {
    console.error(`Color not configured for value "${currentValue}"`, field);
  }

  return (
    <div key={field} className="lg:gap-2 flex flex-row items-center">
      <div className={classNames("max-w-[140px] lg:max-w-[190px] w-full py-2 font-semibold", titleExtraClasses)}>
        {fieldConstraint?.name}
      </div>
      <div
        className={classNames(
          "lg:flex-nowrap lg:justify-start flex flex-row flex-wrap justify-center gap-1 py-2",
          valuesExtraClasses,
        )}
      >
        {fieldConstraint?.options?.map((option) => (
          <Badge key={option} color={option === currentValue ? color : "default"} className="whitespace-nowrap">
            {option.replace("_", " ")}
          </Badge>
        ))}
      </div>
    </div>
  );
};
