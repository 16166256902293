const nucleusTheme = {
  // Popover //
  popover: {
    defaultProps: {
      placement: "top",
      offset: 5,
      dismiss: {},
      animate: {
        unmount: {},
        mount: {},
      },
      className: "",
    },
    styles: {
      base: {
        bg: "bg-white",
        p: "p-2",
        border: "border border-gray-400",
        borderRadius: "rounded",
        boxShadow: "shadow shadow-blue-gray-500/10",
        fontFamily: "font-sans",
        fontSize: "text-sm",
        fontWeight: "font-normal",
        color: "text-blue-gray-500",
        outline: "focus:outline-none",
        overflowWrap: "break-words",
        whiteSpace: "whitespace-normal",
        zIndex: "z-[9999]",
      },
    },
  },

  // Menu //
  menu: {
    defaultProps: {
      placement: "bottom",
      offset: 5,
      dismiss: {
        itemPress: true,
      },
      animate: {
        unmount: {},
        mount: {},
      },
      lockScroll: false,
    },
    styles: {
      base: {
        menu: {
          bg: "bg-white",
          minWidth: "min-w-[180px]",
          p: "p-0",
          border: "border border-gray-100",
          borderRadius: "rounded",
          boxShadow: "shadow-sm shadow-gray-500/10",
          fontFamily: "font-sans",
          fontSize: "text-sm",
          fontWeight: "font-normal",
          color: "text-gray-700",
          overflow: "overflow-auto",
          outline: "focus:outline-none",
          zIndex: "z-[9997]",
        },
        item: {
          initial: {
            display: "block",
            width: "w-full",
            pt: "pt-[9px]",
            pb: "pb-2",
            px: "px-3",
            borderRadius: "rounded-none",
            textAlign: "text-start",
            lightHeight: "leading-tight",
            cursor: "cursor-pointer",
            userSelect: "select-none",
            transition: "transition-all",
            bg: "hover:bg-blue-200 hover:bg-opacity-80 focus:bg-blue-200 focus:bg-opacity-80 active:bg-blue-200 active:bg-opacity-80",
            color: "hover:text-blue-gray-900 focus:text-blue-gray-900 active:text-blue-gray-900",
            outline: "outline-none",
          },
          disabled: {
            opacity: "opacity-50",
            cursor: "cursor-not-allowed",
            pointerEvents: "pointer-events-none",
            userSelect: "select-none",
            bg: "hover:bg-transparent focus:bg-transparent active:bg-transparent",
            color: "hover:text-blue-gray-500 focus:text-blue-gray-500 active:text-blue-gray-500",
          },
        },
      },
    },
  },

  // Card //
  card: {
    defaultProps: {
      variant: "filled",
      color: "white",
      shadow: true,
      className: "",
    },
    valid: {
      variants: ["filled"],
      colors: [
        "transparent",
        "white",
        "blue-gray",
        "gray",
        "brown",
        "orange",
        "yellow",
        "green",
        "teal",
        "cyan",
        "blue",
        "indigo",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        initial: {
          position: "relative",
          display: "flex",
          flexDirection: "flex-col",
          backgroundClip: "bg-clip-border",
          borderRadius: "rounded",
          border: "border border-gray-300",
        },
        shadow: {
          boxShadow: "shadow-sm",
        },
      },
      variants: {
        filled: {
          transparent: {
            backgroud: "bg-transparent",
            color: "text-gray-700",
            shadow: "shadow-none",
          },
          white: {
            backgroud: "bg-white",
            color: "text-gray-700",
          },
          "blue-gray": {
            backgroud: "bg-blue-gray-500",
            color: "text-white",
            shadow: "shadow-blue-gray-500/40",
          },
          gray: {
            backgroud: "bg-gray-500",
            color: "text-white",
            shadow: "shadow-gray-500/40",
          },
          brown: {
            backgroud: "bg-brown-500",
            color: "text-white",
            shadow: "shadow-brown-500/40",
          },
          orange: {
            backgroud: "bg-orange-500",
            color: "text-white",
            shadow: "shadow-orange-500/40",
          },
          yellow: {
            backgroud: "bg-yellow-500",
            color: "text-gray-700",
            shadow: "shadow-yellow-500/40",
          },
          green: {
            backgroud: "bg-green-500",
            color: "text-white",
            shadow: "shadow-green-500/40",
          },
          blue: {
            backgroud: "bg-blue-500",
            color: "text-white",
            shadow: "shadow-blue-500/40",
          },
          red: {
            backgroud: "bg-red-500",
            color: "text-white",
            shadow: "shadow-red-500/40",
          },
        },
      },
    },
  },
  cardHeader: {
    defaultProps: {
      variant: "filled",
      color: "white",
      shadow: true,
      floated: true,
      className: "",
    },
    valid: {
      variants: ["filled"],
      colors: ["transparent", "white", "yellow", "green", "blue", "red"],
    },
    styles: {
      base: {
        initial: {
          position: "relative",
          backgroundClip: "bg-clip-border",
          mt: "mt-4",
          mx: "mx-4",
          borderRadius: "rounded-xl",
          overflow: "overflow-hidden",
        },
        shadow: {
          boxShadow: "shadow-lg",
        },
        floated: {
          mt: "-mt-6",
        },
      },
      variants: {
        filled: {
          transparent: {
            backgroud: "bg-transparent",
            color: "text-gray-700",
            shadow: "shadow-none",
          },
          white: {
            backgroud: "bg-white",
            color: "text-gray-700",
          },
          yellow: {
            backgroud: "bg-yellow-500",
            color: "text-gray-700",
            shadow: "shadow-yellow-500/40",
          },
          green: {
            backgroud: "bg-green-500",
            color: "text-white",
            shadow: "shadow-green-500/40",
          },
          blue: {
            backgroud: "bg-blue-500",
            color: "text-white",
            shadow: "shadow-blue-500/40",
          },
          red: {
            backgroud: "bg-red-500",
            color: "text-white",
            shadow: "shadow-red-500/40",
          },
        },
      },
    },
  },
  cardBody: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        p: "p-4 md:p-6",
      },
    },
  },
  cardFooter: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        initial: {
          p: "p-4 md:p-6",
        },
        divider: {
          borderWidth: "border-t",
          borderColor: "border-blue-gray-50",
        },
      },
    },
  },

  // Radio //
  radio: {
    defaultProps: {
      color: "blue",
      label: undefined,
      icon: undefined,
      ripple: true,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: [
        "blue-gray",
        "gray",
        "brown",
        "orange",
        "yellow",
        "green",
        "teal",
        "cyan",
        "blue",
        "indigo",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "flex",
          alignItems: "items-center",
          cursor: "cursor-pointer",
          p: "p-2",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-full",
          borderColor: "border-gray-300",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:bg-gray-300",
            width: "before:w-7",
            height: "before:h-7",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-gray-700",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        "blue-gray": {
          color: "text-blue-gray-500",
          border: "checked:border-blue-gray-500",
          before: "checked:before:bg-blue-gray-500",
        },
        gray: {
          color: "text-gray-500",
          border: "checked:border-gray-500",
          before: "checked:before:bg-gray-500",
        },
        brown: {
          color: "text-brown-500",
          border: "checked:border-brown-500",
          before: "checked:before:bg-brown-500",
        },
        orange: {
          color: "text-orange-500",
          border: "checked:border-orange-500",
          before: "checked:before:bg-orange-500",
        },
        yellow: {
          color: "text-yellow-500",
          border: "checked:border-yellow-500",
          before: "checked:before:bg-yellow-500",
        },
        green: {
          color: "text-green-500",
          border: "checked:border-green-500",
          before: "checked:before:bg-green-500",
        },
        teal: {
          color: "text-teal-500",
          border: "checked:border-teal-500",
          before: "checked:before:bg-teal-500",
        },
        cyan: {
          color: "text-cyan-500",
          border: "checked:border-cyan-500",
          before: "checked:before:bg-cyan-500",
        },
        blue: {
          color: "text-blue-500",
          border: "checked:border-blue-500",
          before: "checked:before:bg-blue-500",
        },
        indigo: {
          color: "text-indigo-500",
          border: "checked:border-indigo-500",
          before: "checked:before:bg-indigo-500",
        },
        purple: {
          color: "text-purple-500",
          border: "checked:border-purple-500",
          before: "checked:before:bg-purple-500",
        },
        pink: {
          color: "text-pink-500",
          border: "checked:border-pink-500",
          before: "checked:before:bg-pink-500",
        },
        red: {
          color: "text-red-500",
          border: "checked:border-red-500",
          before: "checked:before:bg-red-500",
        },
      },
    },
  },

  // Checkbox //
  checkbox: {
    defaultProps: {
      color: "blue",
      label: undefined,
      icon: undefined,
      ripple: true,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: [
        "blue-gray",
        "gray",
        "brown",
        "orange",
        "yellow",
        "green",
        "teal",
        "cyan",
        "blue",
        "indigo",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "flex",
          alignItems: "items-center",
          cursor: "cursor-pointer",
          p: "p-3",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-md",
          borderColor: "border-blue-gray-200",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:bg-blue-gray-500",
            width: "before:w-7",
            height: "before:h-7",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-gray-700",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          color: "text-white",
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        "blue-gray": {
          background: "checked:bg-blue-gray-500",
          border: "checked:border-blue-gray-500",
          before: "checked:before:bg-blue-gray-500",
        },
        gray: {
          background: "checked:bg-gray-500",
          border: "checked:border-gray-500",
          before: "checked:before:bg-gray-500",
        },
        brown: {
          background: "checked:bg-brown-500",
          border: "checked:border-brown-500",
          before: "checked:before:bg-brown-500",
        },
        orange: {
          background: "checked:bg-orange-500",
          border: "checked:border-orange-500",
          before: "checked:before:bg-orange-500",
        },
        yellow: {
          background: "checked:bg-yellow-500",
          border: "checked:border-yellow-500",
          before: "checked:before:bg-yellow-500",
        },
        green: {
          background: "checked:bg-green-500",
          border: "checked:border-green-500",
          before: "checked:before:bg-green-500",
        },
        teal: {
          background: "checked:bg-teal-500",
          border: "checked:border-teal-500",
          before: "checked:before:bg-teal-500",
        },
        cyan: {
          background: "checked:bg-cyan-500",
          border: "checked:border-cyan-500",
          before: "checked:before:bg-cyan-500",
        },

        blue: {
          background: "checked:bg-blue-500",
          border: "checked:border-blue-500",
          before: "checked:before:bg-blue-500",
        },
        indigo: {
          background: "checked:bg-indigo-500",
          border: "checked:border-indigo-500",
          before: "checked:before:bg-indigo-500",
        },
        purple: {
          background: "checked:bg-purple-500",
          border: "checked:border-purple-500",
          before: "checked:before:bg-purple-500",
        },
        pink: {
          background: "checked:bg-pink-500",
          border: "checked:border-pink-500",
          before: "checked:before:bg-pink-500",
        },
        red: {
          background: "checked:bg-red-500",
          border: "checked:border-red-500",
          before: "checked:before:bg-red-500",
        },
      },
    },
  },

  // Chip //
  chip: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "blue",
      icon: undefined,
      open: true,
      onClose: undefined,
      action: undefined,
      animate: {
        unmount: {},
        mount: {},
      },
      className: "",
    },
    valid: {
      variants: ["filled", "outlined", "ghost"],
      sizes: ["sm", "md", "lg"],
      colors: ["gray", "orange", "yellow", "green", "blue", "red"],
    },
    styles: {
      base: {
        chip: {
          position: "relative",
          display: "grid",
          placeItems: "items-center",
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          textTransform: "uppercase",
          lineHeight: "leading-none",
          whiteSpace: "whitespace-nowrap",
          userSelect: "select-none",
        },
        action: {
          position: "!absolute",
          top: "top-2/4",
          right: "right-1",
          translate: "-translate-y-2/4",
          mx: "mx-px",
          rounded: "rounded-md",
        },
        icon: {
          position: "absolute",
          top: "top-2/4",
          translate: "-translate-y-2/4",
        },
      },
      sizes: {
        sm: {
          chip: {
            py: "py-1",
            px: "px-2",
            fontSize: "text-xs",
            borderRadius: "rounded-md",
          },
          action: {
            width: "w-4",
            height: "h-4",
          },
          icon: {
            width: "w-4",
            height: "h-4",
            left: "left-1",
          },
        },
        md: {
          chip: {
            py: "py-1.5",
            px: "px-3",
            fontSize: "text-xs",
            borderRadius: "rounded-lg",
          },
          action: {
            width: "w-5",
            height: "h-5",
          },
          icon: {
            width: "w-5",
            height: "h-5",
            left: "left-1.5",
          },
        },
        lg: {
          chip: {
            py: "py-2",
            px: "px-4",
            fontSize: "text-xs",
            borderRadius: "rounded-lg",
          },
          action: {
            width: "w-6",
            height: "h-6",
          },
          icon: {
            width: "w-6",
            height: "h-6",
            left: "left-1.5",
          },
        },
      },
      variants: {
        filled: {
          gray: {
            background: "bg-gray-500",
            color: "text-white",
          },
          orange: {
            background: "bg-orange-500",
            color: "text-white",
          },
          yellow: {
            background: "bg-yellow-500",
            color: "text-black",
          },
          green: {
            background: "bg-green-500",
            color: "text-white",
          },
          blue: {
            background: "bg-blue-500",
            color: "text-white",
          },
          red: {
            background: "bg-red-500",
            color: "text-white",
          },
        },
        outlined: {
          gray: {
            border: "border border-gray-500",
            color: "text-gray-700",
          },
          orange: {
            border: "border border-orange-500",
            color: "text-orange-700",
          },
          yellow: {
            border: "border border-yellow-500",
            color: "text-yellow-700",
          },
          green: {
            border: "border border-green-500",
            color: "text-green-700",
          },
          blue: {
            border: "border border-blue-500",
            color: "text-blue-700",
          },
          red: {
            border: "border border-red-500",
            color: "text-red-700",
          },
        },
        ghost: {
          gray: {
            background: "bg-gray-500/20",
            color: "text-gray-900",
          },
          orange: {
            background: "bg-orange-500/20",
            color: "text-orange-900",
          },
          yellow: {
            background: "bg-yellow-500/20",
            color: "text-yellow-900",
          },
          green: {
            background: "bg-green-500/20",
            color: "text-green-900",
          },
          blue: {
            background: "bg-blue-500/20",
            color: "text-blue-900",
          },
          red: {
            background: "bg-red-500/20",
            color: "text-red-900",
          },
        },
      },
    },
  },

  // Drawer //
  drawer: {
    defaultProps: {
      size: 300,
      overlay: true,
      placement: "left",
      overlayProps: undefined,
      className: "",
      dismiss: undefined,
      onClose: undefined,
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
    styles: {
      base: {
        drawer: {
          position: "fixed",
          zIndex: "z-[9996]",
          pointerEvents: "pointer-events-auto",
          backgroundColor: "bg-white",
          boxSizing: "box-border",
          width: "w-full",
          boxShadow: "shadow-2xl shadow-blue-gray-900/10",
        },
        overlay: {
          position: "fixed",
          inset: "inset-0",
          width: "w-full",
          height: "h-screen",
          pointerEvents: "pointer-events-auto",
          zIndex: "z-[9995]",
          backgroundColor: "bg-black",
          backgroundOpacity: "bg-opacity-60",
          backdropBlur: "backdrop-blur-sm",
        },
      },
    },
  },

  // Dialog //
  dialog: {
    defaultProps: {
      size: "md",
      dismiss: {},
      animate: {
        unmount: {},
        mount: {},
      },
      className: "",
    },
    valid: {
      sizes: ["xs", "sm", "md", "lg", "xl", "xxl"],
    },
    styles: {
      base: {
        backdrop: {
          display: "grid",
          placeItems: "place-items-center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "w-screen",
          height: "h-screen",
          backgroundColor: "bg-black",
          backgroundOpacity: "bg-opacity-60",
          backdropFilter: "backdrop-blur-sm",
        },
        container: {
          position: "relative",
          bg: "bg-white",
          m: "m-4",
          borderRadius: "rounded",
          boxShadow: "shadow-2xl",
          color: "text-blue-gray-500",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-light",
          lineHeight: "leading-relaxed",
        },
      },
      sizes: {
        xs: {
          width: "w-full md:w-3/5 lg:w-2/5 2xl:w-1/4",
          minWidth: "min-w-[80%] md:min-w-[60%] lg:min-w-[40%] 2xl:min-w-[25%]",
          maxWidth: "max-w-[80%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[25%]",
        },
        sm: {
          width: "w-full md:w-2/3 lg:w-2/4 2xl:w-1/3",
          minWidth: "min-w-[80%] md:min-w-[66.666667%] lg:min-w-[50%] 2xl:min-w-[33.333333%]",
          maxWidth: "max-w-[80%] md:max-w-[66.666667%] lg:max-w-[50%] 2xl:max-w-[33.333333%]",
        },
        md: {
          width: "w-full md:w-3/4 lg:w-3/5 2xl:w-2/5",
          minWidth: "min-w-[90%] md:min-w-[75%] lg:min-w-[60%] 2xl:min-w-[40%]",
          maxWidth: "max-w-[90%] md:max-w-[75%] lg:max-w-[60%] 2xl:max-w-[40%]",
        },
        lg: {
          width: "w-full md:w-5/6 lg:w-3/4 2xl:w-3/5",
          minWidth: "min-w-[90%] md:min-w-[83.333333%] lg:min-w-[75%] 2xl:min-w-[60%]",
          maxWidth: "max-w-[90%] md:max-w-[83.333333%] lg:max-w-[75%] 2xl:max-w-[60%]",
        },
        xl: {
          width: "w-full md:w-5/6 2xl:w-3/4",
          minWidth: "min-w-[95%] md:min-w-[83.333333%] 2xl:min-w-[75%]",
          maxWidth: "max-w-[95%] md:max-w-[83.333333%] 2xl:max-w-[75%]",
        },
        xxl: {
          display: "flex",
          flexDirection: "flex-col",
          width: "w-screen",
          minWidth: "min-w-[100vw]",
          maxWidth: "max-w-[100vw]",
          height: "h-screen",
          minHeight: "min-h-[100vh]",
          maxHeight: "max-h-[100vh]",
          m: "m-0",
          borderRadius: "rounded-none",
        },
      },
    },
  },
  dialogHeader: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        display: "flex",
        alignItems: "items-center",
        flexShrink: "shrink-0",
        p: "px-4 pb-2 pt-4",
        color: "text-blue-gray-900",
        fontSmoothing: "antialiased",
        fontFamily: "font-sans",
        fontSize: "text-2xl",
        fontWeight: "font-semibold",
        lineHeight: "leading-snug",
      },
    },
  },
  dialogBody: {
    defaultProps: {
      className: "",
      divider: false,
    },
    styles: {
      base: {
        initial: {
          position: "relative",
          p: "px-4 pb-4 pt-2",
          color: "text-blue-gray-500",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-light",
          lineHeight: "leading-relaxed",
        },
        divider: {
          borderTop: "border-t-none",
          borderBottom: "border-b-none",
        },
      },
    },
  },
  dialogFooter: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        display: "flex",
        alignItems: "items-center",
        justifyContent: "justify-end",
        flexShrink: "shrink-0",
        flexWrap: "flex-wrap",
        p: "p-4",
        color: "text-blue-gray-500",
      },
    },
  },

  typography: {
    defaultProps: {
      variant: "paragraph",
      color: "inherit",
      textGradient: false,
      className: "",
    },
    valid: {
      variants: ["h1", "h2", "h3", "h4", "h5", "h6", "lead", "paragraph", "small"],
      colors: ["inherit", "current", "black", "white", "gray", "orange", "yellow", "green", "blue", "purple", "red"],
    },
    styles: {
      variants: {
        h1: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-3xl xl:text-5xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-tight",
        },
        h2: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-3xl xl:text-4xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-[1.3]",
        },
        h3: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-3xl xl:text-4xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h4: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-xl xl:text-2xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h5: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-lg xl:text-xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h6: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-normal",
          lineHeight: "leading-relaxed",
        },
        lead: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-xl",
          fontWeight: "font-normal",
          lineHeight: "leading-relaxed",
        },
        paragraph: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-light",
          lineHeight: "leading-relaxed",
        },
        small: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-sm",
          fontWeight: "font-light",
          lineHeight: "leading-normal",
        },
      },
      textGradient: {
        bgClip: "bg-clip-text",
        color: "text-transparent",
      },
      colors: {
        inherit: {
          color: "text-black",
        },
        current: {
          color: "text-current",
        },
        black: {
          color: "text-black",
        },
        white: {
          color: "text-white",
        },
        gray: {
          color: "text-gray-700",
          gradient: "bg-gradient-to-tr from-gray-600 to-gray-400",
        },
        orange: {
          color: "text-orange-500",
          gradient: "bg-gradient-to-tr from-orange-600 to-orange-400",
        },
        yellow: {
          color: "text-yellow-500",
          gradient: "bg-gradient-to-tr from-yellow-600 to-yellow-400",
        },
        green: {
          color: "text-green-500",
          gradient: "bg-gradient-to-tr from-green-600 to-green-400",
        },
        blue: {
          color: "text-blue-500",
          gradient: "bg-gradient-to-tr from-blue-600 to-blue-400",
        },
        purple: {
          color: "text-purple-500",
          gradient: "bg-gradient-to-tr from-purple-600 to-purple-400",
        },
        red: {
          color: "text-red-500",
          gradient: "bg-gradient-to-tr from-red-600 to-red-400",
        },
      },
    },
  },

  // Tooltip //
  tooltip: {
    defaultProps: {
      interactive: false,
      placement: "top",
      offset: 5,
      dismiss: {},
      animate: {
        unmount: {},
        mount: {},
      },
      className: "",
    },
    styles: {
      base: {
        bg: "bg-black",
        py: "py-1.5",
        px: "px-3",
        borderRadius: "rounded-lg",
        fontFamily: "font-sans",
        fontSize: "text-sm",
        fontWeight: "font-normal",
        color: "text-white",
        outline: "focus:outline-none",
        overflowWrap: "break-words",
        zIndex: "z-[9997]",
        whiteSpace: "whitespace-normal",
      },
    },
  },

  // Button //
  button: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "black",
      fullWidth: false,
      ripple: false,
      className: "",
    },
    valid: {
      variants: ["filled", "outlined", "gradient", "text"],
      sizes: ["sm", "md", "lg"],
      colors: [
        "white",
        "blue-gray",
        "gray",
        "brown",
        "orange",
        "yellow",
        "green",
        "light-blue",
        "blue",
        "purple",
        "pink",
        "red",
        "black",
      ],
    },
    styles: {
      base: {
        initial: {
          verticalAlign: "align-middle",
          userSelect: "select-none",
          fontFamily: "font-sans",
          fontWeight: "font-normal",
          textAlign: "text-center",
          textTransform: "normal-case",
          transition: "transition-all",
          disabled: "disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none disabled:cursor-not-allowed",
        },
        fullWidth: {
          display: "block",
          width: "w-full",
        },
      },
      sizes: {
        sm: {
          fontSize: "text-xs",
          py: "py-2",
          px: "px-3",
          borderRadius: "rounded-md",
        },
        md: {
          fontSize: "text-xs",
          py: "py-2.5",
          px: "px-4",
          borderRadius: "rounded-md",
        },
        lg: {
          fontSize: "text-sm",
          py: "py-3.5",
          px: "px-8",
          borderRadius: "rounded-md",
        },
      },
      variants: {
        filled: {
          black: {
            backgroud: "bg-black",
            color: "text-white",
          },
          white: {
            backgroud: "bg-white",
            color: "text-blue-gray-900",
            shadow: "shadow-sm shadow-blue-gray-500/10",
            hover: "hover:shadow-md hover:shadow-blue-gray-500/20",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          "blue-gray": {
            backgroud: "bg-blue-gray-500",
            color: "text-white",
            shadow: "shadow-sm shadow-blue-gray-500/20",
            hover: "hover:shadow-md hover:shadow-blue-gray-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          gray: {
            backgroud: "bg-gray-500",
            color: "text-white",
            shadow: "shadow-sm shadow-gray-500/20",
            hover: "hover:shadow-md hover:shadow-gray-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          brown: {
            backgroud: "bg-brown-500",
            color: "text-white",
            shadow: "shadow-sm shadow-brown-500/20",
            hover: "hover:shadow-md hover:shadow-brown-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          orange: {
            backgroud: "bg-orange-500",
            color: "text-white",
            shadow: "shadow-sm shadow-orange-500/20",
            hover: "hover:shadow-md hover:shadow-orange-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          yellow: {
            backgroud: "bg-yellow-500",
            color: "text-black",
            shadow: "shadow-sm shadow-yellow-500/20",
            hover: "hover:shadow-md hover:shadow-yellow-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          green: {
            backgroud: "bg-green-500",
            color: "text-white",
            shadow: "shadow-sm shadow-green-500/20",
            hover: "hover:shadow-md hover:shadow-green-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          "light-blue": {
            backgroud: "bg-light-blue-500",
            color: "text-white",
            shadow: "shadow-sm shadow-light-blue-500/20",
            hover: "hover:shadow-md hover:shadow-light-blue-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          blue: {
            backgroud: "bg-blue-500",
            color: "text-white",
            shadow: "shadow-sm shadow-blue-500/20",
            hover: "hover:shadow-md hover:shadow-blue-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          purple: {
            backgroud: "bg-purple-500",
            color: "text-white",
            shadow: "shadow-sm shadow-purple-500/20",
            hover: "hover:shadow-md hover:shadow-purple-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          pink: {
            backgroud: "bg-pink-500",
            color: "text-white",
            shadow: "shadow-sm shadow-pink-500/20",
            hover: "hover:shadow-md hover:shadow-pink-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          red: {
            backgroud: "bg-red-200",
            color: "text-red-700",
            shadow: "shadow-sm shadow-red-500/20",
            hover: "hover:shadow-md hover:shadow-red-500/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
        },
        gradient: {
          white: {
            backgroud: "bg-white",
            color: "text-blue-gray-900",
            shadow: "shadow-sm shadow-blue-gray-500/10",
            hover: "hover:shadow-md hover:shadow-blue-gray-500/20",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
          "blue-gray": {
            backgroud: "bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400",
            color: "text-white",
            shadow: "shadow-sm shadow-blue-gray-500/20",
            hover: "hover:shadow-md hover:shadow-blue-gray-500/40",
            active: "active:opacity-[0.85]",
          },
          gray: {
            backgroud: "bg-gradient-to-tr from-gray-600 to-gray-400",
            color: "text-white",
            shadow: "shadow-sm shadow-gray-500/20",
            hover: "hover:shadow-md hover:shadow-gray-500/40",
            active: "active:opacity-[0.85]",
          },
          brown: {
            backgroud: "bg-gradient-to-tr from-brown-600 to-brown-400",
            color: "text-white",
            shadow: "shadow-sm shadow-brown-500/20",
            hover: "hover:shadow-md hover:shadow-brown-500/40",
            active: "active:opacity-[0.85]",
          },
          orange: {
            backgroud: "bg-gradient-to-tr from-orange-600 to-orange-400",
            color: "text-white",
            shadow: "shadow-sm shadow-orange-500/20",
            hover: "hover:shadow-md hover:shadow-orange-500/40",
            active: "active:opacity-[0.85]",
          },
          yellow: {
            backgroud: "bg-gradient-to-tr from-yellow-600 to-yellow-400",
            color: "text-black",
            shadow: "shadow-sm shadow-yellow-500/20",
            hover: "hover:shadow-md hover:shadow-yellow-500/40",
            active: "active:opacity-[0.85]",
          },
          green: {
            backgroud: "bg-gradient-to-tr from-green-600 to-green-400",
            color: "text-white",
            shadow: "shadow-sm shadow-green-500/20",
            hover: "hover:shadow-md hover:shadow-green-500/40",
            active: "active:opacity-[0.85]",
          },
          "light-blue": {
            backgroud: "bg-gradient-to-tr from-light-blue-600 to-light-blue-400",
            color: "text-white",
            shadow: "shadow-sm shadow-light-blue-500/20",
            hover: "hover:shadow-md hover:shadow-light-blue-500/40",
            active: "active:opacity-[0.85]",
          },
          blue: {
            backgroud: "bg-gradient-to-tr from-blue-600 to-blue-400",
            color: "text-white",
            shadow: "shadow-sm shadow-blue-500/20",
            hover: "hover:shadow-md hover:shadow-blue-500/40",
            active: "active:opacity-[0.85]",
          },
          purple: {
            backgroud: "bg-gradient-to-tr from-purple-600 to-purple-400",
            color: "text-white",
            shadow: "shadow-sm shadow-purple-500/20",
            hover: "hover:shadow-md hover:shadow-purple-500/40",
            active: "active:opacity-[0.85]",
          },
          pink: {
            backgroud: "bg-gradient-to-tr from-pink-600 to-pink-400",
            color: "text-white",
            shadow: "shadow-sm shadow-pink-500/20",
            hover: "hover:shadow-md hover:shadow-pink-500/40",
            active: "active:opacity-[0.85]",
          },
          red: {
            backgroud: "bg-gradient-to-tr from-red-600 to-red-400",
            color: "text-white",
            shadow: "shadow-sm shadow-red-500/20",
            hover: "hover:shadow-md hover:shadow-red-500/40",
            active: "active:opacity-[0.85]",
          },
        },
        outlined: {
          white: {
            border: "border border-white",
            color: "text-white",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-white/50",
            active: "active:opacity-[0.85]",
          },
          "blue-gray": {
            border: "border border-blue-gray-500",
            color: "text-blue-gray-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-blue-gray-200",
            active: "active:opacity-[0.85]",
          },
          gray: {
            border: "border border-gray-500",
            color: "text-gray-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-gray-200",
            active: "active:opacity-[0.85]",
          },
          brown: {
            border: "border border-brown-500",
            color: "text-brown-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-brown-200",
            active: "active:opacity-[0.85]",
          },
          orange: {
            border: "border border-orange-500",
            color: "text-orange-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-orange-200",
            active: "active:opacity-[0.85]",
          },
          yellow: {
            border: "border border-yellow-500",
            color: "text-yellow-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-yellow-200",
            active: "active:opacity-[0.85]",
          },
          green: {
            border: "border border-green-500",
            color: "text-green-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-green-200",
            active: "active:opacity-[0.85]",
          },
          "light-blue": {
            border: "border border-light-blue-500",
            color: "text-light-blue-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-light-blue-200",
            active: "active:opacity-[0.85]",
          },
          blue: {
            border: "border border-blue-500",
            color: "text-blue-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-blue-200",
            active: "active:opacity-[0.85]",
          },
          purple: {
            border: "border border-purple-500",
            color: "text-purple-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-purple-200",
            active: "active:opacity-[0.85]",
          },
          pink: {
            border: "border border-pink-500",
            color: "text-pink-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-pink-200",
            active: "active:opacity-[0.85]",
          },
          red: {
            border: "border border-red-500",
            color: "text-red-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-red-200",
            active: "active:opacity-[0.85]",
          },
        },
        text: {
          white: {
            color: "text-white",
            hover: "hover:bg-white/10",
            active: "active:bg-white/30",
          },
          "blue-gray": {
            color: "text-blue-gray-500",
            hover: "hover:bg-blue-gray-500/10",
            active: "active:bg-blue-gray-500/30",
          },
          gray: {
            color: "text-gray-500",
            hover: "hover:bg-gray-500/10",
            active: "active:bg-gray-500/30",
          },
          brown: {
            color: "text-brown-500",
            hover: "hover:bg-brown-500/10",
            active: "active:bg-brown-500/30",
          },
          orange: {
            color: "text-orange-500",
            hover: "hover:bg-orange-500/10",
            active: "active:bg-orange-500/30",
          },
          yellow: {
            color: "text-yellow-500",
            hover: "hover:bg-yellow-500/10",
            active: "active:bg-yellow-500/30",
          },
          green: {
            color: "text-green-500",
            hover: "hover:bg-green-500/10",
            active: "active:bg-green-500/30",
          },
          "light-blue": {
            color: "text-light-blue-500",
            hover: "hover:bg-light-blue-500/10",
            active: "active:bg-light-blue-500/30",
          },
          blue: {
            color: "text-blue-500",
            hover: "hover:bg-blue-500/10",
            active: "active:bg-blue-500/30",
          },
          purple: {
            color: "text-purple-500",
            hover: "hover:bg-purple-500/10",
            active: "active:bg-purple-500/30",
          },
          pink: {
            color: "text-pink-500",
            hover: "hover:bg-pink-500/10",
            active: "active:bg-pink-500/30",
          },
          red: {
            color: "text-red-500",
            hover: "hover:bg-red-500/10",
            active: "active:bg-red-500/30",
          },
        },
      },
    },
  },

  // Badge
  badge: {
    defaultProps: {
      color: "red",
      invisible: false,
      withBorder: false,
      overlap: "square",
      content: undefined,
      placement: "top-end",
      className: undefined,
      containerProps: undefined,
    },
    valid: {
      colors: ["white", "gray", "brown", "orange", "yellow", "green", "blue", "purple", "red"],
      overlaps: ["circular", "square"],
      placements: ["top-start", "top-end", "bottom-start", "bottom-end"],
    },
    styles: {
      base: {
        container: {
          position: "relative",
          display: "inline-flex",
        },
        badge: {
          initial: {
            position: "absolute",
            minWidth: "min-w-[12px]",
            minHeight: "min-h-[12px]",
            borderRadius: "rounded-full",
            paddingY: "py-1",
            paddingX: "px-1",
            fontSize: "text-xs",
            fontWeight: "font-medium",
            content: "content-['']",
            lineHeight: "leading-none",
            display: "grid",
            placeItems: "place-items-center",
          },
          withBorder: {
            borderWidth: "border-2",
            borderColor: "border-white",
          },
          withContent: {
            minWidth: "min-w-[24px]",
            minHeight: "min-h-[24px]",
          },
        },
      },
      placements: {
        "top-start": {
          square: {
            top: "top-[4%]",
            left: "left-[2%]",
            translateX: "-translate-x-2/4",
            translateY: "-translate-y-2/4",
          },
          circular: {
            top: "top-[14%]",
            left: "left-[14%]",
            translateX: "-translate-x-2/4",
            translateY: "-translate-y-2/4",
          },
        },
        "top-end": {
          square: {
            top: "top-[4%]",
            right: "right-[2%]",
            translateX: "translate-x-2/4",
            translateY: "-translate-y-2/4",
          },
          circular: {
            top: "top-[14%]",
            right: "right-[14%]",
            translateX: "translate-x-2/4",
            translateY: "-translate-y-2/4",
          },
        },
        "bottom-start": {
          square: {
            bottom: "bottom-[4%]",
            left: "left-[2%]",
            translateX: "-translate-x-2/4",
            translateY: "translate-y-2/4",
          },
          circular: {
            bottom: "bottom-[14%]",
            left: "left-[14%]",
            translateX: "-translate-x-2/4",
            translateY: "translate-y-2/4",
          },
        },
        "bottom-end": {
          square: {
            bottom: "bottom-[4%]",
            right: "right-[2%]",
            translateX: "translate-x-2/4",
            translateY: "translate-y-2/4",
          },
          circular: {
            bottom: "bottom-[14%]",
            right: "right-[14%]",
            translateX: "translate-x-2/4",
            translateY: "translate-y-2/4",
          },
        },
      },
      colors: {
        white: {
          backgroud: "bg-white",
          color: "text-blue-gray-900",
        },
        gray: {
          backgroud: "bg-gray-500",
          color: "text-white",
        },
        brown: {
          backgroud: "bg-brown-500",
          color: "text-white",
        },
        orange: {
          backgroud: "bg-orange-500",
          color: "text-white",
        },
        yellow: {
          backgroud: "bg-yellow-500",
          color: "text-black",
        },
        green: {
          backgroud: "bg-green-500",
          color: "text-white",
        },
        blue: {
          backgroud: "bg-blue-500",
          color: "text-white",
        },
        purple: {
          backgroud: "bg-purple-500",
          color: "text-white",
        },
        pink: {
          backgroud: "bg-pink-500",
          color: "text-white",
        },
        red: {
          backgroud: "bg-red-700",
          animation: "animate-pulse",
          color: "text-white",
        },
      },
    },
  },
  buttonGroup: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "blue",
      fullWidth: false,
      ripple: true,
      className: "",
    },
    valid: {
      variants: ["filled", "outlined", "gradient", "text"],
      sizes: ["sm", "md", "lg"],
      colors: [
        "white",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        initial: {
          display: "flex",
          flexDirection: "row",
        },
        fullWidth: {
          width: "w-full",
        },
      },
      dividerColor: {
        white: {
          divideColor: "divide-blue-gray-50",
        },
        "blue-gray": {
          divideColor: "divide-blue-gray-600",
        },
        gray: {
          divideColor: "divide-gray-600",
        },
        brown: {
          divideColor: "divide-brown-600",
        },
        "deep-orange": {
          divideColor: "divide-deep-orange-600",
        },
        orange: {
          divideColor: "divide-orange-600",
        },
        amber: {
          divideColor: "divide-amber-600",
        },
        yellow: {
          divideColor: "divide-yellow-600",
        },
        lime: {
          divideColor: "divide-lime-600",
        },
        "light-green": {
          divideColor: "divide-light-green-600",
        },
        green: {
          divideColor: "divide-green-600",
        },
        teal: {
          divideColor: "divide-teal-600",
        },
        cyan: {
          divideColor: "divide-cyan-600",
        },
        "light-blue": {
          divideColor: "divide-light-blue-600",
        },
        blue: {
          divideColor: "divide-blue-600",
        },
        indigo: {
          divideColor: "divide-indigo-600",
        },
        "deep-purple": {
          divideColor: "divide-deep-purple-600",
        },
        purple: {
          divideColor: "divide-purple-600",
        },
        pink: {
          divideColor: "divide-pink-600",
        },
        red: {
          divideColor: "divide-red-600",
        },
      },
    },
  },
};

export { nucleusTheme };
