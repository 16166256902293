import Popover from "@components/Popover";
import { useClosestListingTableState } from "@features/listing";
import useQuickStart from "@features/quickStart";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { Button } from "@material-tailwind/react/components/Button";
import { capitalizeFirstLetter } from "@utils/utility";
import { isEqual } from "lodash-es";
import React, { useEffect, useState } from "react";
import ColumnsToggle, { type Option } from "./ColumnsToggle";

const TriggerComponent = React.forwardRef(
  ({ isOpen, ...props }: { isOpen: boolean }, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <Button variant="text" size="sm" className="group inline-flex items-center rounded-sm py-3" ref={ref} {...props}>
      <i className="fas fa-cog" />
    </Button>
  ),
);

function DisplaySettingsPopover() {
  const quickStart = useQuickStart();
  const { listingTableSnap, listingTableState } = useClosestListingTableState();

  const visibleColumns = listingTableSnap.visibleColumns;

  const [isOpen, setIsOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!visibleColumns?.length) {
      return;
    }

    setSelectedOptions(
      visibleColumns.map((column_id) => ({
        label: capitalizeFirstLetter(column_id.replace(/_/g, " ")),
        value: column_id as ThreatKey,
      })),
    );
  }, [visibleColumns]);

  const applyChanges = async () => {
    const areColumnsChanged = !isEqual(
      selectedOptions.map((sOpt) => sOpt.value),
      visibleColumns,
    );

    if (!areColumnsChanged) {
      return;
    }

    const new_visible_columns = selectedOptions.map((option) => option.value);

    await listingTableState.changeVisibleColumns(new_visible_columns);

    quickStart.markNextStepAsCompleted("configure_columns");
  };

  const handleSetOpen = (isOpen: boolean) => {
    if (!isOpen) {
      applyChanges();
    }
    setIsOpen(isOpen);
  };

  return (
    <Popover Trigger={TriggerComponent} isOpen={isOpen} setOpen={handleSetOpen}>
      <ColumnsToggle
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        recordsType={listingTableSnap.recordsType}
      />
    </Popover>
  );
}

export default DisplaySettingsPopover;
