import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const ZdiCanRenderer: (
  context: CellContext<FrontendSerializedThreat, FrontendSerializedThreat["zdi_can"]>,
) => JSX.Element = (context) => {
  const threat = context.row.original;

  if (!threat?.zdi_can) {
    return <span className="text-gray-400">-</span>;
  }

  return <span className="text-gray-600">{threat.zdi_can}</span>;
};

export default ZdiCanRenderer;
