import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import type { AbstractRecordsApiQueryResponse } from "../ListingTableState";
import { useClosestListingTableStateValue } from "./useClosestListingTableState";
import { useThreatsListing } from "./useThreatsListing";

/**
 * Generates the URL endpoint for threats listing based on the current table state. (Takes closest available context in case of nested usage)
 *
 * @return {string} The URL endpoint for threats listing.
 */
function useClosestContextListing<T extends AbstractRecordsApiQueryResponse>(
  options: Partial<UseQueryOptions<T, Error, T, QueryKey>> = {},
) {
  const listingTableSnap = useClosestListingTableStateValue();

  return useThreatsListing<T>(listingTableSnap.urlEndpoint, options);
}

export { useClosestContextListing };
