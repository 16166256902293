import { Tooltip } from "@material-tailwind/react/components/Tooltip";
import userLocalStorage from "@utils/userLocalStorage";
import { useEffect, useState } from "react";
import useFetchMutation from "../utils/useFetchMutation";

interface ImpersonateNotificationProps {
  username: string;
  currentPath: string;
}

function ImpersonatedBanner({ username, currentPath }: ImpersonateNotificationProps) {
  const [visible, setVisible] = useState<boolean>(() => {
    const storedVisibility = userLocalStorage.getItem<boolean>("impersonationVisible");
    return storedVisibility !== null ? storedVisibility : true;
  });

  const { mutateAsync, isPending, isError, error } = useFetchMutation<void, Error, void, unknown>(
    "/impersonate/release/",
    {},
    {
      onSuccess: () => {
        window.location.href = currentPath;
      },
      onError: (err) => {
        console.error("Error stopping impersonation:", err);
      },
    },
  );

  const handleShow = () => setVisible(true);

  const handleHide = () => {
    setVisible(false);
    userLocalStorage.setItem("impersonationVisible", false);
  };

  const handleStopImpersonate = async () => {
    await mutateAsync();
  };

  useEffect(() => {
    document.body.classList.add("impersonated-border");

    return () => {
      document.body.classList.remove("impersonated-border");
    };
  }, []);

  useEffect(() => {
    userLocalStorage.setItem("impersonationVisible", visible);
  }, [visible]);

  if (!visible) {
    return (
      <div
        onClick={handleShow}
        className="fixed bottom-2 right-0 transform -translate-x-1/2 z-[20] cursor-pointer border border-red-900 rounded-full h-10 w-10 flex items-center justify-center hover:bg-red-900 hover:text-white text-red-900 transition-all duration-300 bg-gray-50"
      >
        <Tooltip content="You are in impersonation mode.">
          <i className="fas fa-user-secret" />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 z-50 transition-transform duration-300">
      <div className="flex flex-wrap items-center rounded p-4 m-4 max-w-lg shadow-lg font-sans text-base bg-gray-50 text-gray-900 border border-gray-500">
        <div className="w-full pb-4">
          You are currently working on behalf of <em className="font-medium">{username}</em>.
        </div>
        <div className="w-full flex justify-end">
          <button
            type="button"
            onClick={handleHide}
            className="appearance-none bg-transparent font-medium px-2 py-1 cursor-pointer capitalize mr-2 text-gray-900 text-sm"
            disabled={isPending}
          >
            Hide warning
          </button>
          <button
            type="button"
            onClick={handleStopImpersonate}
            className="appearance-none bg-transparent font-medium px-2 py-1 cursor-pointer text-sm text-red-900 border border-red-900 rounded-md hover:bg-red-900 hover:text-white transition-all duration-300"
            disabled={isPending}
          >
            Stop session
          </button>
        </div>
        {isError && (
          <div className="w-full mt-2 text-red-500">{(error as any)?.message || "An unexpected error occurred."}</div>
        )}
      </div>
    </div>
  );
}

export { ImpersonatedBanner };
