import { useMutation, useQueryClient } from "@tanstack/react-query";
import getCookie from "@utils/getCookie";

import type { SerializedRiskLevel, SerializedRiskLevelPost } from "../interfaces";
import { organizationRiskLevelsUrlEndpoint } from "./useRiskLevelsQuery";

export function useMutateRiskLevels() {
  const queryClient = useQueryClient();
  return useMutation<
    SerializedRiskLevel[],
    Error,
    { riskLevels: SerializedRiskLevelPost[]; keep_manual_risk_levels: boolean }
  >({
    mutationFn: async ({ riskLevels, keep_manual_risk_levels }) => {
      return fetch(organizationRiskLevelsUrlEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          risk_levels: riskLevels,
          keep_manual_risk_levels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error);
          }

          const responseRiskLevels = response as SerializedRiskLevel[];
          if (!Array.isArray(responseRiskLevels)) {
            throw new Error("No risk levels returned from server");
          }

          queryClient.setQueryData(["riskLevels"], responseRiskLevels);
          return response;
        });
    },
  });
}
