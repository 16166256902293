import autoFixSearchQuery from "@components/DjangoQL/autoFixSearchQuery";
import { RecordsType, getListingEndpointUrl } from "@features/listing";
import type { SerializedMandiantMalwareKey } from "@interfaces/SerializedMandiantMalware";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { type ThreatModelConfiguration, getIntrospections } from "@queries/useIntrospections";
import type { ColumnDef, ColumnSizingState, ColumnSort } from "@tanstack/react-table";
import { getSearchQueryWithValidation } from "@utils/searchQueryToUrl";
import userLocalStorage from "@utils/userLocalStorage";

type RecordsLoadUpdate = Pick<
  ListingTableState,
  | "isFetching"
  | "dataError"
  | "isLoading"
  | "isEnabled"
  | "total_pages"
  | "results"
  | "bookmarked_count"
  | "total_count"
>;

interface AbstractRecordsApiQueryResponse {
  bookmarked_count: number;
  total_count: number;
  meta: {
    total_pages: number;
    page: number;
    per_page: number;
  };
  results: Record<string, any>[];
  sorting?: string[];
}

type VisibleColumn = keyof ThreatModelConfiguration | SerializedMandiantMalwareKey;

interface ListingTableProps {
  recordsType: RecordsType;
  changeVisibleColumns?: (columns: (keyof ThreatModelConfiguration | SerializedMandiantMalwareKey)[]) => any;
  useVisibleColumns?: () => VisibleColumn[];
  visibleColumns?: VisibleColumn[];
  displayFeedsRow?: boolean;
  tableMaxHeight?: number;

  persistKey?: string;
}

const minColumnSizing: ColumnSizingState = {
  mandiant_exec_summary: 400,
  cve_id: 160,
  vip_id: 160,
  nvd_description: 300,
};

const defaultColumnSizing: ColumnSizingState = {
  ...minColumnSizing,
  vip_favorite: 34,
  mandiant_risk_rating: 180,
  mandiant_exploit_rating: 206,
  nvd_cvssv2_base_score: 74,
  nvd_cvssv3_base_score: 74,
  epss_score: 98,
  cisa_date_added_to_catalog: 128,
  cisa_due_date: 110,
};

const DEFAULT_PER_PAGE = 25;

const DEFAULT_LISTING_TABLE_STATE: ListingTableState["_state"] = {
  searchQuery: "",
  page: 0,
  perPage: DEFAULT_PER_PAGE,
  columnSizing: {},
  sorting: [],
};

interface ListingTableStatePersist {
  searchQuery: string;
  page: number;
  perPage: number;
  columnSizing: Record<string, number>;
  sorting: readonly ColumnSort[];
}

class ListingTableState implements ListingTableProps {
  tableMaxHeight?: number;

  // Whether each row should have a feed icons row
  displayFeedsRow = true;

  // Whether the table has export functionality
  recordsType: RecordsType;

  // Various listing tables may have different columns selected, so we need to keep track of them
  visibleColumns: VisibleColumn[] = [];
  useVisibleColumns: () => VisibleColumn[];

  private _changeVisibleColumns: ListingTableProps["changeVisibleColumns"];

  // from the records query
  isFetching = false;
  isLoading = false;
  isEnabled = true;
  dataError: string | undefined = undefined;

  // columns configuration for table
  columnsConfiguration!: ColumnDef<any, any>[];

  // context listing table state
  private _state: ListingTableStatePersist;

  // persisting state when needed
  persistKey?: string;
  proxyState!: typeof this._state;

  showBookmarkedOnly = false;

  // from the records query
  total_count?: number;
  bookmarked_count?: number;
  total_pages = 0;
  results?: Record<string, any>[];

  constructor(initialState: ListingTableProps) {
    this.recordsType = initialState.recordsType;
    this.visibleColumns = initialState.visibleColumns ?? [];

    if (initialState.changeVisibleColumns) {
      this._changeVisibleColumns = initialState.changeVisibleColumns;
    }

    this.useVisibleColumns = initialState.useVisibleColumns
      ? initialState.useVisibleColumns
      : () => this.visibleColumns;

    if (initialState.displayFeedsRow !== undefined) {
      this.displayFeedsRow = initialState.displayFeedsRow;
    }

    if (initialState.tableMaxHeight !== undefined) {
      this.tableMaxHeight = initialState.tableMaxHeight;
    }

    this.persistKey = initialState.persistKey;
    this._state = {
      ...DEFAULT_LISTING_TABLE_STATE,
    };

    this.loadState();
  }

  private persistState() {
    if (this.persistKey) {
      userLocalStorage.setItem(this.persistKey, this._state);
    }
  }

  private loadState() {
    if (this.persistKey) {
      const savedState = userLocalStorage.getItem<ListingTableStatePersist>(this.persistKey);

      if (savedState?.searchQuery !== undefined) {
        this._state = savedState;
      } else {
        this._state = DEFAULT_LISTING_TABLE_STATE;
      }
    }
  }

  getState() {
    return this._state;
  }

  /**
   *
   * @param {RecordsLoadUpdate} newState - the new state to update
   */
  onDataUpdate(newState: RecordsLoadUpdate) {
    // Update primitive properties
    this.isFetching = newState.isFetching;
    this.dataError = newState.dataError;
    this.isLoading = newState.isLoading;
    this.isEnabled = newState.isEnabled;

    // Update query results data
    this.total_count = newState.total_count;
    this.bookmarked_count = newState.bookmarked_count;
    this.total_pages = newState.total_pages;
    this.results = newState.results;
  }

  updateColumnsConfiguration(columnsConfiguration: ColumnDef<any, any>[]): void {
    const compareColumnConfigurations = (a: ColumnDef<any, any>[], b: ColumnDef<any, any>[]): boolean => {
      if (a.length !== b.length) {
        return false;
      }

      for (let i = 0; i < a.length; i++) {
        const columnA = a[i];
        const columnB = b[i];

        if (
          columnA?.id !== columnB?.id ||
          columnA?.size !== columnB?.size ||
          columnA?.minSize !== columnB?.minSize ||
          columnA?.enableResizing !== columnB?.enableResizing ||
          columnA?.enableSorting !== columnB?.enableSorting
        ) {
          return false;
        }
      }

      return true;
    };

    if (
      (!this.columnsConfiguration && columnsConfiguration.length > 0) ||
      (columnsConfiguration?.length > 0 &&
        !compareColumnConfigurations(columnsConfiguration, this.columnsConfiguration))
    ) {
      this.columnsConfiguration = columnsConfiguration;
    } else {
      // Skip updating the columnsConfiguration since they are the same
    }
  }

  get totalResults() {
    return this.total_count;
  }

  get totalBookmarkedCount() {
    return this.bookmarked_count;
  }

  get totalPages() {
    return this.total_pages;
  }

  // extending changeVisibleColumns to also perform side effects if needed
  // todo: better naming for this one
  public changeVisibleColumns(columns: (keyof ThreatModelConfiguration | SerializedMandiantMalwareKey)[]) {
    if (!this._changeVisibleColumns) {
      return;
    }

    this._changeVisibleColumns(columns);

    if (columns && columns.length > 0) {
      if (this.sorting && this.sorting.length > 0) {
        const sortedValues = this.sorting;

        if (!sortedValues.every((value) => columns.includes(value as any))) {
          this.updateSorting(this.sorting.filter((sort) => columns.includes(sort.id as ThreatKey)));
        }
      }
    }
  }

  get hasColumnsConfiguration() {
    return this._changeVisibleColumns && this.columnsConfiguration && this.columnsConfiguration.length > 0;
  }

  get sorting() {
    return this._state.sorting;
  }

  get filters() {
    return {
      page: this._state.page,
      perPage: this._state.perPage,
      sorting: this._state.sorting,
    };
  }

  updateSearchQuery(value: string) {
    const introspections = getIntrospections();

    if (value !== "" && introspections) {
      value = autoFixSearchQuery(value, introspections);

      if (getSearchQueryWithValidation(value, introspections) === "") {
        return false;
      }
    }

    if (value !== this._state.searchQuery) {
      this.updatePagination(0, this._state.perPage);
    }

    this._state.searchQuery = value;
    this.persistState();

    return true;
  }

  get searchQueryUrl() {
    const introspections = getIntrospections();

    return getSearchQueryWithValidation(this.searchQuery, introspections!);
  }

  get urlEndpointVisibleColumns() {
    if (this.recordsType === RecordsType.Malware) {
      return this.visibleColumns;
    }

    return [...this.visibleColumns, "vip_intels", "vip_favorite"];
  }

  private getUrlEndpointForPage(page: number) {
    return getListingEndpointUrl({
      recordsType: this.recordsType,
      options: {
        page: page,
        perPage: this._state.perPage,
        sorting: this._state.sorting,
      },
      visibleColumns: this.urlEndpointVisibleColumns,
      searchQueryUrl: this.searchQueryUrl,
      onlyBookmarked: this.showBookmarkedOnly,
    });
  }

  get urlEndpoint() {
    return this.getUrlEndpointForPage(this._state.page + 1);
  }

  getNextPageEndpoint() {
    if (this.total_pages && this._state.page + 1 < this.total_pages) {
      return this.getUrlEndpointForPage(this._state.page + 2); // +1 for next page, +1 because pages are 0-indexed internally
    }

    return null;
  }

  getPreviousPageEndpoint() {
    // Check if it's the first page
    if (this._state.page > 0) {
      return this.getUrlEndpointForPage(this._state.page); // -1 because pages are 0-indexed internally
    }
    return null;
  }

  updatePagination(page: number, perPage: number) {
    this._state.page = page;
    this._state.perPage = perPage;
    this.persistState();
  }

  updateColumnSizing(columnSizing: ColumnSizingState) {
    this._state.columnSizing = columnSizing;
    this.persistState();
  }

  updateSorting(sorting: readonly ColumnSort[]) {
    this._state.sorting = sorting;
    this.persistState();
  }

  get isSearchQueryEmpty() {
    return this.searchQuery === "";
  }

  get searchQuery() {
    return this._state.searchQuery;
  }

  setShowBookmarkedOnly(showBookmarkedOnly: boolean) {
    this.showBookmarkedOnly = showBookmarkedOnly;
  }
}

export { ListingTableState, defaultColumnSizing, minColumnSizing };

export type { ListingTableProps, AbstractRecordsApiQueryResponse };
