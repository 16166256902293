import { useCreateToast } from "@hooks/useCreateToast";
import useGlobalVariables from "@hooks/useGlobalVariables";
import type SerializedNote from "@interfaces/SerializedNote";
import { type UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation from "@utils/useFetchMutation";

const endpoint = (id?: number) => `/api/v1/notes${id ? `/${id}` : ""}/`;
const notesUrlByThreatEndpoint = (threat_id: number) => `/api/v1/notes/by_threat/${threat_id}/`;

const NOTES_QUERY_KEY = (currentUserID: number, cve_threat_intel_id: number) => [
  "notesByThreat",
  currentUserID,
  cve_threat_intel_id,
];

type NotesApiResponse = SerializedNote[];

async function fetchNotes(threat_id: number): Promise<NotesApiResponse> {
  return fetchWithSession(notesUrlByThreatEndpoint(threat_id));
}

function useMutateNote(note?: SerializedNote, isDelete?: boolean) {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();
  const { addMessage } = useCreateToast();

  const method = isDelete ? "DELETE" : note?.id ? "PATCH" : "POST";
  return useFetchMutation<
    SerializedNote,
    Error,
    Pick<SerializedNote, "threat_id" | "visibility" | "content"> & Partial<SerializedNote>,
    unknown
  >(
    endpoint(note?.id),
    (data) => data,
    {
      onSuccess: async (res) => {
        // for DELETE, we don't get a response, so we need to use the note
        const threat_id = note?.threat_id || res?.threat_id;
        if (threat_id) {
          await queryClient.invalidateQueries({ queryKey: NOTES_QUERY_KEY(currentUserID, threat_id) });
        }

        const message = isDelete ? "deleted" : note?.id ? "updated" : "created";
        addMessage({
          title: "Done!",
          content: `Note ${message}.`,
          variant: "success",
          duration: 3000,
        });

        return res;
      },
      onError: () => {
        addMessage({
          title: "Error!",
          content: "Something went wrong. Please try again later.",
          variant: "error",
          duration: 5000,
        });
      },
    },
    method,
  );
}

function useNotes<TSelectedData = NotesApiResponse>(
  cve_threat_intel_id: number,
  options: Partial<UseQueryOptions<NotesApiResponse, unknown, TSelectedData>> = {},
) {
  const { currentUserID } = useGlobalVariables();
  return useQuery({
    queryKey: NOTES_QUERY_KEY(currentUserID, cve_threat_intel_id),
    queryFn: () => fetchNotes(cve_threat_intel_id),
    ...options,
  });
}

export { useMutateNote };
export default useNotes;
