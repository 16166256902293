import { useCallback, useEffect, useState } from "react";

interface SearchParamsActions {
  get: (key: string) => string | null;
  getAll: (key: string) => string[];
  set: (key: string, value: string) => void;
  append: (key: string, value: string) => void;
  delete: (key: string) => void;
  has: (key: string) => boolean;
}

function useSearchParams(): [URLSearchParams, SearchParamsActions] {
  const [searchParams, setSearchParams] = useState<URLSearchParams>(() => new URLSearchParams(window.location.search));

  useEffect(() => {
    const handlePopState = () => {
      setSearchParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  const updateURL = useCallback((newSearchParams: URLSearchParams) => {
    const newURL = `${window.location.pathname}?${newSearchParams.toString()}`;
    window.history.pushState({}, "", newURL);
  }, []);

  const actions: SearchParamsActions = {
    get: useCallback((key: string) => searchParams.get(key), [searchParams]),
    getAll: useCallback((key: string) => searchParams.getAll(key), [searchParams]),
    set: useCallback(
      (key: string, value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(key, value);
        setSearchParams(newSearchParams);
        updateURL(newSearchParams);
      },
      [searchParams, updateURL],
    ),
    append: useCallback(
      (key: string, value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.append(key, value);
        setSearchParams(newSearchParams);
        updateURL(newSearchParams);
      },
      [searchParams, updateURL],
    ),
    delete: useCallback(
      (key: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(key);
        setSearchParams(newSearchParams);
        updateURL(newSearchParams);
      },
      [searchParams, updateURL],
    ),
    has: useCallback((key: string) => searchParams.has(key), [searchParams]),
  };

  return [searchParams, actions];
}

export { useSearchParams };