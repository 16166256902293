import type { ColumnSort } from "@tanstack/react-table";

import { RecordsType } from "../enums";

interface CustomEndpointUrlProps {
  page?: number;
  perPage?: number;
  isExport?: boolean;
  isCountOnly?: boolean;
  exportFormat?: string;
  sorting?: readonly ColumnSort[];
}

const DEFAULT_PER_PAGE = 25;

function getListingEndpointUrl({
  recordsType = RecordsType.CVEs,
  options = {},
  visibleColumns = [],
  searchQueryUrl = "",
  onlyBookmarked = false,
}: {
  recordsType?: RecordsType;
  options?: CustomEndpointUrlProps;
  visibleColumns?: string[];
  searchQueryUrl?: string;
  onlyBookmarked?: boolean;
}) {
  let urlEndpoint = recordsType === RecordsType.CVEs ? "/api/v1/threats/?" : "/api/v1/malwares/?";

  // pagination [pages start from 1 on backend, but on 0 on frontend]
  urlEndpoint += `page=${options.page ?? 1}&`;

  urlEndpoint += `per_page=${options?.perPage ?? DEFAULT_PER_PAGE}&`;

  if (options?.sorting && options.sorting.length > 0) {
    urlEndpoint += `sort[]=${options.sorting[0].desc ? "-" : ""}${options.sorting[0].id}&`;
  }

  // ? include only visible columns (maybe worth limiting here and make it mandatory to pass visible columns?)
  if (Array.isArray(visibleColumns) && visibleColumns.length > 0) {
    for (const column of visibleColumns) {
      urlEndpoint += `include[]=${column}&`;
    }
  }

  if (searchQueryUrl) {
    urlEndpoint += `q=${encodeURIComponent(searchQueryUrl)}&`;
  }

  if (options.isExport) {
    urlEndpoint += "is_export=True&";

    if (options.exportFormat) {
      urlEndpoint += `export_format=${options.exportFormat}&`;
    }
  }

  if (options.isCountOnly) {
    urlEndpoint += "is_count_only=True&";
  }

  if (onlyBookmarked) {
    urlEndpoint += "only_bookmarked=True&";
  }

  return urlEndpoint;
}

export { getListingEndpointUrl };
