import { useContext } from "react";
import { useSnapshot } from "valtio";
import { NucleusQLContext } from "./NucleusQLProvider";
import type { NucleusQLState } from "./NucleusQLState";

function useClosestNucleusQLState() {
  const nucleusQLState = useContext(NucleusQLContext);
  const nucleusQLStateSnap = useSnapshot<NucleusQLState>(nucleusQLState);

  return [nucleusQLState, nucleusQLStateSnap] as const;
}

export { useClosestNucleusQLState };