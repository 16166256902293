import classNames from "classnames";
import React from "react";

import Badge from "@components/Badge";
import { useClosestListingTableStateValue } from "@features/listing";

import DisplaySettingsPopover from "./TableTopControls/DisplaySettingsPopover";

function TableTopControls() {
  const { totalResults, isEnabled, hasColumnsConfiguration, isFetching } = useClosestListingTableStateValue();

  const badgeValue = isFetching && !totalResults ? "..." : (totalResults || 0).toLocaleString();
  return (
    <div className="md:flex-row flex flex-col items-start justify-center py-2">
      <span
        className={classNames("flex flex-row self-center gap-1.5 px-0", {
          "animate-pulse": isFetching,
        })}
      >
        <span className="text-gray-1000 text-lg font-semibold text-center">Results</span>
        <Badge size="small">{isEnabled ? badgeValue : "N/A"}</Badge>
      </span>

      <div className="flex-1" />
      <div className="md:mt-0 lg:flex-row flex flex-col items-center gap-1 mt-1">
        {hasColumnsConfiguration ? (
          <React.Suspense fallback={<div className="h-6 w-6" />}>
            <DisplaySettingsPopover />
          </React.Suspense>
        ) : null}
      </div>
    </div>
  );
}

export default TableTopControls;
