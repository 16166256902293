import React from "react";

// import { Link } from "@components/Link";
import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { CellContext } from "@tanstack/react-table";

/**
 * 
 * interface SerializedMandiantMalwareCVE {
  cve_id: string;
  id: string;
}
 */
const MalwareCVEs: (context: CellContext<SerializedMandiantMalware, SerializedMandiantMalware["cves"]>) => JSX.Element =
  (context) => {
    const cves = context.getValue();

    if (!Array.isArray(cves)) {
      return <>-</>;
    }

    return (
      <div className="flex flex-wrap">
        {cves.map((cve, index) => (
          <span key={`cve_${index}_${cve.cve_id}`}>
            {/* <Link
            href={`/v/${cve.cve_id}`}
            target="_blank"
            rel="noreferrer"
            className="text-xs font-bold"
          > */}
            {cve.cve_id}
            {/* <i className="fas fa-external-link-alt inline ml-1 text-xs"></i> */}
            {/* </Link> */}
            {index < cves.length - 1 ? ", " : ""}
          </span>
        ))}
      </div>
    );
  };

export default MalwareCVEs;
