import { BrowserRouter, Route, Routes } from "react-router";

import { GlobalLoadingIndicator } from "@components/GlobalLoadingIndicator";
import { NucleusNavbar } from "@components/NucleusNavbar";
import { NucleusQLProvider } from "@components/NucleusQL/NucleusQLProvider";
import { QueryErrorBoundary } from "@components/QueryErrorBoundary";
import TemplateSlot from "@utils/TemplateSlot";
import { Suspense } from "react";
import { ListingTableWrapper } from "./ListingTableWrapper";
import Portals from "./Portals";
import Providers from "./Providers";

import { is_flag_enabled } from "@utils/is_flag_enabled";
import {
  AlertsPage,
  GlobalTagsPage,
  IndexPage,
  ListingPage,
  LoginPage,
  MyOrganizationPage,
  ProfilePage,
  SourcesPage,
  StorePage,
  VulnerabilityPage,
  VulnerabilityPagePDF,
  WatchingPage,
} from "./LazyImports";

function Router() {
  const isNewDashboardEnabled = is_flag_enabled("NEW_DASHBOARD_FLAG");

  return (
    <BrowserRouter>
      <Providers>
        <NucleusQLProvider key="global-search-widget">
          <ListingTableWrapper>
            <Suspense fallback={<div />}>
              <TemplateSlot slotID="slot_navbar" keepContent={false} keepClasses={false}>
                <QueryErrorBoundary>
                  <NucleusNavbar />
                </QueryErrorBoundary>
              </TemplateSlot>
            </Suspense>
            <TemplateSlot slotID="slot_globalLoader">
              <GlobalLoadingIndicator />
            </TemplateSlot>

            {/* Routes with shared Suspense */}
            <QueryErrorBoundary>
              <Suspense
                fallback={
                  <div className="flex flex-col items-center justify-center min-h-[200px] p-8">
                    <i className="far fa-spinner-third fa-spin fa-2x text-gray-500 mb-3" />
                    <p className="text-gray-600 font-medium">Navigating to page...</p>
                  </div>
                }
              >
                <Routes>
                  <Route path="/" element={isNewDashboardEnabled ? <IndexPage /> : <ListingPage />} />
                  <Route path="/analyze" element={<ListingPage />} />
                  {/* <Route path="/malware" element={<MalwarePage />} /> */}
                  <Route path="/v/:vip_id" element={<VulnerabilityPage />} />
                  <Route path="/organization" element={<MyOrganizationPage />} />
                  <Route path="/integrations" element={<StorePage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/monitoring" element={<WatchingPage />} />
                  <Route path="/monitoring/alerts" element={<AlertsPage />} />
                  <Route path="/sources" element={<SourcesPage />} />
                  <Route path="/manage_global_tags" element={<GlobalTagsPage />} />
                  <Route path="/login" element={<LoginPage />} />

                  {/* todo no navbar */}
                  <Route path="/cve_pdf/:vip_id" element={<VulnerabilityPagePDF />} />
                </Routes>
              </Suspense>
            </QueryErrorBoundary>

            {/* Custom portal root for dialogs to stay in the listing table wrapper */}
            <div id="dialog-root" />
          </ListingTableWrapper>
        </NucleusQLProvider>
        <Portals />
      </Providers>
    </BrowserRouter>
  );
}

export { Router };
