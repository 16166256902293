import type { TagSelectOption } from "@features/tags/tags.interfaces";
import { Fragment } from "react";
import type { ActionMeta, OnChangeValue } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";

interface TagSelectorProps {
  selectedTags: TagSelectOption[];
  onTagChange: (newValue: OnChangeValue<TagSelectOption, true>, actionMeta: ActionMeta<TagSelectOption>) => void;
  onTagCreate: (inputValue: string) => void;
  loadOptions: (inputValue: string, callback: (options: TagSelectOption[]) => void) => void;
  maxSelected?: number;
  controlStyles?: React.CSSProperties;
  isDisabled?: boolean;
}

function TagSelector({
  selectedTags,
  onTagChange,
  onTagCreate,
  loadOptions,
  maxSelected = 10,
  controlStyles,
  isDisabled = false,
}: TagSelectorProps) {
  const handleTagChange = (newValue: OnChangeValue<TagSelectOption, true>, actionMeta: ActionMeta<TagSelectOption>) => {
    if (newValue.length <= maxSelected) {
      onTagChange(newValue, actionMeta);
    }
  };

  const handleCreateTag = (inputValue: string) => {
    if (selectedTags.length < maxSelected) {
      onTagCreate(inputValue);
    }
  };

  const isMaxSelected = selectedTags.length >= maxSelected;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-2 justify-end">
        <AsyncCreatableSelect
          className="flex-grow min-w-96"
          isMulti
          isDisabled={isDisabled}
          defaultOptions
          value={selectedTags}
          loadOptions={loadOptions}
          onChange={handleTagChange}
          onCreateOption={handleCreateTag}
          placeholder="Add a Tag..."
          noOptionsMessage={() => (isMaxSelected ? "Maximum tags reached" : "No tags found")}
          isClearable={selectedTags.some((tag) => tag.isEditable)}
          formatCreateLabel={(inputValue) => (
            <Fragment>
              {isMaxSelected ? (
                <span className="text-gray-400">Maximum tags reached</span>
              ) : (
                <>
                  <span className="font-medium">Create and add a new tag:</span>{" "}
                  <span className="text-blue-600">{inputValue}</span>
                </>
              )}
            </Fragment>
          )}
          formatOptionLabel={(option) => <span className="font-medium">{option.label}</span>}
          menuPortalTarget={document.body} // Render menu in portal
          styles={{
            control: (base) => ({
              ...base,
              ...controlStyles,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown is above other elements
            multiValue: (base, state) => (!state.data.isEditable ? { ...base, backgroundColor: "gray" } : base),
            multiValueLabel: (base, state) =>
              !state.data.isEditable
                ? {
                    ...base,
                    fontWeight: "bold",
                    color: "white",
                    paddingRight: 6,
                  }
                : base,
            multiValueRemove: (base, state) => (!state.data.isEditable ? { ...base, display: "none" } : base),
            option: (base, state) => ({
              ...base,
              color: state.isDisabled ? "gray" : base.color,
            }),
          }}
          menuPosition="fixed" // Position menu relative to viewport
          isOptionDisabled={() => isMaxSelected} // Disable Options When Max Reached
        />
      </div>
      {isMaxSelected && (
        <p className="text-sm text-red-600 mt-1">You have reached the maximum of {maxSelected} tags.</p>
      )}
    </div>
  );
}

export { TagSelector };
