import { Suggestion } from "@components/DjangoQL/Suggestion";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import type { IntrospectionsResponse, ModelPropertyConfiguration } from "@queries/useIntrospections";

export const getFieldConfig: (
  introspections: IntrospectionsResponse,
  fieldName?: ThreatKey | null | undefined,
) => ModelPropertyConfiguration | null = (introspections, fieldName) => {
  if (!fieldName) {
    return null;
  }

  fieldName = Suggestion.resolveFieldName(fieldName);
  if (fieldName && fieldName in introspections.fields) {
    return introspections.fields[fieldName];
  }

  return null;
};
