import { getGlobalVariables } from "@hooks/useGlobalVariables";
import { safeParseJSON } from "./utility";

function getUserID(): string {
  const { currentUserID } = getGlobalVariables();
  return currentUserID.toString();
}

function getPrefixedKey(key: string): string {
  const userID = getUserID();
  return `${userID}:${key}`;
}

const userLocalStorage = {
  setItem(key: string, value: any): void {
    try {
      const prefixedKey = getPrefixedKey(key);
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(prefixedKey, serializedValue);
    } catch (e) {
      console.error(`Error setting localStorage item '${key}':`, e);
    }
  },

  getItem<T>(key: string): T | null {
    try {
      const prefixedKey = getPrefixedKey(key);
      const item = localStorage.getItem(prefixedKey);
      return safeParseJSON<T>(item);
    } catch (e) {
      console.error(`Error getting localStorage item '${key}':`, e);
      return null;
    }
  },

  removeItem(key: string): void {
    try {
      const prefixedKey = getPrefixedKey(key);
      localStorage.removeItem(prefixedKey);
    } catch (e) {
      console.error(`Error removing localStorage item '${key}':`, e);
    }
  },

  clearUserStorage(): void {
    try {
      const userID = getUserID();
      const keysToRemove: string[] = [];

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith(`${userID}:`)) {
          keysToRemove.push(key);
        }
      }

      for (const key of keysToRemove) {
        localStorage.removeItem(key);
      }
    } catch (e) {
      console.error("Error clearing localStorage for user:", e);
    }
  },
};

// biome-ignore lint/style/noDefaultExport: <ok for single modules>
export default userLocalStorage;
