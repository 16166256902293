import { isObject } from "lodash-es";
import React from "react";

import type { FrontendSerializedThreat, Intel471ChatterTrend } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const Intel471ChatterTrendRenderer: (
  context: CellContext<FrontendSerializedThreat, Intel471ChatterTrend>,
) => JSX.Element = (context) => {
  // value = {
  //   "2023-06-15": {
  //     "chatter": 4,
  //     "instant_messages": 2,
  //     "posts": 2,
  //     "private_messages": 0
  //   },
  //   "2023-06-16": {
  //     "chatter": 0,
  //     "instant_messages": 0,
  //     "posts": 0,
  //     "private_messages": 0
  //   },
  // }
  const value = context.getValue();
  if (!value || isObject(value) === false) {
    return <div>-</div>;
  }

  // We display amount of days with chatter
  return <div>{Object.keys(value).length}</div>;
};

export default Intel471ChatterTrendRenderer;
