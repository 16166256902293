import type { FIELD_TYPE } from "@components/DjangoQL/utils";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { useSnapshot } from "valtio";
import { proxy, snapshot } from "valtio/vanilla";

type FieldReferenceState = {
  // comes from model introspections
  id: ThreatKey;
  type?: FIELD_TYPE;
  nullable?: boolean;
  name?: string;
  autocomplete?: boolean;
  searchable?: boolean;
  priority?: number;

  // comes from field references
  description?: string;
  // combined from model introspections and field references
  possible_values?: string[];
};

type FieldReferencesFieldsState = Partial<{
  [key in ThreatKey]: FieldReferenceState;
}>;

type FieldReferencesSyncState = {
  isReady: boolean;
  fields: FieldReferencesFieldsState;
};

const fieldReferencesState = proxy<FieldReferencesSyncState>({
  isReady: false,
  fields: {},
});

function updateFieldReferencesState(_fieldReferences: FieldReferencesFieldsState) {
  for (const [key, value] of Object.entries(_fieldReferences)) {
    if (key && value) {
      fieldReferencesState.fields[key as ThreatKey] = value;
    }
  }
  fieldReferencesState.isReady = true;
}

function getFieldReferencesFieldsSnapshot() {
  return snapshot(fieldReferencesState).fields;
}

function useFieldReferencesSnapshot() {
  return useSnapshot(fieldReferencesState);
}

export { updateFieldReferencesState, getFieldReferencesFieldsSnapshot, useFieldReferencesSnapshot };
export type { FieldReferencesSyncState, FieldReferenceState, FieldReferencesFieldsState };
