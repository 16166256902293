import React from "react";

import Tooltip from "@components/Tooltip";
import { MandiantSourceInstance } from "@interfaces/MandiantSourceInstance";
import type { FrontendSerializedThreat, MandiantSource } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_SOURCES_TO = 3;
const MandiantSourcesRenderer: (context: CellContext<FrontendSerializedThreat, MandiantSource[]>) => JSX.Element = (
  context,
) => {
  const value = context.getValue();

  if (!Array.isArray(value)) {
    return <>-</>;
  }

  const instances = value.map((source) => new MandiantSourceInstance(source));

  const renderSource = (source: MandiantSourceInstance, index) => (
    <span key={`mandiant_sources_${index}`} className="mr-2">
      {source.getUrl() ? (
        <a
          className="ease-nav-brand text-slate-600 hover:text-blue-700 inline-flex items-start font-semibold transition-all"
          href={source.getUrl()}
          target="_blank"
          rel="noreferrer"
        >
          <span>
            {source.getTitle()}
            <i className="fas fa-external-link-alt ml-1 text-xs" />
          </span>
        </a>
      ) : (
        source.getTitle()
      )}
    </span>
  );

  const fullView = <div>{instances.map(renderSource)}</div>;

  if (instances.length > LIMIT_SOURCES_TO) {
    return (
      <div>
        <div>{instances.slice(0, LIMIT_SOURCES_TO).map((source, index) => renderSource(source, index))}</div>
        <Tooltip value={fullView}>
          <div className="cursor-progress mt-2 text-xs text-right text-gray-600">
            And {value.length - LIMIT_SOURCES_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export default MandiantSourcesRenderer;
