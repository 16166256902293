import { DateTime } from "luxon";
import React from "react";

function LastUpdated({ value }: { value?: string }) {
  const lastUpdated = value ? DateTime.fromISO(value).toRelative() : "Never";

  return <h5 className="px-3 py-1 text-xs">Last updated: {lastUpdated}</h5>;
}

export default LastUpdated;
