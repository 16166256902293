import type React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSON } from "@utils/utility";

const JSONCellRenderer: React.FunctionComponent<CellContext<FrontendSerializedThreat, string>> = (context) => {
  const safeParsed = safeParseJSON(context.getValue());

  if (!safeParsed || !Array.isArray(safeParsed) || safeParsed.length === 0) {
    return <span>-</span>;
  }

  return <span>{safeParsed.join(", ")}</span>;
};

export default JSONCellRenderer;
