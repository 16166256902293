import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

const MandiantAliasesRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = context.getValue();

  const aliases = isArrayLikeString(value) ? safeParseJSONtoArray(value) : [];

  if (aliases.length > 0) {
    return (
      <div>
        {aliases
          .map((source, index) => <span key={`aliases-${index}`}>{source}</span>)
          .reduce((prev, curr) => [prev, ", ", curr])}
      </div>
    );
  }

  return <div>-</div>;
};

export default MandiantAliasesRenderer;
