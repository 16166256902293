import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, SecurinPlugin } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 10;
const SecurinPluginsRenderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const values = safeParseJSONtoArray<SecurinPlugin>(context.getValue());

  if (!values || !Array.isArray(values)) {
    return <>-</>;
  }

  const renderSource = (plugin: SecurinPlugin, index: number) =>
    plugin?.scannerName ? (
      <span key={`securin_plugin_${index}`}>
        {plugin.scannerName}
        {index !== values.length - 1 ? <span>, </span> : null}
      </span>
    ) : (
      <span key={`securin_plugin_${index}`}>Unknown source</span>
    );

  const fullView = <div>{values.map(renderSource)}</div>;

  if (values.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{values.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderSource(source, index))}</div>
        <Tooltip value={fullView}>
          <div className="cursor-progress mt-2 text-xs text-right text-gray-600">
            And {values.length - LIMIT_RECORDS_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};

export default SecurinPluginsRenderer;
