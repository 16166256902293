import { colord } from "colord";

const getColorByIndex = (index: number, totalColors: number) => {
  const hue = (index / totalColors) * 140;
  const saturation = 54;
  const lightness = 66;
  const colorString = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  const color = colord(colorString);
  return color.toRgbString();
};

export const generatePriorityColors = (totalPriorities: number) => {
  return Array.from({ length: totalPriorities }, (_, index) => {
    const color = getColorByIndex(index, totalPriorities);
    return color;
  });
};
