import { isBoolean } from "lodash-es";
import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const MandiantBooleanRenderer: (context: CellContext<FrontendSerializedThreat, boolean>) => JSX.Element = (context) => {
  const value = context.getValue();

  if (!isBoolean(value)) {
    return <>-</>;
  }

  return value ? (
    <span className="text-xs font-semibold text-red-500">True</span>
  ) : (
    <span className="text-xs font-semibold text-green-500">False</span>
  );
};

export default MandiantBooleanRenderer;
