import React from "react";

import Badge from "@components/Badge";
import { numberValueToColor } from "@configs/colors";
import type { FrontendSerializedThreat, ThreatKey } from "@interfaces/SerializedThreat";
import useIntrospections from "@queries/useIntrospections";
import type { CellContext } from "@tanstack/react-table";

const floatToPercentValue = (value: number) => {
  return +(value * 100).toFixed(2);
};

const ColoredNumericLabel: (context: CellContext<FrontendSerializedThreat, number>) => JSX.Element = (context) => {
  const value = context.getValue();
  const {
    data: { fields: fieldsDefinition },
  } = useIntrospections(); // ? is calling hook here is expensive?

  if (!value) {
    return <span className="text-gray-400">-</span>;
  }

  const fieldID = context.column.id as ThreatKey;
  const colorConfiguration = numberValueToColor(fieldID, value);

  const constrains = fieldsDefinition[fieldID]?.constrains;
  if (colorConfiguration) {
    const displayValue = constrains?.display_type === "percents" ? `${floatToPercentValue(value)}%` : value.toString();

    return <Badge color={colorConfiguration}>{displayValue}</Badge>;
  }

  return <>{value.toString()}</>;
};

export default ColoredNumericLabel;
