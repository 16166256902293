import useQuickStart, { useQuickStartControls } from "@features/quickStart";
import classNames from "classnames";

import { RadialProgress } from "../RadialProgress";

function QuickStart() {
  const quickStart = useQuickStart();
  const { openClose } = useQuickStartControls();

  if (!quickStart.enabled) {
    return null;
  }

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openClose(true);
  };

  return (
    <button
      className={classNames(
        "hidden sm:flex sm:flex-row sm:items-center",
        "md:min-w-[108px] md:w-[108px] h-8",
        "rounded h-8 px-1.5 leading-8",
        "transition-colors duration-200",
        "border-2 border-gray-400",
        "hover:border-gray-500 hover:bg-gray-100",
        {
          // Completed state
          "hover:text-green-500": quickStart.isCompleted,
          // In-progress state
          "hover:text-blue-500": !quickStart.isCompleted,
        },
      )}
      onClick={handleOpen}
    >
      <RadialProgress
        percent={quickStart.percent}
        color={quickStart.isCompleted ? "text-green-500" : "text-blue-500"}
        textSize="text-[7px]"
      >
        {quickStart.progressText}
      </RadialProgress>
      <span className="h-8 leading-8 text-[10px] font-normal tracking-wider text-gray-700 hidden sm:block ml-2">
        Quick Start
      </span>
    </button>
  );
}

export { QuickStart };
