import type { FIELD_TYPE } from "@components/DjangoQL/utils";
import { RecordsType, useClosestListingTableMode } from "@features/listing";
import useGlobalVariables from "@hooks/useGlobalVariables";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { useQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import { getInitialPageContext } from "@utils/usePageContext";

const fetchIntrospections: (recordsType: RecordsType) => Promise<IntrospectionsResponse> = (
  recordsType = RecordsType.CVEs,
) => {
  return fetchWithSession(`/api/v1/introspections/?model=${recordsType}`);
};

export interface IntrospectionsResponse {
  current_model: string;
  fields: ThreatModelConfiguration;
}

export type ThreatModelConfiguration = Record<ThreatKey, ModelPropertyConfiguration>;

export interface ModelPropertyConfiguration {
  type: FIELD_TYPE; // Field type in db or custom type
  nullable: boolean;

  constrains: ModelPropertyConfigurationConstraint;
}

// custom QL attributes
export interface ModelPropertyConfigurationConstraint {
  name?: string; // front-end friendly name
  options?: string[]; // Only for enum fields (strings, but their values are constrained)
  min_value?: number; // Only for numeric fields
  max_value?: number; // Only for numeric fields
  display_type?: string; //  constraint type which reflects field transformation via Serializer which has to be emulated on frontend(f.e. int field which are displayed as percents)
  sortable?: boolean; // if field is sortable
  autocomplete?: boolean; // if field is autocomplete
  searchable?: boolean; // if field is searchable and should be included into search query
}

function useIntrospections(forceRecordsType?: RecordsType) {
  const recordsType = useClosestListingTableMode();
  const recordsTypeToUse = forceRecordsType ?? recordsType;

  // introspections are already available in the common page context, so we can use those instead of fetching them again
  const introspections = getIntrospections();

  const { currentUserID } = useGlobalVariables();
  return useQuery<IntrospectionsResponse>({
    queryKey: ["introspections", currentUserID, recordsTypeToUse],
    queryFn: () => fetchIntrospections(recordsTypeToUse),
    staleTime: 1 * 60 * 1000,
    initialData: introspections,
  });
}

function useFieldIntrospection(field: ThreatKey | undefined) {
  const introspections = useIntrospections();
  if (!field) {
    return null;
  }

  return introspections.data?.fields[field];
}

// Taking introspections from the page context, but this is tricky since NOT all the pages contains introspections
function getIntrospections(): IntrospectionsResponse {
  const introspections = getInitialPageContext().introspections;

  return introspections as IntrospectionsResponse;
}

async function getIntrospectionsAsync(recordsType: RecordsType = RecordsType.CVEs) {
  const introspections = getIntrospections();
  if (!introspections) {
    // we weren't able to get introspections from the page context, so we have to fetch them
    const introspectionsResponse = await fetchIntrospections(recordsType);
    return introspectionsResponse;
  }

  return introspections;
}

export type IntrospectionKey = keyof ThreatModelConfiguration;
export default useIntrospections;
export { getIntrospections, getIntrospectionsAsync, useFieldIntrospection };
