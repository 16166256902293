import type React from "react";

import { ListingTableProvider } from "@components/ListingTable";
import { type ListingTableProps, RecordsType, useChangeVisibleColumns, useVisibleColumns } from "@features/listing";

function _useVisibleColumns() {
  return useVisibleColumns(RecordsType.CVEs);
}

/**
 * Wrapper for listing table components representing global CVE listing table.
 */
export function ListingTableWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const changeVisibleColumns = useChangeVisibleColumns(RecordsType.CVEs);
  const initialVisibleColumns = _useVisibleColumns();

  const listingTableProps: ListingTableProps = {
    useVisibleColumns: _useVisibleColumns,
    visibleColumns: initialVisibleColumns,
    changeVisibleColumns,
    recordsType: RecordsType.CVEs,
    persistKey: "cve-listing-table",
  };

  return <ListingTableProvider initialState={listingTableProps}>{children}</ListingTableProvider>;
}
