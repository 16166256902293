import { getIconClass, type icons } from "@configs/icons";

interface IconProps {
  icon: keyof typeof icons;
  className?: string;
  style?: "fas" | "far" | "fal";
  title?: string;
  size?: "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  fixedWidth?: boolean;
}

export function Icon({ icon, className = "", style = "far", title, size, fixedWidth = false }: IconProps) {
  const sizeClass = size ? `fa-${size}` : "";
  const widthClass = fixedWidth ? "fa-fw" : "";

  return (
    <span className="inline-flex items-center justify-center">
      <i className={`${getIconClass(icon, style)} ${sizeClass} ${widthClass} ${className}`} title={title} />
    </span>
  );
}

export type { IconProps };