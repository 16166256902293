import { MenuSuggestion, type MenuSuggestionProps } from "./MenuSuggestion";
import { Suggestion } from "./Suggestion";

export interface AsyncMenuSuggestionProps extends MenuSuggestionProps {
  url: string;
}

class AsyncMenuSuggestion extends MenuSuggestion {
  url: string;

  constructor(props: AsyncMenuSuggestionProps) {
    super(props);

    this.url = props.url;

    this.children = [
      new Suggestion({
        text: "Loading...",
        isDisabled: true,
        textarea: this.textarea,
      }),
    ];

    // Start fetching data
    this.fetchData()
      .catch((error) => {
        console.error("Failed to fetch data", error);

        this.children = [
          new Suggestion({
            text: "Failed to fetch data. Try again later.",
            isDisabled: true,
            className: "text-red-500",
            textarea: this.textarea,
          }),
        ];
      })
      .finally(() => {
        this.refreshChildren();
      });
  }

  refreshChildren() {
    if (this.menuInstanceId) {
      const createdMenu = document.getElementById(this.menuInstanceId);
      if (createdMenu) {
        // remove loading item
        while (createdMenu.firstChild) {
          createdMenu.firstChild.remove();
        }

        // add new fetched items
        this.children.forEach((menuItem: Suggestion, id) => {
          const menuListItem = this.createMenuListItem(menuItem, id.toString());
          createdMenu.appendChild(menuListItem);
        });
      }
    }
  }

  async fetchData() {
    const options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    const response = await fetch(this.url, options);
    if (response.ok) {
      const data: string[] = await response.json();

      // Preprocess the fetched data to children
      this.children = data.map((item: string) => {
        return new Suggestion({
          text: item,
          snippetBefore: '"',
          snippetAfter: '" ',
          textarea: this.textarea,
        });
      });
    }

    this.refreshChildren();
  }

  createInstance = (id: string) => {
    const menu = this.createMenu(id);

    const menuItems = [
      new Suggestion({
        text: "Loading...",
        isDisabled: true,
        textarea: this.textarea,
      }),
    ];

    menuItems.forEach((menuItem: Suggestion, index) => {
      const menuListItem = this.createMenuListItem(menuItem, index.toString());
      menu.appendChild(menuListItem);
    });

    return menu;
  };
}

export default AsyncMenuSuggestion;
