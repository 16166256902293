import { transformSearchQuery_silent, validateSearchQuery } from "@utils/searchQueryToUrl";

// This component is responsible for initializing djangoql on any input and performing its binding to the ecosystem
class NucleusQLState {
  value: string;
  cursorPosition: number | null;
  // indicates if search query failed validation at token's level
  queryParsingError: string | null;

  constructor(initialValue?: string) {
    this.value = initialValue || "";
    this.cursorPosition = null;
    this.queryParsingError = null;
  }

  get isValidParsing() {
    return !this.queryParsingError;
  }

  get hasCorrectSearchQueryState() {
    return this.value !== "" && !this.queryParsingError && !!transformSearchQuery_silent(this.value);
  }

  updateState(newState: Partial<NucleusQLState>, validate = true) {
    Object.assign(this, newState);

    if (validate) {
      return this.validate();
    }

    return true;
  }

  validate() {
    // validate search query
    try {
      validateSearchQuery(this.value);
      this.queryParsingError = null;
    } catch (e: any) {
      this.queryParsingError = e.message;
      return false;
    }

    return true;
  }
}

export { NucleusQLState };
