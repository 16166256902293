import { Flatpickr, type FlatpickrValue } from "@components/Flatpickr";
import classNames from "classnames";
import type { Instance } from "flatpickr/dist/types/instance";
import { DateTime } from "luxon";
import type React from "react";
import { type Ref, useRef } from "react";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}
type DateValue = Date | DateRange | null;

interface DateFilterProps {
  value: FlatpickrValue;
  onChange: (dateRange: DateValue) => void;
  label?: string;
  isEnabled?: boolean;
  minDate?: string;
  maxDate?: string;
  className?: string;
  showResetButton?: boolean;
  mode?: "range" | "single";
}

// ! Note, this component might be incomplete, specially for range mode
const DateFilter: React.FC<DateFilterProps> = ({
  value,
  onChange,
  label,
  isEnabled = true,
  minDate,
  maxDate,
  className,
  showResetButton = false,
  mode = "range",
}) => {
  const flatpickrRef = useRef<Instance | null>(null);

  let displayValue = "";
  if (mode === "single" && value instanceof Date) {
    displayValue = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
  } else if (value && typeof value === "object") {
    const { startDate, endDate } = value as any;
    if (startDate && endDate) {
      displayValue = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} to ${DateTime.fromJSDate(
        endDate,
      ).toFormat("yyyy-MM-dd")}`;
    } else if (startDate) {
      displayValue = DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
    }
  }

  const isFilterApplied = !!value;

  return (
    <div className={className}>
      {label && (
        <label className="block mb-1 text-sm font-medium text-gray-700" htmlFor={`date_${label}`}>
          {label}
        </label>
      )}

      <Flatpickr
        ref={flatpickrRef}
        options={{
          mode: mode === "single" ? "single" : "range",
          minDate,
          maxDate,
        }}
        value={value}
        onClose={(selectedDates: Date[]) => {
          if (!isEnabled) {
            return;
          }

          if (mode === "single") {
            onChange(selectedDates[0] || null);
          } else {
            onChange({
              startDate: selectedDates[0] || null,
              endDate: selectedDates[1] || null,
            });
          }
        }}
        render={(_, refCallback) => (
          <div
            ref={refCallback as Ref<HTMLDivElement>}
            className={classNames("relative", {
              "cursor-pointer": isEnabled,
              "cursor-not-allowed": !isEnabled,
            })}
          >
            <i className="absolute transform -translate-y-1/2 fas fa-calendar-alt left-2 top-1/2" />
            <div
              className={classNames(
                "w-full py-1.5 pl-6 pr-2 border rounded-sm min-h-[40px] bg-white overflow-hidden normal-case",
                {
                  "text-gray-400": !isEnabled,
                  "leading-7": true,
                },
              )}
            >
              {displayValue || "Any date"}
              <input
                type="hidden"
                name={`date_${label}`}
                className="absolute text-white bg-white opacity-0 focus:outline-none"
                disabled={!isEnabled}
              />
              {showResetButton && isFilterApplied && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (mode === "single") {
                      onChange(null);
                    } else {
                      onChange({
                        startDate: null,
                        endDate: null,
                      });
                    }
                  }}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2"
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export { DateFilter };
