import type { SerializedMandiantMalwareKey } from "@interfaces/SerializedMandiantMalware";

const DEFAULT_MALWARE_COLUMNS: SerializedMandiantMalwareKey[] = [
  "mandiant_malware_id",
  "name",
  "aliases",
  "actors",
  "cves",
  "description",
  "inherently_malicious",
  "last_activity_time",
] as const;

export { DEFAULT_MALWARE_COLUMNS };
