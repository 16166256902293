import { isArray } from "lodash-es";
import React from "react";

import type SerializedThreat from "@interfaces/SerializedThreat";
import type { ExploitDBExploit } from "@interfaces/SerializedThreat";

class ExploitDBExploitInstance {
  cve_id?: string;
  exploitdb_author?: string;
  exploitdb_date_publised?: string;
  exploitdb_date_updated?: string;
  exploitdb_filename?: string;
  exploitdb_source_url?: string | null;
  exploitdb_url?: string | null;

  constructor(exploit: Partial<ExploitDBExploit>) {
    this.cve_id = exploit.cve_id;
    this.exploitdb_author = exploit.exploitdb_author;
    this.exploitdb_date_publised = exploit.exploitdb_date_publised;
    this.exploitdb_date_updated = exploit.exploitdb_date_updated;
    this.exploitdb_filename = exploit.exploitdb_filename;
    this.exploitdb_source_url = exploit.exploitdb_source_url;
    this.exploitdb_url = exploit.exploitdb_url;
  }

  getUrl() {
    return this.exploitdb_url;
  }

  getDate() {
    return this.exploitdb_date_publised;
  }

  getSourceUrl() {
    return this.exploitdb_source_url;
  }
}

function ExploitDB({ threat }: { threat: SerializedThreat }) {
  const exploitdb_exploits = isArray(threat.exploitdb_exploits) ? [...threat.exploitdb_exploits] : [];
  const tableCellClass = "px-2 py-1 text-center break-words";

  const instances = exploitdb_exploits.map((exploit) => new ExploitDBExploitInstance(exploit));

  return (
    <div className="px-4">
      <table className="w-full text-xs table-fixed">
        <thead>
          <tr className="bg-gray-200">
            <th className={tableCellClass}>Publish Date</th>
            <th className={tableCellClass}>Filename</th>
            <th className={tableCellClass}>Source URL</th>
            <th className={tableCellClass}>URL</th>
            <th className={tableCellClass}>Last Updated</th>
            <th className={tableCellClass}>Author</th>
          </tr>
        </thead>
        <tbody>
          {instances.length ? (
            instances.map((exploit, index) => {
              return (
                <tr key={`${exploit.exploitdb_filename}_${index}`} className="text-gray-700">
                  <td className={tableCellClass}>{exploit.exploitdb_date_publised}</td>
                  <td className={tableCellClass}>{exploit.exploitdb_filename}</td>
                  <td className={tableCellClass}>
                    {exploit.getSourceUrl() ? (
                      <a
                        href={exploit.getSourceUrl()!}
                        target="_blank"
                        className="hover:text-blue-700 text-blue-500"
                        rel="noreferrer"
                      >
                        {exploit.getSourceUrl()}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className={tableCellClass}>
                    {exploit.getUrl() ? (
                      <a
                        href={exploit.getUrl()!}
                        target="_blank"
                        className="hover:text-blue-700 text-blue-500"
                        rel="noreferrer"
                      >
                        {exploit.getUrl()}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className={tableCellClass}>{exploit.exploitdb_date_updated}</td>
                  <td className={tableCellClass}>{exploit.exploitdb_author}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className={`${tableCellClass} text-center`} colSpan={7}>
                No data to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ExploitDB;
