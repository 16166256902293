import type SerializedOrganizationConfiguration from "./SerializedOrganizationConfiguration";
import type SerializedOrganizationMember from "./SerializedOrganizationMember";

interface SerializedCVEStatus {
  id: string;
  name: string;
  description: string;
  is_default?: boolean;
  order: number;
}

export default interface SerializedOrganization {
  id: number;
  members: SerializedOrganizationMember[];
  name: string;
  data: object;
  created_at: string;
  modified_at: string;
  expiration_date?: string;
  trial_expiration_date?: string;
  configuration?: SerializedOrganizationConfiguration;
  tier: "FREE" | "TRIAL" | "PAID";
  has_risk_levels?: boolean;
  watched_search_result_limit?: number;
  cve_statuses?: SerializedCVEStatus[];
}

const DEFAULT_MAX_USERS_ALLOWED = 5;

class OrganizationUIModel implements SerializedOrganization {
  id: number;
  members: SerializedOrganizationMember[];
  name: string;
  data: object;
  created_at: string;
  modified_at: string;
  expiration_date?: string;
  trial_expiration_date?: string;
  configuration?: SerializedOrganizationConfiguration;
  tier: "FREE" | "TRIAL" | "PAID";
  has_risk_levels?: boolean;

  //
  currentUserID?: number;

  constructor(organization: SerializedOrganization, currentUserID: number) {
    this.id = organization.id;
    this.members = organization.members;
    this.name = organization.name;
    this.data = organization.data;
    this.created_at = organization.created_at;
    this.modified_at = organization.modified_at;
    this.expiration_date = organization.expiration_date;
    this.trial_expiration_date = organization.trial_expiration_date;
    this.configuration = organization.configuration;
    this.tier = organization.tier;
    this.has_risk_levels = organization.has_risk_levels;

    this.currentUserID = currentUserID;
  }

  isOrganizationAdmin() {
    return this.members.find((member) => member.profile.id === this.currentUserID)?.role === "org_admin";
  }

  canUseCustomRiskLevels() {
    return this.configuration?.are_custom_risk_levels_enabled ?? false;
  }

  get maxUsers() {
    return this.configuration?.max_users_allowed ?? DEFAULT_MAX_USERS_ALLOWED;
  }

  get activeUsers() {
    return this.members.filter((u) => u.profile.is_active).length;
  }

  get canInviteNewUser() {
    return this.activeUsers < this.maxUsers;
  }

  get availableFreeSlots() {
    return this.maxUsers - this.activeUsers;
  }
}

export { OrganizationUIModel };
