import type { Theme } from "../../../models/Theme";

export const defaultTheme: Theme = {
  cardBgColor: "#ffffff",
  cardDetailsBackGround: "#ffffff",
  cardDetailsColor: "#000",
  cardSubtitleColor: "#000",
  cardTitleColor: "#4b70b2",
  detailsColor: "#000",
  iconBackgroundColor: "#4b70b2",
  nestedCardBgColor: "#f5f5f5",
  nestedCardDetailsBackGround: "#f5f5f5",
  nestedCardDetailsColor: "#000",
  nestedCardSubtitleColor: "#000",
  nestedCardTitleColor: "#000",
  primary: "#4b70b2",
  secondary: "#ecfdf3",
  titleColor: "#4b70b2",
  titleColorActive: "#4b70b2",
};

export const darkTheme: Theme = {
  cardBgColor: "#191919",
  cardDetailsBackGround: "#191919",
  cardDetailsColor: "#ffff0f",
  cardSubtitleColor: "#ffffff",
  cardTitleColor: "#4b70b2",
  detailsColor: "#ffffff",
  iconBackgroundColor: "#4b70b2",
  nestedCardBgColor: "#333333",
  nestedCardDetailsBackGround: "#333333",
  nestedCardDetailsColor: "#ffffff",
  nestedCardSubtitleColor: "#ffffff",
  nestedCardTitleColor: "#ffffff",
  primary: "#4b70b2",
  secondary: "#ecfdf3",
  titleColor: "#4b70b2",
  titleColorActive: "#4b70b2",
};
