import { isBoolean, isString } from "lodash-es";
import { DateTime } from "luxon";
import type React from "react";

import type { CellContext } from "@tanstack/react-table";
import { isValidDateString } from "@utils/utility";

function isArrayOfStrings(value: any) {
  return Array.isArray(value) && value.length > 0 && value.every((item) => typeof item === "string");
}

function isObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

function isArrayOfObjects(value: any) {
  return Array.isArray(value) && value.length > 0 && value.every((item) => isObject(item));
}

const TRUNCATE_TEXT_LENGTH = 500;
const TRUNCATE_ARRAY_LENGTH = 10;
const DefaultCellRenderer: React.FunctionComponent<CellContext<any, any>> = (context) => {
  const value = context.getValue();
  const id = context.cell.column.id;

  if (isBoolean(value)) {
    return value ? <i className="fas fa-check text-green-500" /> : <i className="fas fa-times text-red-500" />;
  }

  const stringified = value ? value.toString() : "";

  if ((!stringified && value !== 0) || stringified === "{}") {
    return <span className="text-gray-400">-</span>;
  }

  if (isArrayOfStrings(value)) {
    if (value.length > TRUNCATE_ARRAY_LENGTH) {
      return (
        <span className="text-gray-400">{`${value.slice(0, TRUNCATE_ARRAY_LENGTH).join(", ")} and ${value.length - TRUNCATE_ARRAY_LENGTH} more...`}</span>
      );
    }

    return <span className="text-gray-400">{value.join(", ")}</span>;
  }

  if (isArrayOfObjects(value)) {
    // Typically it has to be handled by custom renderer
    return <span className="text-gray-400">{`${value.length} entrie${value.length > 1 ? "s" : ""}`}</span>;
  }

  if (isObject(value)) {
    // Typically it has to be handled by custom renderer
    return <span className="text-gray-400">Has information</span>;
  }

  if (isString(value) && isValidDateString(value)) {
    // determine if the value is a datestring
    const dateObject = DateTime.fromISO(value, { zone: "utc" });
    return (
      <span className="inline-flex items-center justify-center text-xs font-semibold tracking-wide text-gray-900">
        {dateObject.toFormat("MM/dd/yyyy")}
      </span>
    );
  }

  if (typeof value === "number" && !["mandiant_malware_id"].includes(id)) {
    // display numbers into centralized bubbles with localized formatting
    return (
      <span className="min-w-[80px] inline-flex items-center justify-center px-3 py-1 text-xs font-semibold text-gray-900 bg-gray-100 rounded-full">
        {value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })}
      </span>
    );
  }

  // Incase default cell renderer is used for extra long text, we need to truncate it
  const stringifiedTruncated =
    stringified.length > TRUNCATE_TEXT_LENGTH ? `${stringified.slice(0, TRUNCATE_TEXT_LENGTH)}...` : stringified;

  return <>{stringifiedTruncated}</>;
};

export { DefaultCellRenderer };
