import { Link } from "@components/Link";
import { ResponsiveScrollArea } from "@components/ResponsiveScrollArea";
import type SerializedNote from "@interfaces/SerializedNote";
import Badge from "@material-tailwind/react/components/Badge";
import { Card, CardBody } from "@material-tailwind/react/components/Card";
import Drawer from "@material-tailwind/react/components/Drawer";
import { Typography } from "@material-tailwind/react/components/Typography";
import { useQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import { optimisticUpdatePageContext, usePageContext } from "@utils/usePageContext";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useState, useTransition } from "react";
import { useNavigate } from "react-router";
import { HeaderMenu } from "./HeaderMenu";

// Only about for notes now
interface SerializedNotification {
  id: number;
  content_type: number;
  content_object: SerializedNote;
  severity: number;
  created_at: string;
  status: "new" | "seen";
}

const NotificationItem = ({
  notification,
  onRead,
  onNavigate,
}: {
  notification: SerializedNotification;
  onRead: () => Promise<void>;
  onNavigate: () => void;
}) => {
  const date = DateTime.fromISO(notification.created_at);
  const isRead = notification.status === "seen";
  const mentionLink = `/v/${notification.content_object.vip_id}?fromMention=${notification.content_object.id}`;
  const [isPending, startTransition] = useTransition();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleMarkAsSeen = async (id: number) => {
    setIsLoading(true);
    try {
      await fetchWithSession("/api/v1/alerts/mark_as_seen/", {
        method: "POST",
        body: JSON.stringify({ ids: [id] }),
      });
      startTransition(() => {
        onRead();
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    if (!isRead) {
      await handleMarkAsSeen(notification.id);
    }

    // Redirect to the note with the mention
    await onNavigate();
    navigate(mentionLink);
  };

  return (
    <Card
      className={classNames({
        "bg-white": !isRead,
        "bg-gray-50": isRead,
      })}
    >
      <CardBody className="!p-4">
        <div className="flex justify-between items-start gap-4">
          <p className="text-sm text-gray-600 pr-6">
            <span className="text-sm font-medium text-gray-900 whitespace-no-wrap">
              {notification.content_object.author_username}
            </span>{" "}
            mentioned you in a note on{" "}
            <Link className="whitespace-nowrap" onClick={handleClick} to={mentionLink}>
              {notification.content_object.vip_id}
            </Link>
          </p>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="text-xs text-gray-500">
            {date.toLocaleString(DateTime.DATETIME_SHORT)} ({date.toRelative()})
          </div>
          {!isRead && (
            <button
              onClick={async (e) => {
                e.stopPropagation();
                await handleMarkAsSeen(notification.id);
              }}
              className="text-blue-500 text-xs hover:text-blue-600 hover:underline whitespace-nowrap flex items-center gap-2"
              disabled={isLoading || isPending}
            >
              {(isLoading || isPending) && <i className="fas fa-spinner animate-spin text-xs" />}
              Mark as read
            </button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

function NotificationDrawer({
  isOpen,
  handleClose,
  notifications_count,
}: { isOpen: boolean; handleClose: () => void; notifications_count: number }) {
  const {
    data: notifications = [],
    refetch,
    isFetching,
    error,
  } = useQuery<SerializedNotification[]>({
    queryKey: ["notifications"],
    queryFn: () => fetchWithSession("/api/v1/alerts/get_notifications/"),
    enabled: isOpen && notifications_count > 0,
  });

  const handleMarkAsRead = async () => {
    refetch();
    optimisticUpdatePageContext("notifications_count", notifications_count - 1);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      placement="right"
      className="px-4 py-5"
      size={400}
      overlay={true}
      dismiss={{
        enabled: true,
        escapeKey: true,
        referencePress: true,
        outsidePress: true,
      }}
    >
      <div className="flex flex-col h-full p-0 gap-4 flex-1">
        <div className="flex flex-row justify-between flex-grow-0">
          <Typography variant="h5" color="black">
            Notifications
          </Typography>
          <button
            className={classNames(
              "hover:text-gray-700 text-md text-gray-600",
              "w-6 h-6 flex items-center justify-center",
            )}
            onClick={handleClose}
            title="Close"
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <ResponsiveScrollArea>
            {error ? (
              <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                <Typography variant="paragraph">Error loading notifications</Typography>
              </div>
            ) : isFetching ? (
              <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                <i className="fas fa-spinner text-4xl mb-4 animate-spin" />
              </div>
            ) : notifications.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                <i className="far fa-bell text-4xl mb-4" />
                <Typography variant="paragraph">No notifications yet</Typography>
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onRead={handleMarkAsRead}
                    onNavigate={handleClose}
                  />
                ))}
              </div>
            )}
          </ResponsiveScrollArea>
        </div>
      </div>
    </Drawer>
  );
}

const BadgeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Badge color="red" overlap="circular">
    {children}
  </Badge>
);

function Notifications() {
  // todo: we need to update this to gather from the backend as a part of the page context
  const { notifications_count } = usePageContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <HeaderMenu
        menuItems={[]}
        iconClass="far fa-bell"
        handleMenuClick={handleMenuClick}
        BadgeWrapper={notifications_count > 0 ? BadgeWrapper : undefined}
      />
      <NotificationDrawer isOpen={isOpen} handleClose={handleClose} notifications_count={notifications_count} />
    </>
  );
}

export { Notifications };
