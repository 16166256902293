import { DateTime } from "luxon";

import type { MandiantSource, Urls } from "./SerializedThreat";

export class MandiantSourceInstance implements MandiantSource {
  // old version fields
  date?: string;
  description?: string;
  title?: string;
  urls?: Urls;

  // fields found in newer records
  is_vendor_fix?: boolean;
  source_description?: string;
  source_name?: string;
  unique_id?: string;
  url?: string;

  constructor(source: MandiantSource) {
    this.date = source.date;
    this.description = source.description;
    this.title = source.title;
    this.urls = source.urls;
    this.is_vendor_fix = source.is_vendor_fix;
    this.source_description = source.source_description;
    this.source_name = source.source_name;
    this.unique_id = source.unique_id;
    this.url = source.url;
  }

  getUrl(): string {
    if (this.url) {
      return this.url;
    }
    if (this.urls && this.urls?.url?.length > 0) {
      return this.urls.url[0];
    }

    return "#";
  }

  getTitle(): string {
    if (this.title) {
      return this.title;
    }
    if (this.source_name) {
      return this.source_name;
    }

    // try to generate the title from url, so if "url": "https://support.apple.com/kb/HT213905" we get "support.apple.com" as title
    if (this.url) {
      const url = new URL(this.url);
      return url.hostname;
    }

    return "Unknown";
  }

  getDate = () => {
    const dateString = this.date;
    if (!dateString) {
      return "";
    }

    // Date could be in  "April 25, 2022 07:00:00 AM" format or '2022-05-24T12:00:00.000Z' format. We need to strip to YYYY-MM-DD
    const fromDateFormat =
      dateString.includes("AM") || dateString.includes("PM")
        ? "LLLL dd, yyyy hh:mm:ss a"
        : "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
    const dateObject = DateTime.fromFormat(dateString, fromDateFormat);

    if (!dateObject.isValid) {
      console.log(`Invalid date: ${dateString}`);
      return "";
    }

    return dateObject.toFormat("yyyy-MM-dd");
  };

  getDescription = () => {
    if (this.description) {
      return this.description;
    }
    if (this.source_description) {
      return this.source_description;
    }

    return "Unknown";
  };
}
