import { useCreateToast } from "@hooks/useCreateToast";
import useOrganization from "@hooks/useOrganization";
import { useQueryClient } from "@tanstack/react-query";
import useFetchMutation from "@utils/useFetchMutation";
import { useEffect, useMemo } from "react";
import { randomString } from "remeda";
import { proxy, useSnapshot } from "valtio";

// could be one from the organization or from the tier configuration
export interface SerializedCveStatus {
  id?: string; // undefined if status comes from default list
  order?: number; // undefined if status comes from default list
  name: string;
  description?: string;
  is_default?: boolean; // determines if this status should be assigned to new vulnerabilities by default
}

export interface CveStatus extends SerializedCveStatus {
  // frontend representation
  id: string;
  order: number;
}

export const useOrganizationCveStatuses = (): SerializedCveStatus[] => {
  const { data: organizationData } = useOrganization();

  return useMemo(() => {
    const statuses = organizationData?.cve_statuses || [];

    return statuses;
  }, [organizationData]);
};

const statusesState = proxy<{ statuses: CveStatus[]; isInitialized: boolean; hasUnsavedChanges: boolean }>({
  statuses: [],
  isInitialized: false,
  hasUnsavedChanges: false, // if user has some field in edit mode without saving
});

export function setHasUnsavedChanges(hasUnsavedChanges: boolean) {
  statusesState.hasUnsavedChanges = hasUnsavedChanges;
}

export function useHasUnsavedChanges() {
  const { hasUnsavedChanges } = useSnapshot(statusesState);
  return hasUnsavedChanges;
}


export const useCveStatusesInit = () => {
  const orgStatuses = useOrganizationCveStatuses();

  useEffect(() => {
    if (!statusesState.isInitialized) {
      statusesState.statuses = orgStatuses.map((status, index) => ({
        ...status,
        id: status.id ?? randomString(10),
        order: index,
      }));
      statusesState.isInitialized = true;
    }
  }, [orgStatuses]);
};

export const useCveStatusesState = () => {
  const { statuses } = useSnapshot(statusesState);
  const startingStatuses = useOrganizationCveStatuses();

  const isModified = useMemo(() => {
    if (statuses.length !== startingStatuses.length) {
      return true;
    }

    return statuses.some((currentStatus, index) => {
      const startingStatus = startingStatuses[index]!;
      return (
        currentStatus.name !== startingStatus.name ||
        currentStatus.description !== startingStatus.description ||
        currentStatus.is_default !== startingStatus.is_default ||
        currentStatus.order !== startingStatus.order
      );
    });
  }, [statuses, startingStatuses]);

  return { statuses, isModified };
};

export const useCveStatusesActions = () => {
  const handleAddStatus = ({ name, description }: { name: string; description: string }) => {
    statusesState.statuses = [
      ...statusesState.statuses,
      {
        id: randomString(10),
        name,
        description,
        order: statusesState.statuses.length,
      },
    ];
  };

  const handleUpdateStatus = (updatedStatus: CveStatus) => {
    statusesState.statuses = statusesState.statuses.map((s) => (s.id === updatedStatus.id ? { ...updatedStatus } : s));
  };

  const handleDeleteStatus = (id: string) => {
    statusesState.statuses = statusesState.statuses
      .filter((s) => s.id !== id)
      .map((s, index) => ({ ...s, order: index }));
  };

  const handleReorderStatuses = (activeId: string, overId: string) => {
    const oldIndex = statusesState.statuses.findIndex((s) => s.id === activeId);
    const newIndex = statusesState.statuses.findIndex((s) => s.id === overId);
    const newStatuses = Array.from(statusesState.statuses);
    const [reorderedItem] = newStatuses.splice(oldIndex, 1);
    newStatuses.splice(newIndex, 0, reorderedItem!);

    statusesState.statuses = newStatuses.map((status, index) => ({
      ...status,
      order: index,
    }));
  };

  return {
    handleAddStatus,
    handleUpdateStatus,
    handleDeleteStatus,
    handleReorderStatuses,
  };
};

const organizationStatusesUrlEndpoint = "/api/v1/organizations/statuses/";
export function useSaveCveStatuses() {
  const queryClient = useQueryClient();
  const { statuses } = useCveStatusesState();
  const { addMessage } = useCreateToast();

  return useFetchMutation<SerializedCveStatus[], Error>(
    organizationStatusesUrlEndpoint,
    { statuses },
    {
      onSuccess: () => {
        addMessage({
          title: "Success!",
          content: "Status configuration updated",
          variant: "success",
          duration: 1000,
        });

        queryClient.invalidateQueries({ queryKey: ["organization"] });
        queryClient.invalidateQueries({ queryKey: ["vip_cve_statuses"] });
        queryClient.invalidateQueries({ queryKey: ["cve_status_changelogs"] });
        statusesState.isInitialized = false;
      },
    },
  );
}

export function useChangeThreatStatus(cve_threat_intel_id: number) {
  const queryClient = useQueryClient();
  const { addMessage } = useCreateToast();
  return useFetchMutation(
    `/api/v1/threats/${cve_threat_intel_id.toString()}/vip_status/`,
    (statusValue: string) => {
      return {
        status: statusValue,
      };
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["organization"] });

        addMessage({
          title: "Success!",
          content: "Vulnerability status updated",
          variant: "success",
          duration: 1000,
        });
      },
    },
    "PATCH",
  );
}
