import useGlobalVariables from "@hooks/useGlobalVariables";
import useOrganization from "@hooks/useOrganization";
import { useCallback, useMemo } from "react";
import type { SerializedBaseTag } from "./tags.interfaces";

export function useTagPermissions() {
  const { currentUserID } = useGlobalVariables();
  const { data: org, isLoading, isSuccess } = useOrganization();

  const isOrgAdmin = useMemo(
    () => org?.members.find((m) => m.profile.id === currentUserID)?.role === "org_admin",
    [org, currentUserID],
  );

  const isReady = useMemo(() => !isLoading && isSuccess, [isLoading, isSuccess]);

  const enrichTagsWithPermissions = useCallback(
    (tags: SerializedBaseTag[]) => {
      return isReady
        ? tags.map((tag) => ({
            ...tag,
            isEditable: !tag.is_global && (tag.created_by === currentUserID || isOrgAdmin),
          }))
        : [];
    },
    [currentUserID, isOrgAdmin, isReady],
  );

  return {
    enrichTagsWithPermissions,
    isReady,
  };
}

