import { lazy } from "react";

export const IndexPage = lazy(() => import("./pages/index.page").then((module) => ({ default: module.IndexPage })));
export const ListingPage = lazy(() =>
  import("./pages/listing.page").then((module) => ({ default: module.ListingPage })),
);
export const MalwarePage = lazy(() => import("./pages/malware"));
export const VulnerabilityPage = lazy(() =>
  import("./pages/vulnerability_details").then((module) => ({ default: module.VulnerabilityDetailsPage })),
);
export const VulnerabilityPagePDF = lazy(() =>
  import("./pages/vulnerability_details").then((module) => ({ default: module.VulnerabilityDetailsPagePDF })),
);
export const MyOrganizationPage = lazy(() =>
  import("./pages/my_organization.page").then((module) => ({ default: module.MyOrganizationPage })),
);
export const StorePage = lazy(() => import("./pages/store.page").then((module) => ({ default: module.StorePage })));
export const ProfilePage = lazy(() =>
  import("./pages/profile.page").then((module) => ({ default: module.ProfilePage })),
);
export const WatchingPage = lazy(() =>
  import("./pages/watching.page").then((module) => ({ default: module.WatchingPage })),
);
export const AlertsPage = lazy(() => import("./pages/alerts.page").then((module) => ({ default: module.AlertsPage })));
export const LoginPage = lazy(() => import("./pages/login").then((module) => ({ default: module.LoginPage })));
export const SourcesPage = lazy(() =>
  import("./pages/sources.page").then((module) => ({ default: module.SourcesPage })),
);
export const GlobalTagsPage = lazy(() =>
  import("./pages/global_tags").then((module) => ({ default: module.GlobalTagsPage })),
);
