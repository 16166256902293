import ErrorUnauthorized from "@errors/ErrorUnauthorized";
import getCookie from "./getCookie";

interface FetchWithSessionOptions extends RequestInit {
  allowErrorResponse?: boolean; // allow getting error response data
  noParse?: boolean; // don't parse response as JSON
}

async function fetchWithSession<T = any>(input: RequestInfo, init?: FetchWithSessionOptions) {
  const options = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    } as Record<string, string>,
  };

  if (init?.method && ["PUT", "PATCH", "POST"].includes(init.method)) {
    options.headers["X-CSRFToken"] = getCookie("csrftoken");
  }

  const response = await fetch(input, { ...init, ...options });

  // Handle 403 errors first
  if (response.status === 403) {
    const data = await response.json().catch(() => ({}));
    if (data?.detail) {
      throw new ErrorUnauthorized(data.detail);
    }
    throw new ErrorUnauthorized("Access Forbidden");
  }

  if (init?.noParse) {
    return response as unknown as T;
  }

  // Allow getting error response data if option is set
  if (!response.ok && init?.allowErrorResponse) {
    return response.json() as Promise<T>;
  }

  if (!response.ok) {
    const data = await response.json().catch(() => ({}));
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${
        data?.message || data?.detail || data?.error || response.statusText
      }`,
    );
  }

  return response.json() as Promise<T>;
}
export default fetchWithSession;
