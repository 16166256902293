import type React from "react";
import { createContext, useRef } from "react";
import { proxy } from "valtio";
import { NucleusQLState } from "./NucleusQLState";

const NucleusQLContext = createContext<NucleusQLState>(new NucleusQLState());

const NucleusQLProvider = ({
  children,
  initialValue,
}: {
  children: React.ReactNode;
  initialValue?: string;
}) => {
  const localWidgetState = useRef(proxy<NucleusQLState>(new NucleusQLState(initialValue))).current;

  return <NucleusQLContext.Provider value={localWidgetState}>{children}</NucleusQLContext.Provider>;
};



export { NucleusQLContext, NucleusQLProvider };
