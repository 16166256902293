import type React from "react";

import type { CellContext } from "@tanstack/react-table";

const VipMonitoredRenderer: React.FunctionComponent<CellContext<any, any>> = (context) => {
  const value = context.getValue();

  return value ? <i className="fas fa-check text-green-500" /> : <i className="fas fa-times text-red-500" />;
};

export default VipMonitoredRenderer;
