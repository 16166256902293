import React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const Intel471GirsRenderer: (
  context: CellContext<FrontendSerializedThreat, FrontendSerializedThreat["intel471_girs"]>,
) => JSX.Element = (context) => {
  const value = context.getValue();

  const displayValue = value
    ? Object.entries(value)
        .map(([path, name]) => `${path}: '${name}'`)
        .join(", ")
    : "None";

  return <div>{displayValue}</div>;
};

export default Intel471GirsRenderer;
