import classNames from "classnames";
import TextTransition, { presets } from "react-text-transition";

import { RadialProgress } from "@components/RadialProgress";
import useQuickStart, { useQuickStartControls } from "@features/quickStart";
import { Drawer } from "@material-tailwind/react/components/Drawer";
import { Typography } from "@material-tailwind/react/components/Typography";

import QuickStartItems from "./QuickStartDrawer/QuickStartItems";

function QuickStartDrawer() {
  const { isOpen, openClose } = useQuickStartControls();
  const quickStart = useQuickStart();

  const handleClose = () => {
    openClose(false);
  };

  const handleMakeCompleted = async (id: string) => {
    await quickStart.markNextStepAsCompleted(id as any);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      className="p-4 shadow-xl"
      placement="right"
      overlay={false}
      size={380}
      dismiss={{
        enabled: true,
        escapeKey: true,
        referencePress: true,
        outsidePress: true,
        ancestorScroll: false,
        bubbles: true,
      }}
    >
      <div className="flex p-0 flex-col max-h-full   foo bar">
        <div className=" flex  justify-between flex-grow-0 flex-row">
          <div className="flex flex-row items-center justify-center gap-1">
            <RadialProgress
              percent={quickStart.percent}
              color={quickStart.isCompleted ? "text-green-400" : "text-nucleus"}
              size="w-11 h-11"
              textSize="text-[10px]"
            >
              <span
                className={classNames({
                  "text-green-400": quickStart.isCompleted,
                  "text-nucleus": !quickStart.isCompleted,
                })}
              >
                <TextTransition springConfig={presets.wobbly}>{quickStart.progressText}</TextTransition>
              </span>
            </RadialProgress>
            <div className="flex flex-col gap-1 ml-2">
              <Typography variant="h5" color="black">
                Quick Start
              </Typography>
              <Typography variant="small" color="gray">
                Walk through this guide to get the most out of VIP right away. Click{" "}
                <a
                  className="hover:text-blue-800 underline-offset-2 text-blue-700 underline"
                  target="_blank"
                  rel="noreferrer"
                  href={quickStart.url}
                >
                  here
                </a>{" "}
                for detailed quick start instructions.
              </Typography>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <button
              className={classNames(
                "hover:text-gray-700 mr-1 text-2xl text-gray-600",
                "w-6 h-6 flex items-center justify-center",
                "border border-gray-300 rounded",
              )}
              onClick={handleClose}
              title="Close"
            >
              &times;
            </button>
          </div>
        </div>

        <div className="flex-grow-1 flex flex-col justify-start gap-1 mt-4">
          {quickStart.nextStepsItems?.length ? (
            <>
              <div className="flex flex-row justify-between flex-grow-0">
                <span className="text-base font-semibold text-blue-800">Next steps</span>
              </div>
              <QuickStartItems items={quickStart.nextStepsItems} handleMakeCompleted={handleMakeCompleted} />
            </>
          ) : null}
          {quickStart.completedItems?.length ? (
            <>
              <div className="flex flex-row justify-between flex-grow-0 mt-4">
                <span className="text-base font-semibold text-green-700">Completed:</span>
              </div>
              <QuickStartItems items={quickStart.completedItems} isCompletedList={true} />
            </>
          ) : null}

          {quickStart.isCompleted ? (
            <div className="flex flex-row justify-center flex-grow-0 mt-4 text-center align-middle">
              <span className="flex flex-row items-center gap-2 text-xl font-semibold text-green-700">
                <i className="fas fa-check-circle ml-1 text-2xl text-green-700" />
                You're all set!
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </Drawer>
  );
}

export { QuickStartDrawer };
