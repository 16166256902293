import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, VulncheckKevReportExploitation } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { DateTime } from "luxon";

const LIMIT_REPORTS_TO = 10;

export const vulncheck_kev_reported_exploitation: (
  context: CellContext<FrontendSerializedThreat, VulncheckKevReportExploitation[]>,
) => JSX.Element = (context) => {
  const values = context.getValue();

  if (!Array.isArray(values) || values?.length === 0) {
    return <span className="text-sm text-gray-500">-</span>;
  }

  const renderReport = (report: VulncheckKevReportExploitation, index: number) => (
    <a
      className="flex transition-all flex-start ease-nav-brand text-slate-600 hover:text-blue-700"
      href={report.url}
      target="_blank"
      rel="noreferrer"
      key={`vulncheck_kev_reported_exploitation_${index}`}
    >
      <span>
        {DateTime.fromISO(report.date_added).toLocaleString(DateTime.DATE_SHORT)}
        <i className="inline ml-1 text-xs fas fa-external-link-alt" />
      </span>
    </a>
  );

  const fullView = <div>{values.map(renderReport)}</div>;

  if (values.length > LIMIT_REPORTS_TO) {
    return (
      <div>
        <div>{values.slice(0, LIMIT_REPORTS_TO).map((report, index) => renderReport(report, index))}</div>
        <Tooltip value={fullView}>
          <div className="mt-2 text-xs text-right text-gray-600 cursor-progress">
            And {values.length - LIMIT_REPORTS_TO} more...
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div>{fullView}</div>;
};
