import { useCreateToast } from "@hooks/useCreateToast";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation from "@utils/useFetchMutation";
import type { OrganizationTagStat, SerializedBaseTag } from "./tags.interfaces";

const tagsUrl = "/api/v1/tags/";

export async function fetchTags(inputValue?: string): Promise<SerializedBaseTag[]> {
  return fetchWithSession<SerializedBaseTag[]>(tagsUrl + (inputValue ? `?q=${inputValue}` : ""));
}

const useOrganizationTags = () => {
  return useSuspenseQuery<OrganizationTagStat[]>({
    queryKey: ["organizationTags"],
    queryFn: async () => await fetchWithSession("/api/v1/tags/by_organization/"),
  });
};

type MutateTagVariables =
  | { action: "create"; values: string[] }
  | { action: "assign" | "unassign"; values: string[]; threat_id: number };

function useMutateTag() {
  const method = "POST";
  const queryClient = useQueryClient();

  return useFetchMutation<Partial<SerializedBaseTag>, Error, MutateTagVariables, unknown>(
    (variables) => {
      const action = variables?.action;
      return tagsUrl + (action === "create" ? "" : `${action}/`);
    },
    (variables) => {
      // TypeScript infers the correct shape based on action
      if (variables?.action === "create") {
        return { values: variables.values };
      }

      if (!variables) {
        return {};
      }

      return { values: variables.values, threat_id: variables.threat_id };
    },
    {
      onSuccess(data, variables, context) {
        if (variables.action === "create") {
          queryClient.invalidateQueries({ queryKey: ["organizationTags"] });
        }
        // Handle 'assign' and 'unassign' if needed
      },
    },
    method,
  );
}

function useTagDelete() {
  const { addMessage } = useCreateToast();
  const queryClient = useQueryClient();

  return useFetchMutation<unknown, Error, { tag_id: number }, unknown>(
    (variables) => {
      return `${tagsUrl + variables?.tag_id}/`;
    },
    () => undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["organizationTags"] });
        addMessage({
          title: "Success!",
          content: "Tag deleted successfully",
          variant: "success",
          duration: 1000,
        });
      },
    },
    "DELETE",
  );
}

function useTagEdit() {
  const queryClient = useQueryClient();

  return useFetchMutation<Partial<SerializedBaseTag>, Error, Partial<SerializedBaseTag>, unknown>(
    (variables) => {
      if (variables?.id) {
        return `${tagsUrl + variables.id}/`;
      }
      throw new Error("Tag ID is required");
    },
    (tag: Partial<SerializedBaseTag>) => ({
      value: tag.value,
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["organizationTags"] });
      },
    },
    "PATCH",
  );
}

function useTagUnassign(threat_id: string) {
  const { addMessage } = useCreateToast();

  return useFetchMutation<Partial<SerializedBaseTag>, Error, { values: string[] }, unknown>(
    () => {
      return `${tagsUrl}unassign/`;
    },
    (variables) => ({
      values: variables?.values,
      threat_id: threat_id,
    }),
    {
      onSuccess: () => {
        addMessage({
          title: "Success!",
          content: "Tags updated successfully",
          variant: "success",
          duration: 1000,
        });
      },
    },
    "POST",
  );
}

export { useOrganizationTags, useMutateTag, useTagDelete, useTagEdit, useTagUnassign };
