import React from "react";

import Tooltip from "@components/Tooltip";
import type { FrontendSerializedThreat, SecurinVendorComment } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { safeParseJSONtoArray } from "@utils/utility";

const LIMIT_RECORDS_TO = 6;
const Renderer: (context: CellContext<FrontendSerializedThreat, string>) => JSX.Element = (context) => {
  const value = safeParseJSONtoArray<SecurinVendorComment>(context.getValue());

  if (!value || !Array.isArray(value)) {
    return <>-</>;
  }

  const textClass = "text-sm text-gray-500";

  const renderRecord = (record: SecurinVendorComment, index: number) => (
    <Tooltip key={`securin_vendorcomment_${index}`} value={<div className={textClass}>{record.commentary}</div>}>
      <div className="text-green-800 cursor-pointer">
        <span className="text-xs">{record.vendor}</span>
        {index < value.length - 1 ? ", " : ""}
      </div>
    </Tooltip>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map((source, index) => renderRecord(source, index))}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  const fullView = <div>{value.map(renderRecord)}</div>;
  return <div>{fullView}</div>;
};

export default Renderer;
