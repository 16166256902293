import React from "react";

import type { EvidenceDetail, FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_RECORDS_TO = 3;
const RfRiskRulesTriggeredRenderer: (context: CellContext<FrontendSerializedThreat, EvidenceDetail[]>) => JSX.Element =
  (context) => {
    const value: EvidenceDetail[] = context.getValue();

    if (!value || !Array.isArray(value)) {
      return <>-</>;
    }

    const renderRecord = (record: EvidenceDetail, index) => (
      //   add comma after each record except the last one
      <span key={`rf_risk_rules_triggered_${index}`}>
        {record.Rule}
        {index !== value.length - 1 ? <span>, </span> : null}
      </span>
    );

    if (value.length > LIMIT_RECORDS_TO) {
      return (
        <div>
          <div>{value.slice(0, LIMIT_RECORDS_TO).map(renderRecord)}</div>
          <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
            And {value.length - LIMIT_RECORDS_TO} more...
          </div>
        </div>
      );
    }

    const fullView = <div>{value.map(renderRecord)}</div>;
    return <div>{fullView}</div>;
  };

export default RfRiskRulesTriggeredRenderer;
