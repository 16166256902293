import type React from "react";

import Badge from "@components/Badge";
import { COLORS_CONFIGURATION } from "@configs/colors";
import type { FrontendSerializedThreat, ThreatKey } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const ColoredLabel: React.FunctionComponent<CellContext<FrontendSerializedThreat, string>> = (context) => {
  const value = context.getValue();

  if (!value?.length) {
    return <span className="text-gray-400">-</span>;
  }

  const fieldID = context.column.id as ThreatKey;
  const colorConfiguration = COLORS_CONFIGURATION[fieldID]?.[value.toUpperCase()];
  const displayValue = value[0].toUpperCase() + value.slice(1);

  if (colorConfiguration) {
    return <Badge color={colorConfiguration}>{displayValue}</Badge>;
  }

  return <>{value.toString()}</>;
};

export default ColoredLabel;
