import { FEEDS_LABELS_MAPPING } from "@configs/feeds";
import { capitalizeFirstLetter } from "@utils/utility";

import { uniqueId } from "lodash-es";
import type QLContext from "./QLContext";
import { Suggestion, type SuggestionProps, type SuggestionType } from "./Suggestion";

export interface MenuSuggestionProps extends SuggestionProps {
  context: QLContext;
  children?: (Suggestion | MenuSuggestion)[];
  type?: SuggestionType;
}

const MENU_ITEM_THRESHOLD = 18;

class MenuSuggestion extends Suggestion {
  children: (Suggestion | MenuSuggestion)[];
  menu!: HTMLUListElement; // created in createInstance/createMenu
  menuInstanceId: string | null = null;
  focusedMenuItem = -1; // index of focused menu item (first level)
  submenuFocusedItem = -1; // index of focused submenu item (second level)
  boundHandleKeyDown: (e: KeyboardEvent) => void;
  type: SuggestionType = "menu";
  context: QLContext;

  static sharedTooltip: HTMLDivElement | null = null;

  static readonly subMenuClass = "django-ql-menu__submenu";
  static readonly menuClass = "django-ql-menu";

  constructor(props: MenuSuggestionProps) {
    super(props);

    this.children = props.children ?? [];
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    this.context = props.context;
    this.textarea = props.textarea;

    this.ensureSharedTooltipExists();
  }

  ensureSharedTooltipExists() {
    if (!MenuSuggestion.sharedTooltip) {
      MenuSuggestion.sharedTooltip = document.createElement("div");
      MenuSuggestion.sharedTooltip.id = "django-ql-shared-tooltip";
      MenuSuggestion.sharedTooltip.setAttribute(
        "class",
        "hidden bg-gray-100 py-1 px-3 text-xs text-gray-900 rounded-md absolute z-[99999] border border-gray-600 max-w-[280px]",
      );
      document.body.appendChild(MenuSuggestion.sharedTooltip);
    }
  }

  showTooltip(text: string, x: number, y: number) {
    if (MenuSuggestion.sharedTooltip) {
      MenuSuggestion.sharedTooltip.textContent = text;
      MenuSuggestion.sharedTooltip.style.left = `${x + 120}px`;
      MenuSuggestion.sharedTooltip.style.top = `${y + 40}px`;
      MenuSuggestion.sharedTooltip.classList.remove("hidden");
    }
  }

  hideTooltip() {
    if (MenuSuggestion.sharedTooltip) {
      MenuSuggestion.sharedTooltip.classList.add("hidden");
    }
  }

  createInstance = (id: string) => {
    const menu = this.createMenu(id);

    const menuItems = this.children;
    menuItems.forEach((menuItem: MenuSuggestion | Suggestion, index) => {
      const menuListItem = this.createMenuListItem(menuItem, index.toString());
      menu.appendChild(menuListItem);
    });

    if (
      menuItems.every((item) => Object.getPrototypeOf(item).constructor === Suggestion) &&
      menuItems.length > MENU_ITEM_THRESHOLD
    ) {
      menu.classList.add("with-scrollbar");
    } else {
      menu.classList.remove("with-scrollbar");
    }

    return menu;
  };

  createMenu = (id: string) => {
    // show scrollbar if there single level menu items
    const hasOverflow = this.children.every((item) => item.type === "text");
    const menu = document.createElement("ul");
    this.menuInstanceId = `menu-instance-${id}`;
    menu.setAttribute("id", this.menuInstanceId);
    menu.setAttribute("data-index", id.toString());

    menu.setAttribute("aria-hidden", "true");
    menu.setAttribute(
      "class",
      `${MenuSuggestion.menuClass} overflow-visible transform relative transition duration-150 ease-in-out origin-top min-w-32${
        hasOverflow ? " " + "overflow-y-auto scrollbar-thumb-gray-600 scrollbar-track-gray-100 scrollbar-thin" : ""
      }`,
    );

    this.addMenuEventListeners(menu);

    this.menu = menu;
    return menu;
  };

  // Only first level menu items
  getMenuItems = () => {
    const menu = this.menu;
    if (!menu) {
      return [];
    }

    const menuItems = Array.from(menu.children);
    return menuItems as HTMLLIElement[];
  };

  // Only second level menu items
  getSubMenuItems = (menuItem?: HTMLLIElement) => {
    if (!menuItem) {
      return [];
    }

    const submenu = menuItem.querySelector(`.${MenuSuggestion.subMenuClass}`);
    if (!submenu) {
      return [];
    }

    const submenuItems = Array.from(submenu.children);
    return submenuItems as HTMLLIElement[];
  };

  addMenuEventListeners = (menu: HTMLElement) => {
    // event listener for hovering over menu items
    menu.addEventListener("mouseover", this.handleMouseOver.bind(this));
    menu.addEventListener("mouseleave", this.handleMouseOut.bind(this));

    // keyboard handler for menu items so we can navigate with arrow keys
    this.textarea.addEventListener("keydown", this.boundHandleKeyDown);
  };

  customLabel = (item: Suggestion) => {
    const label = item.text as keyof typeof FEEDS_LABELS_MAPPING;

    return FEEDS_LABELS_MAPPING[label] || capitalizeFirstLetter(label);
  };

  onMouseDown = (e: MouseEvent | KeyboardEvent) => {
    // if this one clicked, our input will be blurred and menu gonna be destroyed
    e.preventDefault();
    e.stopPropagation();

    // only left click should be handled
    if (e instanceof MouseEvent && e.button !== 0) {
      return false;
    }

    if (this.children.length > 1) {
      // if there is more than one child, its a menu click and shouldBe ignored
    } else if (this.children.length === 1) {
      // if there is only one child, trigger the child's click
      this.children[0]?.onMouseDown(e, this.context);
    } else {
      this.onSelect(this.context);
    }

    return false;
  };

  focusMenuItem = (newMenuIndex: number, isMouseEvent = false) => {
    const menuItems = this.getMenuItems();

    if (newMenuIndex >= menuItems.length) {
      return;
    }

    if (this.focusedMenuItem !== newMenuIndex) {
      // release focus from previous menu item
      if (this.focusedMenuItem >= 0) {
        const focusedMenuItem = menuItems[this.focusedMenuItem];
        focusedMenuItem?.classList.remove("active");
      }

      if (newMenuIndex >= 0) {
        const newFocusedMenuItem = menuItems[newMenuIndex];
        // avoid adding active class to menu items that are disabled and has 'disabled' class
        if (!newFocusedMenuItem?.classList.contains("disabled")) {
          this.focusedMenuItem = newMenuIndex;
          newFocusedMenuItem?.classList.add("active");
        }
      }
    }

    if (isMouseEvent && this.focusedMenuItem >= 0) {
      const focusedMenuItem = menuItems[this.focusedMenuItem];
      if (focusedMenuItem) {
        // if this is a mouse event, we automatically display submenu if there is one
        this.showSubMenu(focusedMenuItem, this.children[this.focusedMenuItem]!);
      }
    } else if (!isMouseEvent) {
      const focusedMenuItem = menuItems[this.focusedMenuItem];
      if (focusedMenuItem) {
        this.hideSubMenu(focusedMenuItem);

        this.scrollIntoViewIfNeeded(focusedMenuItem);
      }
    }
  };

  focusSubMenuItem = (newSubmenuIndex: number) => {
    if (newSubmenuIndex < -1) {
      return;
    }

    if (this.focusedMenuItem >= 0) {
      const menuItems = this.getMenuItems();
      const focusedMenuItem = menuItems[this.focusedMenuItem];
      const subMenuItems = this.getSubMenuItems(focusedMenuItem);

      if (this.submenuFocusedItem >= 0 && newSubmenuIndex > -1 && subMenuItems[this.submenuFocusedItem]) {
        // release focus from previous submenu item
        subMenuItems[this.submenuFocusedItem]?.classList.remove("active");
      }

      if (newSubmenuIndex >= 0 && subMenuItems[newSubmenuIndex]) {
        // focus new submenu item
        subMenuItems[newSubmenuIndex].classList.add("active");
        this.scrollIntoViewIfNeeded(subMenuItems[newSubmenuIndex]);
      }

      this.submenuFocusedItem = newSubmenuIndex;
    }
  };

  // hide any other submenus
  hideSubMenus = () => {
    const menuItems = this.getMenuItems();
    for (const item of menuItems) {
      item.classList.remove("open");
      this.hideSubMenu(item);
    }

    this.focusSubMenuItem(-1);
  };

  showSubMenu = (menuItem: HTMLLIElement, menuSuggestion: MenuSuggestion | Suggestion) => {
    this.hideSubMenus();

    // if there is a submenu, we need to display it
    if (menuSuggestion.children && menuSuggestion.children?.length > 0) {
      const subMenu = this.createSubMenu(menuSuggestion.children);
      menuItem.classList.add("open");
      menuItem.appendChild(subMenu);
      this.focusSubMenuItem(0);
    }
  };

  hideSubMenu = (menuItem: HTMLElement) => {
    const subMenu = menuItem.querySelector(`.${MenuSuggestion.subMenuClass}`);
    if (subMenu) {
      menuItem.classList.remove("open");
      subMenu.remove();
    }
  };

  openSubMenu = () => {
    const menuItems = this.getMenuItems();

    if (this.submenuFocusedItem >= 0) {
      // if submenu is already open, we dont need to do anything
      return false;
    }

    if (this.focusedMenuItem < 0) {
      return false;
    }

    const menuItem = menuItems[this.focusedMenuItem];
    const menuSuggestion = this.children[this.focusedMenuItem];
    if (menuItem && menuSuggestion?.children && menuSuggestion.children?.length > 0) {
      this.showSubMenu(menuItem, menuSuggestion);
      return true;
    }

    return false;
  };

  onMenuItemEnter = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // if we are on a menu item with a submenu, we need to open it, else we need to select it
    if (!this.openSubMenu()) {
      if (this.submenuFocusedItem >= 0) {
        const subMenuSuggestion = this.children[this.focusedMenuItem]?.children![this.submenuFocusedItem];
        this.focusSubMenuItem(-1);
        subMenuSuggestion?.onMouseDown(e, this.context);
      } else if (this.focusedMenuItem >= 0) {
        const menuSuggestion = this.children[this.focusedMenuItem];
        this.focusMenuItem(-1);
        menuSuggestion?.onMouseDown(e, this.context);
      } else {
        if (this.submenuFocusedItem >= 0) {
          // following behavior is not for submenu items
          return;
        }

        // we havent yet selected any menu item, but we are pressing enter. There might be already some menu item which is suitable and highlighted, so we need to select it.
        const menuItems = this.getMenuItems(); // array of HTMLLIElement
        const contextPrefix = this.context.prefix.trim(); // input value to check for match

        if (menuItems.length === 0 || contextPrefix.length === 0) {
          return;
        }

        // find the most matchable menu item that contains the prefix
        const mostMatchableMenuItem = menuItems.reduce(
          (acc, menuItem) => {
            const menuItemText = menuItem.textContent?.trim() ?? "";
            const menuItemTextLower = menuItemText.toLowerCase();
            const contextPrefixLower = contextPrefix.toLowerCase();

            if (menuItemTextLower.includes(contextPrefixLower)) {
              return {
                mostMatchableItem: menuItemText,
                matchCount: acc.matchCount + 1,
              };
            }

            return acc;
          },
          { mostMatchableItem: "", matchCount: 0 },
        );

        if (mostMatchableMenuItem.mostMatchableItem && mostMatchableMenuItem.matchCount === 1) {
          // if there is a matchable menu item, and it is the only one, we need to select it
          const index = menuItems.findIndex(
            (menuItem) => menuItem.textContent?.trim() === mostMatchableMenuItem.mostMatchableItem,
          );

          const menuSuggestion = this.children[index];
          menuSuggestion?.onMouseDown(e, this.context);
        }
      }
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const menuItems = this.getMenuItems();

    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault();

        if (this.submenuFocusedItem >= 0) {
          this.focusSubMenuItem(this.submenuFocusedItem - 1);
        } else if (this.focusedMenuItem > 0) {
          this.focusMenuItem(this.focusedMenuItem - 1);
        }

        break;
      }

      case "ArrowDown": {
        e.preventDefault();

        if (this.focusedMenuItem >= 0) {
          const menuItem = menuItems[this.focusedMenuItem];
          const subMenuItems = this.getSubMenuItems(menuItem);
          if (this.submenuFocusedItem >= 0 && this.submenuFocusedItem < subMenuItems.length - 1) {
            this.focusSubMenuItem(this.submenuFocusedItem + 1);
            break;
          }
        }

        if (this.focusedMenuItem < menuItems.length - 1) {
          this.focusMenuItem(this.focusedMenuItem + 1);
        }

        break;
      }

      case "ArrowRight": {
        e.preventDefault();

        this.openSubMenu();
        break;
      }

      case "ArrowLeft": {
        e.preventDefault();

        this.hideSubMenus();
        break;
      }

      case "Enter": {
        this.onMenuItemEnter(e);

        break;
      }

      default:
        return;
    }
  };

  handleMouseOver = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const menuItems = this.getMenuItems();
    const index = menuItems.indexOf(target as HTMLLIElement);

    let focusedMenuItem = null;
    if (index !== -1) {
      this.focusMenuItem(index, true);
      focusedMenuItem = menuItems[index];
    } else if (this.focusedMenuItem >= 0) {
      const menuItem = menuItems[this.focusedMenuItem];
      const submenuItems = this.getSubMenuItems(menuItem);
      const submenuIndex = submenuItems.indexOf(target as HTMLLIElement);

      if (submenuIndex !== -1) {
        this.focusSubMenuItem(submenuIndex);
        focusedMenuItem = submenuItems[submenuIndex];
      } else {
        this.focusSubMenuItem(-1);
      }
    }

    const toolTip = target?.getAttribute("data-tooltip");
    if (toolTip) {
      this.showTooltip(toolTip, e.pageX, e.pageY);

      if (focusedMenuItem) {
        // ? Will that add infinite amount of listeners?
        focusedMenuItem.addEventListener("mousemove", this.handleTooltipMouseMove.bind(this));
      }

      return false;
    }

    this.hideTooltip();
    return false;
  };

  handleTooltipMouseMove = (e: MouseEvent) => {
    this.updateTooltipPosition(e.pageX, e.pageY);
  };

  // Update updateTooltipPosition method
  updateTooltipPosition(x: number, y: number) {
    if (MenuSuggestion.sharedTooltip) {
      MenuSuggestion.sharedTooltip.style.left = `${x + 40}px`;
      MenuSuggestion.sharedTooltip.style.top = `${y + 20}px`;
    }
  }

  handleMouseOut = (e: MouseEvent) => {
    this.hideTooltip();
    const target = e.target as HTMLElement;
    target.removeEventListener("mousemove", this.handleTooltipMouseMove);
  };

  createMenuListItem = (menuItem: MenuSuggestion | Suggestion, id: string) => {
    if (menuItem.type === "text") {
      return menuItem.createInstance(id, this.context) as HTMLElement;
    }

    const menuListItem = document.createElement("li");
    menuListItem.setAttribute("role", "menuitem");
    menuListItem.setAttribute(
      "class",
      "rounded-sm relative px-3 py-1 w-full text-left flex items-center cursor-pointer",
    );
    menuListItem.setAttribute("aria-haspopup", "true");
    menuListItem.setAttribute("id", uniqueId("menuitem-"));

    // same behaviour as Enter key
    menuListItem.addEventListener("mousedown", this.onMenuItemEnter, {
      capture: true,
      passive: false,
      once: true,
    });

    menuListItem.textContent = this.customLabel(menuItem);

    if (menuItem.children?.length && menuItem.children.length > 0) {
      menuListItem.appendChild(MenuSuggestion.icon());
    }

    return menuListItem;
  };

  createSubMenu = (innerItems: (Suggestion | MenuSuggestion)[]) => {
    const menuInstance = document.createElement("ul");
    menuInstance.setAttribute("id", "menu-lang");
    menuInstance.setAttribute("aria-hidden", "true");
    menuInstance.setAttribute(
      "class",
      `${MenuSuggestion.subMenuClass} bg-white border rounded-sm absolute top-0 left-full transition duration-150 ease-in-out origin-top-left min-w-32 scrollbar-thumb-gray-600 scrollbar-track-gray-100 scrollbar-thin overflow-y-auto`,
    );
    menuInstance.setAttribute("role", "menu");

    innerItems.forEach((innerItem: Suggestion, index: number) => {
      // combine list item id from menu item id and inner item id
      const listItemID = `${this.menuInstanceId}-${index}`;
      const innerListItem = innerItem.createInstance(listItemID, this.context)!;
      menuInstance.appendChild(innerListItem);
    });

    return menuInstance;
  };

  static icon = () => {
    const iconContainer = document.createElement("span");
    iconContainer.setAttribute("class", "group ml-auto inline pointer-events-none");

    const icon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    icon.setAttribute("class", "fill-current h-4 w-4 transition duration-150 ease-in-out");
    icon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    icon.setAttribute("viewBox", "0 0 20 20");

    const iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    iconPath.setAttribute("d", "M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z");

    // compile elements
    icon.appendChild(iconPath);
    iconContainer.appendChild(icon);

    return iconContainer;
  };

  scrollIntoViewIfNeeded(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const parentRect = element.parentElement!.getBoundingClientRect();

    if (rect.top < parentRect.top || rect.bottom > parentRect.bottom) {
      element.scrollIntoView({ block: "nearest" });
    }
  }

  destroy() {
    // TODO: seems its not fired always when I suppose it should be
    this.textarea.removeEventListener("keydown", this.boundHandleKeyDown);

    if (this.menu) {
      this.menu.remove();
    }

    this.hideTooltip();

    super.destroy();
  }
}

export { MenuSuggestion };
