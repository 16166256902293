import { autofixCveId } from "@components/DjangoQL/autoFixSearchQuery";
import Dialog from "@material-tailwind/react/components/Dialog";
import { FULL_CVE_ID_REGEX } from "@utils/Lexer";
import { CVE_FORMATS } from "@utils/Lexer";
import { useCallback, useState } from "react";

interface CVEParseResult {
  cves: string[];
  pureMatch: boolean;
  originalText: string;
}

interface UseCVEPasteParams {
  onValueChange: (value: string, cursorPos: number) => void;
}

interface CVEDialogProps {
  parseResult: CVEParseResult;
  onClose: (useCVEs: boolean) => void;
  open: boolean;
}

const analyzePastedCVEs = (text: string): CVEParseResult => {
  const originalText = text.trim();
  const cves = extractCVEsFromText(text); // You'll need to implement this based on your existing logic

  const normalizedOriginal = originalText
    .replace(/[\n\r,\s]+/g, " ")
    .trim()
    .toUpperCase();

  const normalizedCVEs = cves.join(" ");
  const pureMatch = normalizedOriginal === normalizedCVEs;

  return {
    cves,
    pureMatch,
    originalText,
  };
};

function extractCVEsFromText(text: string): string[] {
  const normalizedText = text.toUpperCase();
  const cves = new Set<string>();

  // Extract CVEs using different patterns
  for (const pattern of Object.values(CVE_FORMATS)) {
    const matches = normalizedText.match(pattern) || [];
    for (const match of matches) {
      // Clean up and normalize CVE IDs
      const cleaned = match.replace(/[,\s]+/g, " ").trim();
      for (const cve of cleaned.split(/\s+/)) {
        if (FULL_CVE_ID_REGEX.test(cve)) {
          cves.add(cve);
        }
      }
    }
  }

  return Array.from(cves);
}

// biome-ignore lint/nursery/useComponentExportOnlyModules: <we use it here>
const CVEDialog: React.FC<CVEDialogProps> = ({ parseResult, onClose, open }) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} handler={() => onClose(false)}>
      <div className="fixed inset-0 bg-black/50" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-2xl w-full shadow-xl">
          <div className="p-6">
            <h3 className="text-lg font-bold mb-2 text-gray-900">CVE IDs Found in Pasted Content</h3>

            <p className="text-sm text-gray-600 mb-4">
              The pasted content contains CVE IDs mixed with other text. You can choose to extract just the CVE IDs or
              use the original text.
            </p>

            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-sm text-gray-700 mb-1">
                  Extracted CVE IDs ({parseResult.cves.length})
                </h4>
                <div className="bg-blue-50 p-3 rounded border border-blue-100 text-sm font-mono">
                  {parseResult.cves.length > 10
                    ? `${parseResult.cves.slice(0, 10).join(", ")} and ${parseResult.cves.length - 10} more`
                    : parseResult.cves.join(", ")}
                </div>
              </div>

              <div>
                <h4 className="font-medium text-sm text-gray-700 mb-1">Original Text</h4>
                <div className="bg-gray-50 p-3 rounded border border-gray-200 text-sm font-mono whitespace-pre-wrap max-h-40 overflow-y-auto">
                  {parseResult.originalText}
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                onClick={() => onClose(false)}
              >
                Use Pasted Content
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                onClick={() => onClose(true)}
              >
                Use CVE IDs Only
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export function useCVEPaste({ onValueChange }: UseCVEPasteParams) {
  const [showCVEDialog, setShowCVEDialog] = useState(false);
  const [currentParseResult, setCurrentParseResult] = useState<CVEParseResult | null>(null);
  const [pastePosition, setPastePosition] = useState<number>(0);

  const handlePaste = useCallback((e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const text = e.clipboardData.getData("text");
    const parseResult = analyzePastedCVEs(text);

    // only consider multiple CVEs pasted
    if (parseResult.cves.length > 1) {
      e.preventDefault();
      const cursorPos = e.currentTarget.selectionStart;
      setPastePosition(cursorPos);

      if (parseResult.pureMatch) {
        // Direct paste for pure CVE lists
        const newValue = autofixCveId(parseResult.cves.join(" "));
        onValueChange(newValue, cursorPos);
        return;
      }

      // Show dialog only when we're potentially discarding extra content
      setCurrentParseResult(parseResult);
      setShowCVEDialog(true);
    }
  }, []);

  const handleCVEDialogClose = useCallback(
    (useCVEs: boolean) => {
      setShowCVEDialog(false);

      if (currentParseResult) {
        const newValue = useCVEs ? autofixCveId(currentParseResult.cves.join(" ")) : currentParseResult.originalText;

        onValueChange(newValue, pastePosition);
      }

      setCurrentParseResult(null);
    },
    [currentParseResult, onValueChange, pastePosition],
  );

  const DialogComponent = useCallback(() => {
    if (!currentParseResult) {
      return null;
    }

    return <CVEDialog parseResult={currentParseResult} onClose={handleCVEDialogClose} open={showCVEDialog} />;
  }, [currentParseResult, showCVEDialog, handleCVEDialogClose]);

  return {
    handlePaste,
    DialogComponent,
  };
}
