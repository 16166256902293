import React from "react";

import type { FrontendSerializedThreat, MandiantActor } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";

const LIMIT_RECORDS_TO = 3;

const MandiantActorsRenderer: (context: CellContext<FrontendSerializedThreat, MandiantActor[]>) => JSX.Element = (
  context,
) => {
  const value: MandiantActor[] = context.getValue();

  if (!Array.isArray(value)) {
    return <>-</>;
  }

  const renderActor = (actor: MandiantActor, index: number) => (
    <div key={`mandiant_actors_${actor?.actor_id}`}>
      {/* <a
        className="inline-flex items-center font-semibold transition-all ease-nav-brand text-slate-600 hover:text-blue-700"
        href={`/v/${actor.cve_id}`}
        target="_blank"
      > */}
      {actor.actor_name}
      {/* <i className="ml-1 text-xs fas fa-external-link-alt"></i> */}
      {/* </a> */}
      {index !== value.length - 1 && ", "}
    </div>
  );

  if (value.length > LIMIT_RECORDS_TO) {
    return (
      <div>
        <div>{value.slice(0, LIMIT_RECORDS_TO).map((actor, index) => renderActor(actor, index))}</div>
        <div className="mt-2 text-xs text-right text-gray-600 cursor-not-allowed">
          And {value.length - LIMIT_RECORDS_TO} more...
        </div>
      </div>
    );
  }

  return <div>{value.map(renderActor)}</div>;
};

export default MandiantActorsRenderer;
